﻿module.exports = function (app) {
    app.directive('a', ['$rootScope', 'appFactory', function ($rootScope, appFactory) {
        return {
            restrict: 'E',
            link: function (scope, elem, attrs) {
                attrs.$observe('href', function (hrefVal) {
                    var a = elem[0];
                    if (hrefVal !== undefined && hrefVal !== null && hrefVal !== '' && hrefVal.toLowerCase().indexOf('mailto:') > -1 && hrefVal.toLowerCase().indexOf('@') > -1) {
                        var name = hrefVal.substring(hrefVal.toLowerCase().indexOf('mailto:') + 7, hrefVal.toLowerCase().indexOf('@'));
                        var domain = hrefVal.substring(hrefVal.toLowerCase().indexOf('@') + 1);
                        var domainsmall = domain;
                        if (hrefVal.toLowerCase().indexOf('?') > 0) {
                            domainsmall = hrefVal.substring(hrefVal.toLowerCase().indexOf('@') + 1, hrefVal.toLowerCase().indexOf('?'));
                        }
                        if (name !== undefined && name !== null && name !== '') {
                            var emailTo = name+'@'+domainsmall
                            a.href = 'javascript:emailDisclaimer("'+name+'", "'+domainsmall+'")';
                            if (a.innerHTML !== undefined && a.innerHTML !== null && a.innerHTML !== '' && a.innerHTML.toLowerCase().indexOf('@') > -1) {
                                a.innerHTML = name + '<span>@</span>' + domainsmall;
                            }
                        }
                    }
                    window.emailDisclaimer = function (name, domain) {
                        var emailTo = name + '@' + domain
                        $rootScope.$broadcast('email-disclaimer', { email: emailTo })
                    }
                })
            }
        }
    }]);
};