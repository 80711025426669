module.exports = function (app) {
    app.directive('languagePrompt',
      ['appFactory', '$rootScope', '$window', 'cookieService', function (appFactory, $rootScope, $window, cookieService) {
          return {
              restrict: 'A',
              scope: true,
              bindToController: {
                  config: '&',
                  languagePromptViewModelJson: '&'
              },
              controllerAs: 'ctrl',
              controller: ['$scope', function ($scope) {

                  if (document.getElementById('json-languagepromptviewmodel')) {
                      $scope.languagePromptViewModelJson = JSON.parse(document.getElementById('json-languagepromptviewmodel').text);
                  };

                  $scope.config = {
                      active: $scope.languagePromptViewModelJson.HasLanguageTranslation,
                      hide: false,
                      animate: true,
                      options: [
                        {
                            'id': 0,
                            'title': $scope.languagePromptViewModelJson.YesText
                        },
                        {
                            'id': 1,
                            'title': $scope.languagePromptViewModelJson.NoText
                        },
                        {
                            'id': 2,
                            'title': $scope.languagePromptViewModelJson.NeverText
                        }
                      ],
                      description: $scope.languagePromptViewModelJson.Description
                  }
              }],
              link: function ($scope) {
                  $scope.setActive = function (isActive) {
                      $scope.config.active = isActive
                  }
                  $scope.onSelection = function (optionId) {
                      switch (optionId) {
                          case 0:
                              $window.location.href = $scope.languagePromptViewModelJson.TranslatedItemUrl
                              break;
                          case 1:
                              $scope.setActive(false)
                              $scope.config.hide = true;
                              break;
                          case 2:
                              cookieService.setCookie('website#language_prompt', 'never', 0);
                              $scope.setActive(false)
                              $scope.config.hide = true;
                              break;
                      }
                  }
              }
          }
      }]
    )
}
