module.exports = function (app) {
  app.directive('windowResize',
    ['appFactory', '$rootScope', '$window', function (appFactory, $rootScope, $window) {
      return function ($scope) {
        $scope.checkMobile = function () {
          $scope.isMobile = appFactory.setMobile()
        }

        $scope.initializeWindowSize = function () {
          $scope.windowSize = appFactory.setWindowSize($window.innerWidth)
          $scope.checkMobile()
        }

        $scope.initializeWindowSize()

        return angular.element($window).bind('resize', function () {
          $scope.initializeWindowSize()
          return $scope.$apply()
        })
      }
    }])
}
