﻿module.exports = function (app) {
    app.directive('onenorTypeahead', ['$timeout', '$window', '$rootScope', '$compile', '$sce', 'appFactory', 'searchService', 'queryStringService', function ($timeout, $window, $rootScope, $compile, $sce, appFactory, searchService, queryStringService) {
        var controller = ['$scope', '$element', function ($scope, $element) {
            var timeout;

            function init() {
                $scope.active = false;
                $scope.inProgress = false;
                $scope.options = [];
                $scope.placeholder = '';
                //$scope.query = '';
                $scope.unique = $scope.$id;
                $scope.elem = $element;

                $scope.defaultText = 'Search';
                $scope.altText = '';
                $scope.placeholder = '';

                if (!$scope.endpoint || $scope.endpoint === '') {
                    $scope.endpoint = '/webapi/typeahead/search';
                }
            };

            init();

            // keycodes:
            // tab:             9
            // enter:           13
            // esc:             27
            // arrow left:      37
            // arrow up:        38
            // arrow right:     39
            // arrow down:      40
            $scope.handleKeyup = function ($event, callback) {
                var currentIndex = parseInt($event.currentTarget.attributes['data-order'].value);

                switch ($event.keyCode) {
                    case 40:
                        //go to next item in list
                        var nextIndex = currentIndex + 1;
                        if (nextIndex <= $scope.options.length) {
                            var nextElem = $scope.elem.parent()[0].querySelector('[data-order="' + nextIndex + '"]');
                            if (nextElem) {
                                nextElem.focus();
                            }
                        }
                        break;
                    case 13:
                        //if currently selecting a list item, trigger a click
                        if (timeout) {
                            clearTimeout(timeout);
                        }
                        if (currentIndex > 0) {
                            var target = $event.currentTarget;
                            $timeout(function () {
                                angular.element(target).triggerHandler('click');
                            });
                        }
                        else if ($scope.allowUserInput) {
                            $scope.userInputCallback($event, $scope.query);
                            $scope.userInputCallback($event, $scope.firstname);
                            $scope.userInputCallback($event, $scope.lastname);
                            $scope.options = [];
                            $scope.active = false;
                            $element[0].blur();
                        }
                        break;
                    //case 9:
                    case 27:
                        //close the typeahead list
                        $scope.active = false;
                        break;
                    case 37:
                    case 39:
                        //do nothing
                        break;
                    case 38:
                        //go to prev item in list
                        var prevIndex = currentIndex - 1;
                        if (prevIndex >= 0) {
                            var prevElem = $scope.elem.parent()[0].querySelector('[data-order="' + prevIndex + '"]');
                            prevElem.focus();
                        }
                        break;
                    default:
                        if (callback) callback();
                        break;
                }
            };

            $scope.handleKeydown = function ($event) {
                switch ($event.keyCode) {
                    case 9:
                        break;//let browser control navigation when tab is used
                    case 13:
                    case 27:
                    case 38:
                    case 40:
                        $event.preventDefault();
                        break;
                }
            };

            $scope.handleInputFocus = function () {
                if ($scope.options.length > 0) {
                    $scope.active = true;
                }
            };

            $scope.getTypeaheadList = function () {
                if (timeout) {
                    clearTimeout(timeout);
                }
                timeout = setTimeout(function () {
                    _getTypeaheadList();
                }, 300);
            };

            function _getTypeaheadList() {
                if ($scope.query && $scope.query.length >= 3 ||
                    $scope.firstname && $scope.firstname.length >= 3 ||
                    $scope.lastname && $scope.lastname.length >= 3) {
                    $scope.active = true;
                    $scope.inProgress = true;

                    var qs = '';
                    qs = queryStringService.addQueryString('keyword', $scope.query || "", qs);
                    qs = queryStringService.addQueryString('firstName', $scope.firstname || "", qs);
                    qs = queryStringService.addQueryString('lastName', $scope.lastname || "", qs);
                    qs = queryStringService.addQueryString('templateIds', $scope.templateIds, qs);
                    qs = queryStringService.addQueryString('contentTemplateIds', $scope.contentTemplateIds, qs);
                    qs = queryStringService.addQueryString('pageSize', $scope.pageSize, qs);
                    qs = queryStringService.addQueryString('sortBy', 1, qs); //relevance
                    qs = queryStringService.addQueryString('sortOrder', 0, qs); //ascending
                    qs = queryStringService.addQueryString('language', $scope.language, qs);
                    qs = queryStringService.addQueryString('partial', false, qs);
                    if ($scope.filter && $scope.filter.ExcludeIds) {
                        qs = queryStringService.addQueryString('excludeIds', $scope.filter.ExcludeIds, qs);
                    }
                    if ($scope.additionalQueryName != null &&
                        $scope.additionalQueryName !== "" &&
                        $scope.additionalQueryName !== "undefined") {
                        qs = queryStringService.addQueryString($scope.additionalQueryName, $scope.additionalQueryValue, qs);
                    }

                    searchService.search($scope.endpoint && $scope.endpoint !== '' ? $scope.endpoint : '/api/ssc/webapi/typeahead/', qs, handleSearchSuccess, handleSearchError);
                }
                else {
                    $scope.active = false;
                    $scope.options = [];
                }
            };

            function handleSearchSuccess(response) {
                if (!response || !response.data || (!response.data.Term.includes($scope.query) && !response.data.Term.includes($scope.firstname) && !response.data.Term.includes($scope.lastname))) {
                    return;
                }

                var sanitizedFields = [ 'Name', 'Context' ];
                angular.forEach(response.data.Results, function (result) {
                    angular.forEach(sanitizedFields, function (name) {
                        result[name] = $sce.trustAsHtml(result[name]);
                    });
                });
                $scope.options = response.data.Results;
                $scope.inProgress = false;
                $scope.active = true//$scope.options.length > 0;
            };

            function handleSearchError(error) {
                $scope.options = [];
                $scope.inProgress = false;
                $scope.active = false;
            };

            $scope.addSelection = function (event, option) {
                if ($scope.selectCallback) {
                    $scope.selectCallback(event, $scope.filter, option);
                }
                $scope.query = '';
                $scope.firstname = '';
                $scope.lastname = '';
                $scope.options = [];
                $scope.active = false;
            }
        }],

        link = {
            pre: function ($scope, $element, $attrs) {
                $scope.placeholder = $attrs.placeholderTextDesktop || '';
                $scope.altText = $attrs.placeholderTextDesktop || '';
                $scope.defaultText = $attrs.placeholderTextMobile || '';

                $rootScope.$on('windowresize-isMobile', function (event, ismobile) {
                    $scope.placeholder = (ismobile) ? $scope.defaultText : $scope.altText
                }, true);

                $scope.$watch('placeholder', function () {
                    $element[0].placeholder = $scope.placeholder;
                }, true);

                //search input focus when parent menu responder is active
                if ($scope.autofocus) {
                    $scope.$watch(function (scope) { return scope.$parent.$parent.responder.active }, function (isResponderActive) {
                        if (isResponderActive) {
                            $element[0].parentElement.focus()
                        }
                    }, true)
                }
            },
            post: function ($scope, $element, $attrs) {
                $element.bind('keydown', function (event) { $scope.handleKeydown(event); });
                $element.bind('keyup', function (event) { $scope.handleKeyup(event, $scope.getTypeaheadList); });
                $element.bind('focus', function (event) { $scope.handleInputFocus(event); });
                $element.attr('data-order', '0');

                var html = '<div class="typeahead-list' + ($scope.cssModifier !== '' ? ' typeahead-list--' + $scope.cssModifier : '') + '" ng-class="{ active : active == true }">' +
                           '<div class="typeahead-list-inprogress" ng-show="inProgress == true"><span class="typeahead-inprogress">{{ progressMessage }}</span></div>' +
                           '<button type="button" class="typeahead-list-item" ng-show="inProgress == false && options.length > 0" ng-repeat="option in options" ng-keydown="handleKeydown($event)" ng-keyup="handleKeyup($event, null)" ng-click="addSelection($event, option)" data-order="{{ $index + 1 }}" tab-index="{{ $index + 1 }}" ng-bind-html="option.Name"></button>' +
                           '<div class="typeahead-list-empty" ng-show="inProgress == false && options.length == 0 && !!emptyMessage"><span class="typeahead-empty">{{ emptyMessage }}</span></div>' +
                           '</div>';
                var el = angular.element(html);
                $element.after(el);
                $compile(el)($scope);

                angular.element($window).on('click', function (event) {
                    if (!(event.target.id == $element.attr('id') || $(event.target).parents('#' + $element.attr('id')).length)) {
                        $scope.inProgress = false;
                        $scope.active = false;
                        $scope.$apply();
                    }
                });
            }
        };

        return {
            replace: false,
            restrict: 'A',
            scope: {
                query: '=?onenorTypeaheadModel',
                firstname: '=?onenorTypeaheadFirstname',
                lastname: '=?onenorTypeaheadLastname',
                filter: '=?onenorTypeaheadFilter',
                allowUserInput: '@onenorTypeaheadAllowUserInput',
                autofocus: '@onenorTypeaheadAutofocus',
                userInputCallback: '=onenorTypeaheadUserInputCallback',
                selectCallback: '=onenorTypeaheadSelectCallback',
                language: '@onenorTypeaheadLanguage',
                templateIds: '@onenorTypeaheadTemplateIds',
                contentTemplateIds: '@onenorTypeaheadContentTemplateIds',
                pageSize: '@onenorTypeaheadPageSize',
                progressMessage: '@onenorTypeaheadProgressMessage',
                emptyMessage: '@onenorTypeaheadEmptyMessage',
                endpoint: '@onenorTypeaheadEndpoint',
                cssModifier: '@onenorTypeaheadCssModifier',
                additionalQueryName: '@onenorTypeaheadAdditionalQueryName',
                additionalQueryValue: '@onenorTypeaheadAdditionalQueryValue'
            },
            controller: controller,
            link: link
        };
    }]);
}