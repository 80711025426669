﻿module.exports = function (app) {
  app.controller('professionalsLanding', ['$window', '$scope', '$http', '$element', '$timeout', '$sce', 'helperService', 'queryStringService', 'searchService', 'scrollToService', function ($window, $scope, $http, $element, $timeout, $sce, helperService, queryStringService, searchService, scrollToService) {
    $scope.isLoading = false
    $scope.filters = {
      alphaFilter: {},
      toggleFilters: [],
      selectFilters: [],
      categoryFilters: [],
      activeFilters: []
    }
    $scope.results = []
    $scope.totalResultCount = 0
    $scope.currentResultCount = 0
    $scope.enableInfiniteScroll = false
    $scope.keyContacts = []
    $scope.keyContactsLabel = ''
    $scope.fullTeamLabel = ''

    $scope.keyword = ''
    $scope.firstname = ''
    $scope.lastname = ''

    $scope.subserviceFilters = [{ 'Text': 'Select', 'Value': '' }]
    $scope.selectedService = { 'SelectedToggle': $scope.subserviceFilters[0] }
    $scope.subindustryFilters = [{ 'Text': 'Select', 'Value': '' }]
    $scope.selectedIndustry = { 'SelectedToggle': $scope.subindustryFilters[0] }

    $scope.hasSearchRun = false
    $scope.hasSearchStarted = false

    $scope.serverActiveFilters = []

    /* PRIVATE VARIABLES */

    var language = 'en'
    var sanitizedFields = []
    var qsSeparator = '|'
    var firstSearch = true
    var searchType = 'all'
    var pageSize = 10
    var pageNum = 1
    var scrollPosition = 0
    var endpoint = ''
    var sortBy = 0
    var sortOrder = 0
    var preventAutoScroll = false
    var pageloadSearch = false
    var resultsElement
    var isExperienceEditor = false
    var previousKeyword = ''
    var previousFirstName = ''
    var previousLastName = ''
    var isViewMoreSearch = false

    /* INIT */

    var init = function () {
      // look for initial state json for filters and results

      if ($element.attr('data-page-size')) {
        pageSize = helperService.tryParseInt($element.attr('data-page-size'), pageSize)
      }
      if ($element.attr('data-language')) {
        language = $element.attr('data-language')
      }
      if ($element.attr('data-search-endpoint')) {
        endpoint = $element.attr('data-search-endpoint')
      }
      if ($element.attr('data-sanitized-fields')) {
        sanitizedFields = $element.attr('data-sanitized-fields').split(',')
      }
      if ($element.attr('data-is-experience-editor')) {
        isExperienceEditor = true
      }
      if (document.getElementById('json-alpha-filters')) {
        $scope.filters.alphaFilter = JSON.parse(document.getElementById('json-alpha-filters').text)
      }
      if (document.getElementById('json-select-filters')) {
        $scope.filters.selectFilters = JSON.parse(document.getElementById('json-select-filters').text)
      }
      if (document.getElementById('json-active-filters')) {
        $scope.serverActiveFilters = JSON.parse(document.getElementById('json-active-filters').text)
      }
      if (document.getElementById('scrollToTarget')) {
        resultsElement = document.getElementById('scrollToTarget')
      }

      if ($scope.filters.selectFilters && $scope.filters.selectFilters.length > 0) {
        $scope.select = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced == false })
        $scope.advanced = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced == true })
      }

      if (queryStringService.hasQueryString()) {
        setFilters()
      }
      pageloadSearch = $scope.filters.activeFilters.length > 0
      search()
    }

    /* EVENT HANDLERS */

    $scope.onSubFilterChange = function ($event, name, toggle) {
      removeActiveFilterByGroup('sub' + name)
      if (name === 'services' && $scope.selectedService.SelectedToggle.Value !== '') {
        addActiveFilter('sub' + name, $scope.selectedService.SelectedToggle.Text, $scope.selectedService.SelectedToggle.Value);
      } else if (name === 'industries' && $scope.selectedIndustry.SelectedToggle.Value !== '') {
        addActiveFilter('sub' + name, $scope.selectedIndustry.SelectedToggle.Text, $scope.selectedIndustry.SelectedToggle.Value);
      }
      search()
    }

    $scope.onSubmit = function () {
      if ($scope.keyword) {
        removeActiveFilterByGroup('keyword')
        addActiveKeywordFilter($scope.keyword, 'keyword')
      }
      if ($scope.firstname) {
        removeActiveFilterByGroup('firstname')
        addActiveKeywordFilter($scope.firstname, 'firstname')
      }
      if ($scope.lastname) {
        removeActiveFilterByGroup('lastname')
        addActiveKeywordFilter($scope.lastname, 'lastname')
      }
      pageNum = 1

      search()
    }

    $scope.onFilterInit = function ($event, filter) {
      if (!filter.SelectedOption)
        filter.SelectedOption = filter.Options[0]
    }

    $scope.onFilterChange = function ($event, filter) {
      if (!(filter && filter.SelectedOption)) {
        return
      }

      removeActiveFilterByGroup(filter.Name);

      if (filter.Name === 'services') {
        removeActiveToggleFiltersByGroup(filter.Name);
        removeActiveFilterByGroup('subservices');
      } else if (filter.Name === 'industries') {
        removeActiveToggleFiltersByGroup(filter.Name);
        removeActiveFilterByGroup('subindustries');
      }

      if (filter.SelectedOption.Value !== '') {
        addActiveSelectFilter(filter);

        filter.Options.forEach(function (option) {
          if (option.Toggles && option.Toggles.length > 0) {
            option.SelectedToggle = {};
          }
        });

        if (filter.SelectedOption.Toggles && filter.SelectedOption.Toggles.length > 0) {
          addActiveToggleFilter(filter);
        }
      }

      pageNum = 1
      search()
    }

    $scope.onAlphaSelect = function ($event, filter, option) {
      if (!option) return
      if (!option.IsEnabled) return

      if (filter.SelectedOption && filter.SelectedOption.Value === option.Value) {
        // remove this selection
        filter.SelectedOption = {}
        removeActiveFilterByGroup($scope.filters.alphaFilter.Name)
      } else {
        // clear all previous filters and apply this selection
        $scope.filters.activeFilters = []
        resetSelectedFilters()
        previousKeyword = ''
        previousFirstName = ''
        previousLastName = ''
        filter.SelectedOption = option
        addActiveSelectFilter(filter)
      }
      pageNum = 1

      search()
    }

    $scope.onFilterToggleSelect = function ($event, name, option, toggle) {
      if (!toggle || !toggle.IsEnabled) return

      option.SelectedToggle = toggle

      removeActiveFilterByGroup(name)
      addActiveFilter(name, toggle.Text, toggle.Value)
      pageNum = 1

      search()
    }

    $scope.onRemoveFilter = function (event, active) {
      removeActiveFilterByValue(active.Value);

      if (active.Group === 'keyword') {
        previousKeyword = ''
      }

      if (active.Group === 'firstname') {
        previousFirstName = ''
      }

      if (active.Group === 'lastname') {
        previousLastName = ''
      }

      if (active.Group === 'subservices') {
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0]
      }

      if (active.Group === 'subindustries') {
        $scope.selectedIndustry.SelectedToggle = $scope.subindustryFilters[0]
      }

      angular.forEach($scope.filters.selectFilters, function (filter) {
        if (filter.Name === active.Group) {
          removeActiveToggleFiltersByGroup(active.Group)
          if (filter.Options && filter.Options.length > 0) {
            filter.SelectedOption = filter.Options[0]
          }
          else {
            filter.SelectedOption = {}
          }
          if (filter.ExcludeIds)
            filter.ExcludeIds = null
          if (filter.Keyword)
            filter.Keyword = ''
          if (filter.FirstName)
            filter.FirstName = ''
          if (filter.LastName)
            filter.LastName = ''
        }
      })
      pageNum = 1

      if ($scope.filters.activeFilters.length === 0) {
        $scope.filters.activeFilters = []
        resetHistory()
        $scope.hasSearchRun = false
        $scope.results = []
      } else {
        preventAutoScroll = true
        search()
      }
    }

    $scope.onRemoveFilterAll = function (event) {
      preventAutoScroll = true
      resetSearch()
    }

    $scope.onResultLink = function ($event, url) {
      if ($event.ctrlKey || $event.metaKey) {
        return
      }
      var qs = buildQueryString($window.pageYOffset)
      updateHistory(qs, true)
      window.location = url
    }

    $scope.onEmailClick = function ($event, result) {
      if (result && result.Email !== '' && result.Email.indexOf('@') > 0) {
        var array = result.Email.split('@')
        var prefix = array[0]
        var domain = array[1]
        window.open('/EmailDisclaimer?emailprefix=' + prefix + '&emaildomain=' + domain, 'disclaimer', 'height=540,width=640,location=no,menubar=no,resizable=no,scrollbars=no,toolbar=no,status=no,left=100,top=100')
      }
    }

    $scope.onAutoViewMore = function (e) {
      viewMore()
    }

    var viewMore = function (bUserTriggered) {
      if (!$scope.isLoading) {
        preventAutoScroll = true
        isViewMoreSearch = true
        pageNum++
        search()
      }
    }

    var setFilters = function () {
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('keyword'))) {
        var keyword = queryStringService.getParameterByName('keyword')
        if (keyword) {
          addKeywordFilter(keyword)
        }
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('firstname'))) {
        var firstname = queryStringService.getParameterByName('firstname')
        if (firstname) {
          addFirstNameFilter(firstname)
        }
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('lastname'))) {
        var lastname = queryStringService.getParameterByName('lastname')
        if (lastname) {
          addLastNameFilter(lastname)
        }
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('letter'))) {
        setLetterFilter(queryStringService.getParameterByName('letter'));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('related'))) {
        setSelectFilters(queryStringService.getParameterByName('related'))
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('pageSize'))) {
        pageSize = helperService.tryParseInt(queryStringService.getParameterByName('pageSize'), 0)
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('pageNum'))) {
        pageNum = helperService.tryParseInt(queryStringService.getParameterByName('pageNum'), 0)
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortBy'))) {
        sortBy = helperService.tryParseInt(queryStringService.getParameterByName('sortBy'), 0)
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortOrder'))) {
        sortOrder = helperService.tryParseInt(queryStringService.getParameterByName('sortOrder'), 0)
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('language'))) {
        language = queryStringService.getParameterByName('language')
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('scroll'))) {
        scrollPosition = queryStringService.getParameterByName('scroll')
      }
    }

    var addKeywordFilter = function (keyword) {
      $scope.keyword = keyword;
      keyword = searchService.getKeywordDisplay(keyword);
      addActiveFilter('keyword', keyword, keyword)
    }
    var addFirstNameFilter = function (keyword) {
      $scope.firstname = keyword;
      keyword = searchService.getKeywordDisplay(keyword);
      addActiveFilter('firstname', keyword, keyword)
    }
    var addLastNameFilter = function (keyword) {
      $scope.lastname = keyword;
      keyword = searchService.getKeywordDisplay(keyword);
      addActiveFilter('lastname', keyword, keyword)
    }

    var setLetterFilter = function (letter) {
      var matchingOptions = $scope.filters.alphaFilter.Options.filter(function (alpha) { return alpha.Value === letter })
      if (matchingOptions.length === 1)
        $scope.filters.alphaFilter.SelectedOption = matchingOptions[0]
      addActiveSelectFilter($scope.filters.alphaFilter)
    }

    var setSelectFilters = function (relateds) {
      var aRelated = []
      aRelated = relateds.split('|')
      angular.forEach(aRelated, function (relatedId) {
        var found = false
        angular.forEach($scope.filters.selectFilters, function (filter) {
          if (filter.Options && filter.Options.length > 0) {
            angular.forEach(filter.Options, function (option) {
              if (option.Value === relatedId) {
                found = true
                filter.SelectedOption = option
                if (option.Toggles && option.Toggles.length > 0) {
                  addActiveToggleFilter(filter)
                }
                addActiveSelectFilter(filter)
              } else if (option.Toggles && option.Toggles.length > 0) {
                angular.forEach(option.Toggles, function (toggle) {
                  if (toggle.Value === relatedId) {
                    found = true
                    option.SelectedToggle = toggle
                    filter.SelectedOption = option
                    addActiveFilter('sub' + filter.Name, toggle.Text, toggle.Value);
                    addActiveToggleFilter(filter);

                    if (filter.Name === 'services') {
                      $scope.selectedService.SelectedToggle = toggle;
                    } else if (filter.Name === 'industries') {
                      $scope.selectedIndustry.SelectedToggle = toggle;
                    }
                  }
                })
              }
            })
          }
        })
        if (!found && $scope.serverActiveFilters && $scope.serverActiveFilters.length > 0) {
          // try checking the active filters json from server for typeaheads
          angular.forEach($scope.serverActiveFilters, function (server) {
            if (server.Value === relatedId) {
              addActiveFilter('?', server.Text, server.Value)
            }
          })
        }
      })
    }

    var addActiveKeywordFilter = function (keyword, typeaheadType) {
      if (typeaheadType === 'keyword') {
        keyword = searchService.getKeywordDisplay(keyword)
        addActiveFilter('keyword', keyword, keyword)
      }
      if (typeaheadType === 'firstname') {
        keyword = searchService.getKeywordDisplay(keyword)
        addActiveFilter('firstname', keyword, keyword)
      }
      if (typeaheadType === 'lastname') {
        keyword = searchService.getKeywordDisplay(keyword)
        addActiveFilter('lastname', keyword, keyword)
      }
    }

    var addActiveSelectFilter = function (filter) {
      if (filter && filter.SelectedOption) {
        addActiveFilter(filter.Name, filter.SelectedOption.Text, filter.SelectedOption.Value)
      }
    }

    var addActiveFilter = function (group, text, value) {
      $scope.filters.activeFilters.push({ Group: group, Text: text, Value: value })
    }

    var addActiveToggleFilter = function (filter) {
      $scope.filters.toggleFilters.push({ Name: filter.Name, Option: filter.SelectedOption })

      if (filter.Name === 'services') {
        $scope.selectedService = filter.SelectedOption
        // clear out existig drop-down
        $scope.subserviceFilters = [$scope.subserviceFilters[0]]
        // add new items
        $scope.subserviceFilters = $scope.subserviceFilters.concat(filter.SelectedOption.Toggles)
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0]
      }
      if (filter.Name === 'industries') {
        $scope.selectedIndustry = filter.SelectedOption
        // clear out existig drop-down
        $scope.subindustryFilters = [$scope.subindustryFilters[0]]
        // add new items
        $scope.subindustryFilters = $scope.subindustryFilters.concat(filter.SelectedOption.Toggles)
        $scope.selectedIndustry.SelectedToggle = $scope.subserviceFilters[0]
      }
    }

    //handle find() in ie
    var find = function (group, groupName) {
      return group.filter(function (x) {
        return x.Group === groupName
      })[0];
    }

    var search = function () {
      if (isExperienceEditor) { return }
      if (!($scope.filters.activeFilters.length > 0 || $scope.keyword !== '' || $scope.firstname !== '' || $scope.lastname !== '')) {
        resetSearch()
        return
      }

      //if they fill out text input field and click an advanced filter
      if (find($scope.filters.activeFilters, 'lastname') === undefined && $scope.lastname !== '')
        addActiveFilter('lastname', $scope.lastname, $scope.lastname)
      if (find($scope.filters.activeFilters, 'firstname') === undefined && $scope.firstname !== '')
        addActiveFilter('firstname', $scope.firstname, $scope.firstname)
      if (find($scope.filters.activeFilters, 'keyword') === undefined && $scope.keyword !== '')
        addActiveFilter('keyword', $scope.keyword, $scope.keyword)

      $scope.hasSearchStarted = true
      $scope.isLoading = true

      // build querystring
      var qs = buildQueryString()
      // history qs
      updateHistory(qs, isViewMoreSearch)

      // perform ajax request
      searchService.search(endpoint, qs, handleSearchSuccess, handleSearchError)
    }

    var handleSearchSuccess = function (response) {
      angular.forEach(response.data.Results, function (result) {
        angular.forEach(sanitizedFields, function (name) {
          result[name] = $sce.trustAsHtml(result[name])
        })
      })

      if (response.data.KeyContactsBlockViewModel !== null && response.data.KeyContactsBlockViewModel.KeyContacts.length > 0) {
        $scope.keyContacts = response.data.KeyContactsBlockViewModel.KeyContacts
        $scope.keyContactsLabel = response.data.KeyContactsBlockViewModel.KeyContactsLabel
        $scope.fullTeamLabel = response.data.KeyContactsBlockViewModel.FullTeamLabel
      } else {
        $scope.keyContacts = []
      }

      if (pageNum > 1 && !firstSearch) {
        $scope.results = $scope.results.concat(response.data.Results)
      } else {
        $scope.results = response.data.Results
      }
      $scope.totalResultCount = response.data.TotalResultCount
      $scope.currentResultCount = $scope.results.length

      if (pageloadSearch && scrollPosition > 0) {
        $timeout(function () {
          window.scroll(0, scrollPosition)
        }, 500)
      } else if ($scope.filters.activeFilters.length >= 1 && !!resultsElement && !isViewMoreSearch) {
        scrollToService.scrollToElement(resultsElement)
      }

      if ($scope.currentResultCount >= $scope.totalResultCount) {
        $scope.enableInfiniteScroll = false
      } else {
        $timeout(function () {
          $scope.enableInfiniteScroll = $scope.totalResultCount > 0 && $scope.currentResultCount > 0 && ($scope.currentResultCount < $scope.totalResultCount)
        }, 1000)
      }

      if ($scope.keyword !== '' && $scope.keyword !== previousKeyword) {
        previousKeyword = $scope.keyword
      }
      if ($scope.firstname !== '' && $scope.firstname !== previousFirstName) {
        previousFirstName = $scope.firstname
      }
      if ($scope.lastname !== '' && $scope.lastname !== previousLastName) {
        previousLastName = $scope.lastname
      }
      firstSearch = false
      pageloadSearch = false
      preventAutoScroll = false
      isViewMoreSearch = false
      $scope.hasSearchRun = true
      $scope.isLoading = false
      $scope.keyword = ''
      $scope.firstname = ''
      $scope.lastname = ''
    }

    $scope.onKeywordTypeaheadSelect = function (event, filter, selection) {
      if (!selection) return;

      //filter is always null for keyword
      //redirect to selection.Url
      if (selection && selection.Url) {
        $window.location.href = selection.Url;
      }
    }

    $scope.onFilterTypeaheadSelect = function (event, filter, selection) {
      if (!selection) return;

      //add id to exclusion
      if (filter.ExcludeIds)
        filter.ExcludeIds += '|' + selection.Id;
      else
        filter.ExcludeIds = selection.Id;

      //create selection
      addActiveFilter(filter.Name, selection.Name, selection.Id);
      pageNum = 1;

      search();
    }

    var handleSearchError = function (error) {
      pageloadSearch = false
      isViewMoreSearch = false
      $scope.enableInfiniteScroll = false
      $scope.isLoading = false
      $scope.keyword = ''
      $scope.firstname = ''
      $scope.lastname = ''
    }

    var buildQueryString = function (scroll) {
      var letter = $scope.filters.activeFilters.reduce(function (sum, val) {
        if (val.Group === $scope.filters.alphaFilter.Name) {
          return sum += (sum !== '' ? '|' : '') + val.Value
        }
        else {
          return sum
        }
      }, '')

      var related = ''
      angular.forEach($scope.filters.activeFilters, function (active) {
        if (active.Group !== $scope.filters.alphaFilter.Name && active.Group !== 'firstname' && active.Group !== 'lastname' && active.Group !== 'keyword') {
          related += (related !== '' ? '|' : '') + active.Value
        }
      })

      var qs = ''
      if ($scope.keyword && $scope.keyword !== '') {
        qs = queryStringService.addQueryString('keyword', $scope.keyword, qs)
      } else if (previousKeyword && previousKeyword !== '') {
        qs = queryStringService.addQueryString('keyword', previousKeyword, qs)
      }
      if ($scope.firstname && $scope.firstname !== '') {
        qs = queryStringService.addQueryString('firstname', $scope.firstname, qs)
      } else if (previousFirstName && previousFirstName !== '') {
        qs = queryStringService.addQueryString('firstname', previousFirstName, qs)
      }
      if ($scope.lastname && $scope.lastname !== '') {
        qs = queryStringService.addQueryString('lastname', $scope.lastname, qs)
      } else if (previousLastName && previousLastName !== '') {
        qs = queryStringService.addQueryString('lastname', previousLastName, qs)
      }
      if (letter && letter !== '') { qs = queryStringService.addQueryString('letter', letter, qs) }
      if (related && related !== '') { qs = queryStringService.addQueryString('related', related, qs) }
      if (firstSearch) { qs = queryStringService.addQueryString('noSkip', true, qs) }
      if (pageNum && pageNum > 1) { qs = queryStringService.addQueryString('pageNum', pageNum, qs) }
      qs = queryStringService.addQueryString('pageSize', pageSize, qs)
      qs = queryStringService.addQueryString('sortBy', sortBy, qs)
      qs = queryStringService.addQueryString('sortOrder', sortOrder, qs)
      if (language !== '') { qs = queryStringService.addQueryString('language', language, qs) }
      if (scroll && scroll > 0) { qs = queryStringService.addQueryString('scroll', scroll, qs) }

      return qs
    }

    var updateHistory = function (qs, replace) {
      var historyQs = qs
      historyQs = queryStringService.removeQueryString('noSkip', true, historyQs)
      historyQs = queryStringService.removeQueryString('pageSize', pageSize, historyQs)
      historyQs = queryStringService.removeQueryString('sortBy', sortBy, historyQs)
      historyQs = queryStringService.removeQueryString('sortOrder', sortOrder, historyQs)
      historyQs = queryStringService.removeQueryString('language', language, historyQs)
      // update history & querystring
      if (pageloadSearch || replace) {
        history.replaceState(null, '', historyQs.replace('?&', '?'))
      } else {
        history.pushState(null, '', historyQs.replace('?&', '?'))
      }
    }

    var resetHistory = function () {
      history.pushState(null, document.title, '?')
    }

    var removeActiveFilterByGroup = function (group) {
      $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Group !== group })
    }

    var removeActiveToggleFiltersByGroup = function (group) {
      if (group === 'services' || group === 'subservices') {
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0]
        $scope.subserviceFilters = [$scope.subserviceFilters[0]]
      } else if (group === 'industries' || group === 'subindustries') {
        $scope.selectedIndustry.SelectedToggle = $scope.subindustryFilters[0]
        $scope.subindustryFilters = [$scope.subindustryFilters[0]]
      }
      $scope.filters.toggleFilters = $scope.filters.toggleFilters.filter(function (active) { return active.Name !== group })
    }

    var removeActiveFilterByValue = function (value) {
      $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Value !== value })
    }

    var updateActiveFilters = function () {
      $scope.filters.activeFilters = []
      if ($scope.filters.alphaFilter.SelectedOption && $scope.filters.alphaFilter.SelectedOption.Value !== '') {
        $scope.filters.activeFilters.push({ Group: $scope.filters.alphaFilter.Name, Text: $scope.filters.alphaFilter.SelectedOption.Text, Value: $scope.filters.alphaFilter.SelectedOption.Value });
      }
      angular.forEach($scope.filters.selectFilters, function (filter) {
        if (filter.SelectedOption && filter.SelectedOption.Value !== '') {
          if (filter.SelectedOption.SelectedToggle && filter.SelectedOption.SelectedToggle.Value !== '') {
            $scope.filters.activeFilters.push({ Group: filter.Name, Text: filter.SelectedOption.SelectedToggle.Text, Value: filter.SelectedOption.SelectedToggle.Value })
          } else {
            $scope.filters.activeFilters.push({ Group: filter.Name, Text: filter.SelectedOption.Text, Value: filter.SelectedOption.Value })
          }
        }
      })
    }

    var resetSearch = function () {
      $scope.filters.activeFilters = []
      resetSelectedFilters()
      resetHistory()
      $scope.hasSearchRun = false
      $scope.results = []
      pageNum = 1
      isViewMoreSearch = false
      previousKeyword = ''
      previousFirstName = ''
      previousLastName = ''
      $scope.subserviceFilters = [$scope.subserviceFilters[0]]
      $scope.subindustryFilters = [$scope.subindustryFilters[0]]
    }

    var resetSelectedFilters = function () {
      $scope.filters.alphaFilter.SelectedOption = {};
      angular.forEach($scope.filters.selectFilters, function (filter) {
        filter.SelectedOption = {}
        if (filter.Options && filter.Options.length > 0) {
          filter.Options.forEach(function (option) {
            option.SelectedToggle = {}
          })
          filter.SelectedOption = filter.Options[0]
        }
        if (filter.ExcludeIds) { filter.ExcludeIds = null }
        if (filter.Keyword) { filter.Keyword = '' }
        if (filter.FirstName) { filter.FirstName = '' }
        if (filter.LastName) { filter.LastName = '' }
      })
      $scope.filters.toggleFilters = []
    }

    init()
  }])
}
