module.exports = function (app) {
  app.factory('scrollToService', ['$window', function ($window) {
    return {
      scrollToElement: function (element) {
        let scroll = new window.SmoothScroll()

        if (element) {
            scroll.animateScroll(element, {
                speed: 600,
                offset: 50,
                easing: 'easeInOutCubic'
            })
        } 

        return element
      }
    }
  }])
}
