﻿module.exports = function (app) {
    app.factory('helperService', function () {
        return {
            isNullOrEmpty: function (value) {
                if (value != undefined && value !== null && value != '') {
                    return false;
                }
                return true;
            },
            stringToBoolean: function (value) {
                if (value === undefined)
                    return false;
                switch (value.toLowerCase()) {
                    case 'true': case 'yes': case '1': return true;
                    case 'false': case 'no': case '0': case null: return false;
                    default: false;
                }
            },
            tryParseInt: function (str, fallback) {
                var value = fallback;
                if (str !== null) {
                    if (str.length > 0) {
                        if (!isNaN(str)) {
                            value = parseInt(str);
                        }
                    }
                }
                return value;
            },
        };
    })
}