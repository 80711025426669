﻿module.exports = function (app) {
    app.factory('cookieService', ['$http', 'queryStringService', function ($http, queryStringService) {
        return {
            getCookie: function (name) {
                var cname = name + '=';
                var decodedCookies = decodeURIComponent(document.cookie);
                var ca = decodedCookies.split('; ');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == '') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(cname) == 0) {
                        return c.substring(cname.length, c.length);
                    }
                }
                return '';
            },
            setCookie: function (name, value, months) {
                if (!name) return;
                if (!value) return;

                var endpoint = '/sitecore/api/ssc/webapi/cookies/1/cookie';
                var query = '';
                query = queryStringService.addQueryString('name', name, query);
                query = queryStringService.addQueryString('value', value, query);
                query = queryStringService.addQueryString('months', months, query);

                $http({ method: 'POST', url: endpoint + query })
                    .then(function (response) {
                    }, function (error) {
                    });
            },
        };
    }]);
}