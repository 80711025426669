module.exports = function (app) {
  app.directive('introanim',
    ['$rootScope', 'appFactory', function ($rootScope, appFactory) {
      return {
        restrict: 'A',
        scope: true,
        bindToController: {
          'animation': '&'
        },
        controllerAs: 'togglerctrl',
        controller: ['$scope', function ($scope) {
          $scope.animation = {
            ready: false,
            start: false
          }
          $scope.activate = function () {
            $scope.animation.start = true
          }
        }],
        link: {
          post: function ($scope, $element, $attrs) {
            angular.element(function () {
              $scope.activate()
              $element.removeClass($attrs.removeClass)
            })
          }
        }
      }
    }]
  )
}
