module.exports = function (app) {
  app.directive('searchshare',
    ['$rootScope', 'appFactory', function ($rootScope, appFactory) {
      return {
        restrict: 'A',
        scope: true,
        bindToController: {
        },
        controllerAs: 'togglerctrl',
        controller: ['$scope', function ($scope) {
          $scope.url = (window.location.href)
        }],
        link: {
          post: function ($scope, $element, $attrs) {
            $scope.onShareSearch = function () {
              $scope.url = (window.location.href)
              // just the query string = window.location.search
              //window.alert($scope.url)
              window.location.href = "mailto:?body=" + encodeURIComponent($scope.url);
            }
          }
        }
      }
    }]
  )
}
