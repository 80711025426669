/*
  NOTE: Scrolltracker is designed to track the
    scroll position of each selected element individually
    based on !!window-scroll only!!
*/
module.exports = function (app) {
    app.factory('scrollTrackerFactory',
      ['$rootScope', function ($rootScope) {
          return {
              tracker: {
                  initialized: false,
                  scrollDown: false,
                  scrollUp: false,
                  windowPosition: 0,
                  items: [],
                  stickyItems: [],
                  stickyLimiters: [],
                  stickyPlaceholders: [],
                  addItem: function () {
                      var id = this.items.length

                      this.items[id] = {
                          id: id,
                          active: false
                      }

                      return this.items[id]
                  },
                  addStickyLimiter: function (element, id) {
                      var limit = 0;
                      var rect = element.getBoundingClientRect();
                      if (rect.width || rect.height || element.getClientRects().length) {
                          var doc = element.ownerDocument;
                          var docElem = doc.documentElement;

                          limit = rect.top + window.pageYOffset - docElem.clientTop;
                      }

                      var height = element.offsetHeight

                      limit = (limit !== undefined) ? limit : null
                      height = (height !== undefined) ? height : null

                      id = (id !== undefined) ? id : this.stickyLimiters.length

                      this.stickyLimiters[id] = {
                          'id': id,
                          'limit': limit,
                          'limitHeight': height,
                          'element': element
                      }

                      if (this.stickyItems[id] !== undefined) this.stickyItems[id].limit = limit
                      return this.stickyLimiters[id]
                  },
                  updateStickyLimiter: function (id) {
                      if (id === undefined) return

                      var obj = this.stickyLimiters[id]
                      if (obj === undefined) return

                      var limit = 0;
                      var rect = obj.element.getBoundingClientRect();
                      if (rect.width || rect.height || obj.element.getClientRects().length) {
                          var doc = obj.element.ownerDocument;
                          var docElem = doc.documentElement;

                          limit = rect.top + window.pageYOffset - docElem.clientTop;
                      }

                      var height = obj.offsetHeight
                      limit = (limit !== undefined) ? limit : null
                      height = (height !== undefined) ? height : null

                      this.stickyLimiters[id] = {
                          'id': id,
                          'limit': limit,
                          'limitHeight': height,
                          'element' : obj.element
                      }
                      if (this.stickyItems[id] !== undefined) this.stickyItems[id].limit = limit
                      return this.stickyLimiters[id]
                  },
                  addStickyPlaceholder: function (top, id) {
                      id = (id !== undefined) ? id : this.stickyPlaceholders.length

                      this.stickyPlaceholders[id] = {
                          'id': id,
                          'top': top
                      }

                      if (this.stickyItems[id] !== undefined) this.stickyItems[id].top = top
                      return this.stickyPlaceholders[id]
                  },
                  updateStickyPlaceholder: function (id, top) {
                      if (id === undefined) return
                      top = (top !== undefined) ? top : null

                      this.stickyPlaceholders[id] = {
                          'id': id,
                          'top': top
                      }

                      if (this.stickyItems[id] !== undefined) this.stickyItems[id].top = top
                      return this.stickyPlaceholders[id]
                  },
                  addStickyItem: function (settings) {
                      settings = (settings !== undefined) ? JSON.parse(settings) : {}
                      var id = (settings.id !== undefined) ? settings.id : this.stickyItems.length
                      var limit = (this.stickyLimiters[id] !== undefined) ? this.stickyLimiters[id].limit : null

                      this.stickyItems[id] = angular.extend({}, {
                          'id': id,
                          'offset': 0,
                          'elPosition': 0,
                          'elHeight': 0,
                          'elStartPosition': 0,
                          'reachedlimit': false,
                          'limit': limit,
                          'style': {
                              'top': '',
                              'bottom': '',
                              'position': ''
                          }
                      }, settings)
                      $rootScope.$broadcast('stickyitemadded', id)
                      return this.stickyItems[id]
                  },
                  setActive: function (id) {
                      this.items[id].active = true
                      return this.items[id]
                  },
                  setWindowPosition: function (pos) {
                      if (this.windowPosition === 0)
                      {
                        this.scrollDown = false;
                        this.scrollUp = pos > 0;
                      }
                      else 
                      {
                        this.scrollDown = (pos !== 0 && pos > this.windowPosition)
                        this.scrollUp = (pos !== 0 && pos <= this.windowPosition)
                      }
                      this.windowPosition = pos
                  },
                  getElementOffset: function (el) {
                      var _x = 0
                      var _y = 0
                      while (el && !isNaN(el.offsetLeft && !isNaN(el.offsetTop))) {
                          _x += el.offsetLeft - el.scrollLeft
                          _y += el.offsetTop - el.scrollTop
                          el = el.offsetParent
                      }
                      return { top: _y, left: _x }
                  },
                  getTopPosition: function (id, $element) {
                      const placeholder = this.stickyPlaceholders[id]
                      const top = (placeholder) ? placeholder.top : this.getElementOffset($element[0]).top
                      return top
                  }
              }
          }
      }
      ])

    app.directive('scrollTracker',
      ['$rootScope', 'scrollTrackerFactory', '$window',
        function ($rootScope, scrollTrackerFactory, $window) {
            return {
                restrict: 'A',
                scope: true,
                bindToController: {
                    scrollItems: '@'
                },
                controllerAs: 'ctrl',
                controller: ['$scope', function ($scope) {
                    $scope.scrollData = scrollTrackerFactory.tracker
                }],
                link: function ($scope, $element, $attrs) {
                    angular.element(document).ready(function () {
                        $scope.scrollData.initialized = true
                        //$scope.scrollData.setWindowPosition($window.pageYOffset, true)
                        $scope.$apply()
                    })

                    angular.element($window).bind('scroll', function (event) {
                        $scope.scrollData.setWindowPosition($window.pageYOffset)
                        $scope.$apply()
                    })

                    // Set initial window position in case the page is reloaded when
                    // scrolled down the page
                    $scope.scrollData.setWindowPosition($window.pageYOffset)
                }
            }
        }]
    )

    app.directive('scrollTrackerItem',
      ['appFactory', 'scrollTrackerFactory', function (appFactory, scrollTrackerFactory) {
          return {
              restrict: 'A',
              require: '^scrollTracker',
              scope: true,
              bindToController: {
                  scrollItem: '&',
                  settings: '&'
              },
              controllerAs: 'ctrl',
              controller: ['$scope', function ($scope) {
                  $scope.settings = {
                      offset: false,
                      repeat: true
                  }
                  $scope.scrollItem = $scope.scrollData.addItem()
              }],
              link: {
                  pre: function ($scope, $element, $attrs) {
                      var exsettings = $attrs.scrollTrackerItem || null
                      if (exsettings) {
                          exsettings = JSON.parse(exsettings)
                          $scope.settings = angular.extend({}, $scope.settings, exsettings)
                      }
                  },
                  post: function ($scope, $element, $attrs) {
                      // if the scroll position is above the element top - offset
                      // and is below the max height - plus bottom offset
                      // set to active
                      // else set to inactive
                      $scope.$watch('scrollData.windowPosition', function (position) {
                          const autoOffset = ($scope.settings.offset === true)

                          position = (autoOffset) ? position + window.innerHeight : position
                          const elheight = $element[0].offsetHeight

                          const autoOffsetMobile = (appFactory.isMobile) ? -(elheight / 6) : (elheight / 4);
                          const offset = (autoOffset) ? autoOffsetMobile : (isNaN($scope.settings.offset)) ? 0 : parseInt($scope.settings.offset);

                          var sectionTopPosition = $scope.scrollData.getElementOffset($element[0]).top
                          var sectionPosition = (sectionTopPosition + offset)

                          var isActive = (position > sectionPosition)

                          if ($scope.settings.repeat === false) {
                              if ($scope.scrollItem.active !== true) $scope.scrollItem.active = isActive
                          } else {
                              $scope.scrollItem.active = isActive
                          }
                      }, true)
                  }

              }
          }
      }]
    )

    app.directive('scrollStickyLimiter',
      ['$rootScope', 'appFactory', 'scrollTrackerFactory', function ($rootScope, appFactory, scrollTrackerFactory) {
          return {
              restrict: 'A',
              require: '^scrollTracker',
              scope: true,
              bindToController: {
                  scrollStickyLimiter: '&'
              },
              controllerAs: 'ctrl',
              controller: ['$scope', function ($scope) {
                  $scope.scrollData = scrollTrackerFactory.tracker
                  $scope.scrollStickyLimiter = {}
              }],
              link: {
                  post: function ($scope, $element) {
                      $scope.scrollStickyLimiter = $scope.scrollData.addStickyLimiter($element[0])
                  }
              }
          }
      }]
    )

    app.directive('scrollStickyPlaceholder',
      ['$rootScope', 'appFactory', 'scrollTrackerFactory', '$window', function ($rootScope, appFactory, scrollTrackerFactory, $window) {
          return {
              restrict: 'A',
              require: '^scrollTracker',
              scope: true,
              bindToController: {
                  scrollStickyPlaceholder: '&'
              },
              controllerAs: 'ctrl',
              controller: ['$scope', function ($scope) {
                  $scope.scrollData = scrollTrackerFactory.tracker
                  $scope.scrollStickyPlaceholder = {}
              }],
              link: {
                  pre: function ($scope, $element, $attrs) {
                      var top = $scope.scrollData.getElementOffset($element[0]).top
                      $scope.scrollStickyPlaceholder = $scope.scrollData.addStickyPlaceholder(top)
                  },
                  post: function ($scope, $element, $attrs) {
                      $rootScope.$on('windowresize-windowSize', function () {
                          var top = $scope.scrollData.getElementOffset($element[0]).top

                          $scope.scrollData.updateStickyPlaceholder($scope.scrollStickyPlaceholder.id, top)
                      }, true)
                  }
              }
          }
      }]
    )

    app.directive('scrollStickyItem',
      ['$rootScope', 'appFactory', 'scrollTrackerFactory', '$window', function ($rootScope, appFactory, scrollTrackerFactory, $window) {
          return {
              restrict: 'A',
              require: '^scrollTracker',
              scope: true,
              bindToController: {
                  scrollStickyItem: '&'
              },
              controllerAs: 'ctrl',
              controller: ['$scope', function ($scope) {
                  $scope.scrollStickyItem = {}
                  $scope.windowWidth = $window.screen.width;
              }],
              link: {
                  pre: function ($scope, $element, $attrs) {
                      $scope.initialPosition = $element[0].offsetTop;
                      var exsettings = $attrs.scrollStickyItem || {}
                      $scope.scrollStickyItem = $scope.scrollData.addStickyItem(exsettings)
                  },
                  post: function ($scope, $element, $attrs) {
                      $scope.checkPosition = function (winposition) {
                          $scope.scrollStickyItem.elPosition = $scope.scrollData.getTopPosition($scope.scrollStickyItem.id, $element)
                          $scope.scrollStickyItem.elHeight = $element[0].offsetHeight

                          $scope.scrollData.updateStickyLimiter($scope.scrollStickyItem.id)

                          var winPos = winposition
                          var hitBottom = winPos >= ($scope.initialPosition - $scope.scrollStickyItem.offset)
                          var bottom = winPos + $scope.scrollStickyItem.elHeight
                          var hasLimit = !isNaN($scope.scrollStickyItem.limit)
                          var newElTop = 0

                          if (hasLimit && $scope.scrollStickyItem.limit <= bottom) {
                              newElTop = -(
                                Math.round(
                                    (winPos -
                                    ($scope.scrollStickyItem.limit -
                                    $scope.scrollStickyItem.elHeight))
                                  )
                              ) + 'px'

                              $scope.scrollStickyItem.reachedlimit = true
                          } else if ((hasLimit && $scope.scrollStickyItem.limit > bottom)) {
                              newElTop = Math.round($scope.scrollStickyItem.offset) + 'px'
                              $scope.scrollStickyItem.reachedlimit = false
                          } else {
                              newElTop = Math.round($scope.scrollStickyItem.offset) + 'px'
                              $scope.scrollStickyItem.reachedlimit = false
                          }

                          $scope.scrollStickyItem.style = {
                              'position': (hitBottom) ? 'fixed' : '',
                              'top': (hitBottom) ? newElTop : ''
                          }
                      }

                      $scope.$watch('scrollData.windowPosition', function (position) {
                          $scope.checkPosition(position)
                      }, true)
                  }
              }
          }
      }]
    )
}
