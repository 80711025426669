﻿module.exports = function (app) {
    app.controller('cookieNotification', ['$window', '$scope', '$http', '$element', 'cookieService', function ($window, $scope, $http, $element, cookieService) {
        $scope.active = false;

        var ev_cookienotification_open = 'ev_cookienotification_open';
        var cookieName = 'website#show_cookie_prompt';
        var expirationInMonths = 6;

        function init() {
            var cvalue = cookieService.getCookie(cookieName);
            $scope.active = !cvalue || cvalue === 'true';

            $scope.$on(ev_cookienotification_open, function (event, args) {
                cookieService.setCookie(cookieName, 'true', 0);
                $scope.active = true;
            });
        }

        $scope.onAccept = function () {
            $http({ method: 'GET', url: '/CookieAcceptance.axd?accept=AllowAllCookies' })
                .then(function (response) {
                    cookieService.setCookie(cookieName, 'false', expirationInMonths);
                    $scope.active = false;
                },
                function (error) {
                });
        };

        $scope.onDecline = function () {
            $http({ method: 'GET', url: '/CookieAcceptance.axd?accept=AllowSessionCookies' })
                .then(function (response) {
                    cookieService.setCookie(cookieName, 'false', 0);
                    $scope.active = false;
                },
                function (error) {
                });
        };

        init();
    }]);
}