/*
  NOTE: body overlay
*/

module.exports = function (app) {
  app.directive('bodyOverlay',
    ['appFactory', '$rootScope', function (appFactory, $rootScope) {
      return {
        restrict: 'A',
        scope: true,
        bindToController: {
          overlay: '&'
        },
        controllerAs: 'ctrl',
        controller: ['$scope', function ($scope) {
          $scope.overlay = appFactory.overlay
        }],
        link: function ($scope) {
          $scope.onTriggerClose = function () {
            appFactory.deactivateCaller()
          }
        }
      }
    }]
  )
}
