﻿module.exports = function (app) {
    app.factory('searchService', ['$http', 'queryStringService', function ($http, queryStringService) {
        return {
            search: function (endpoint, query, successCallback, errorCallback) {
                if (!endpoint || endpoint === '' || typeof endpoint === 'undefined') errorCallback();
                if (!endpoint.startsWith('/')) endpoint = '/' + endpoint;
                if (endpoint.endsWith('/')) endpoint.slice(0, -1);

                query = queryStringService.addQueryString('_dt', Date.now(), query);

                $http({ method: 'GET', url: endpoint + query })
                    .then(function (response) {
                        successCallback(response);
                    }, function (error) {
                        errorCallback(error);
                    });
            },
            getKeywordDisplay: function(keyword){
                if(keyword[0] != "\"")
                    keyword = "\"" + keyword
                if(keyword[keyword.length - 1] != "\"")
                    keyword =  keyword + "\""
    
                return keyword
            },
        };
    }]);
}