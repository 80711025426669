﻿module.exports = function (app) {
    app.controller('servicesLanding', ['$scope', '$element', 'queryStringService', 'searchService', 'helperService', 'scrollToService', function ($scope, $element, queryStringService, searchService, helperService, scrollToService) {

        //SCOPE VARIABLES
        $scope.isLoading = false;
        $scope.showAll = false;
        $scope.showGroup1All = false;
        $scope.showGroup2All = false;
        $scope.enableInfiniteScroll = false;
        $scope.filters = {
            toggleFilters: [],
            selectFilters: [],
            activeFilters: []
        };
        $scope.keyword = '';

        $scope.totalResultCount = 0;
        $scope.results = [];

        $scope.activeSearch = false;
        $scope.serverActiveFilters = [];
        $scope.showClearAll = false;
        $scope.resultsLabel = '';
        $scope.noResultsFound = false;

        //PRIVATE VARIABLES
        var endpoint = '';
        var resultsLabel = ''
        var pageloadSearch = false;
        var resultsElement;
        var previousKeyword = '';
        var isExperienceEditor = false;
        var isViewMoreSearch = false;
        var scrollPosition = false;

        var init = function () {
            if ($element.attr('data-search-endpoint')) {
                endpoint = $element.attr('data-search-endpoint');
            }
            if ($element.attr('data-is-experience-editor')) {
                isExperienceEditor = true;
            }
            if ($element.attr('data-resultslabel')) {
                resultsLabel = $element.attr('data-resultslabel');
            }
            if (document.getElementById('json-select-filters')) {
                $scope.filters.selectFilters = JSON.parse(document.getElementById('json-select-filters').text);
            }
            if (document.getElementById('json-active-filters')) {
                $scope.serverActiveFilters = JSON.parse(document.getElementById('json-active-filters').text);
            }
            if (document.getElementById('scrollToTarget')) {
                resultsElement = document.getElementById('scrollToTarget');
            }

            if ($scope.filters.selectFilters && $scope.filters.selectFilters.length > 0) {
                $scope.select = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced == false; });
                $scope.advanced = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced == true; });
            }

            if (queryStringService.hasQueryString()) {
                setFilters();
            }
            pageloadSearch = $scope.filters.activeFilters.length > 0;
            search();
        }

        $scope.onSubmit = function () {
            removeActiveFilterByGroup('keyword');
            if ($scope.keyword) {
                addActiveKeywordFilter($scope.keyword);
            }

            search();
        }

        $scope.showFilter = function(filter) {
          return !filter.Category || !$scope.filters.categoryFilter || filter.Category.includes($scope.filters.categoryFilter.SelectedOption.Value);
        }

        $scope.returnToTop = function () {
            document.documentElement.scrollTop = 0;
        }

        $scope.isGroup1OpenAll = function () {
            var group1Items = [];
            var activeAccordionsCount = 0;
            var closedAccordionsCount = 0;
            var allAccordionItems = $(".accordion__item");
            angular.forEach(allAccordionItems, function (item) {
                var group = $(item).attr('data-accordion-responder');
                if (group.indexOf('"group": "1"') >= 0) {
                    group1Items.push(item.childNodes[3]);
                }
            })

            angular.forEach(group1Items, function (groupItem) {
                if ($(groupItem).hasClass("accordion__inner--active")) {
                    activeAccordionsCount++;
                }
                if ($(groupItem).hasClass("accordion__inner--closed")) {
                    closedAccordionsCount++;
                }
            })

            if (group1Items.length === activeAccordionsCount && closedAccordionsCount === 0) {
                $scope.showGroup1All = true;
                return false;
            }
            if (group1Items.length === closedAccordionsCount) {
                $scope.showGroup1All = false;
                return true;
            }
            if (!$scope.showGroup1All) {
                return true;
            }
        }

        $scope.isGroup1CloseAll = function () {
            var group1Items = [];
            var activeAccordionsCount = 0;
            var closedAccordionsCount = 0;
            var allAccordionItems = $(".accordion__item");
            angular.forEach(allAccordionItems, function (item) {
                var group = $(item).attr('data-accordion-responder');
                if (group.indexOf('"group": "1"') >= 0) {
                    group1Items.push(item.childNodes[3]);
                }
            })

            angular.forEach(group1Items, function (groupItem) {
                if ($(groupItem).hasClass("accordion__inner--active")) {
                    activeAccordionsCount++;
                }
                if ($(groupItem).hasClass("accordion__inner--closed")) {
                    closedAccordionsCount++;
                }
            })

            if (group1Items.length === closedAccordionsCount && activeAccordionsCount === 0) {
                $scope.showGroup1All = false;
                return false;
            }
            if (group1Items.length === activeAccordionsCount) {
                $scope.showGroup1All = true;
                return true;
            }
            if ($scope.showGroup1All) {
                return true;
            }
        }

        $scope.isGroup2OpenAll = function () {
            var group2Items = [];
            var activeAccordionsCount = 0;
            var closedAccordionsCount = 0;
            var allAccordionItems = $(".accordion__item");
            angular.forEach(allAccordionItems, function (item) {
                var group = $(item).attr('data-accordion-responder');
                if (group.indexOf('"group": "2"') >= 0) {
                    group2Items.push(item.childNodes[3]);
                }
            })

            angular.forEach(group2Items, function (groupItem) {
                if ($(groupItem).hasClass("accordion__inner--active")) {
                    activeAccordionsCount++;
                }
                if ($(groupItem).hasClass("accordion__inner--closed")) {
                    closedAccordionsCount++;
                }
            })

            if (group2Items.length === activeAccordionsCount && closedAccordionsCount === 0) {
                $scope.showGroup2All = true;
                return false;
            }
            if (group2Items.length === closedAccordionsCount) {
                $scope.showGroup2All = false;
                return true;
            }
            if (!$scope.showGroup2All) {
                return true;
            }
        }

        $scope.isGroup2CloseAll = function () {
            var group2Items = [];
            var activeAccordionsCount = 0;
            var closedAccordionsCount = 0;
            var allAccordionItems = $(".accordion__item");
            angular.forEach(allAccordionItems, function (item) {
                var group = $(item).attr('data-accordion-responder');
                if (group.indexOf('"group": "2"') >= 0) {
                    group2Items.push(item.childNodes[3]);
                }
            })

            angular.forEach(group2Items, function (groupItem) {
                if ($(groupItem).hasClass("accordion__inner--active")) {
                    activeAccordionsCount++;
                }
                if ($(groupItem).hasClass("accordion__inner--closed")) {
                    closedAccordionsCount++;
                }
            })

            if (group2Items.length === closedAccordionsCount && activeAccordionsCount === 0) {
                $scope.showGroup2All = false;
                return false;
            }
            if (group2Items.length === activeAccordionsCount) {
                $scope.showGroup2All = true;
                return true;
            }
            if ($scope.showGroup2All) {
                return true;
            }
        }

        $scope.openAllGroup1Accordions = function () {
            $scope.$emit('open-all-group1-accordions');
        }

        $scope.closeAllGroup1Accordions = function () {
            $scope.$emit('close-all-group1-accordions');
        }

        $scope.openAllGroup2Accordions = function () {
            $scope.$emit('open-all-group2-accordions');
        }

        $scope.closeAllGroup2Accordions = function () {
            $scope.$emit('close-all-group2-accordions');
        }

        $scope.onViewAllExperienceItems = function () {
            window.location.href = "/services/experience";
        }

        $scope.onFilterInit = function ($event, filter) {
            if (!filter.SelectedOption)
                filter.SelectedOption = filter.Options[0];
        }

        $scope.onFilterChange = function ($event, filter) {
            if (!(filter && filter.SelectedOption)) return;

            if (filter.SelectedOption.Value === '') {
                removeActiveFilterByGroup(filter.Name);
            }
            else {
                removeActiveFilterByGroup(filter.Name);
                addActiveSelectFilter(filter);

                filter.Options.forEach(function (option) {
                    if (option.Toggles && option.Toggles.length > 0) {
                        option.SelectedToggle = {};
                    }
                });
            }

            search();
        }

        $scope.onFilterToggleSelect = function ($event, name, option, toggle) {
            if (!toggle || !toggle.IsEnabled) return;

            option.SelectedToggle = toggle;

            removeActiveFilterByGroup(name);
            addActiveFilter(name, toggle.Text, toggle.Value);

            search();
        }

        $scope.onSubFilterChange = function ($event, name, toggle) {
            removeActiveFilterByGroup("sub" + name);

            search();
        }

        var addActiveKeywordFilter = function (keyword) {
            keyword = searchService.getKeywordDisplay(keyword);
            addActiveFilter('keyword', keyword, keyword);
        }

        var addActiveSelectFilter = function (filter) {
            if (filter && filter.SelectedOption) {
                addActiveFilter(filter.Name, filter.SelectedOption.Text, filter.SelectedOption.Value);
            }
        }

        var addActiveFilter = function (group, text, value) {
            $scope.showClearAll = true;
            $scope.filters.activeFilters.push({ Group: group, Text: text, Value: value });
        }

        $scope.onRemoveFilterAll = function (event) {
            resetSearch();
        }

        var removeActiveFilterByGroup = function (group) {
            $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Group !== group; });
        }

        var removeActiveFilterByValue = function (value) {
            $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Value !== value });
        }

        var resetSearch = function () {
            $scope.filters.activeFilters = [];
            $scope.activeSearch = false;

            resetSelectedFilters();
            resetHistory();
            resetKeyword();
            resetResults();

            $scope.showClearAll = false;

            previousKeyword = '';
        }

        var resetSelectedFilters = function () {
            angular.forEach($scope.filters.selectFilters, function (filter) {
                filter.SelectedOption = {};
                if (filter.Options && filter.Options.length > 0) {
                    filter.Options.forEach(function (option) {
                        option.SelectedToggle = {};
                    });
                    filter.SelectedOption = filter.Options[0];
                }
                if (filter.ExcludeIds)
                    filter.ExcludeIds = null;
                if (filter.Keyword)
                    filter.Keyword = '';
            });
            $scope.filters.toggleFilters = [];
        }

        $scope.onRemoveFilter = function (event, active) {
            removeActiveFilterByValue(active.Value);
            if (active.Group == 'keyword') {
                resetKeyword();
            }

            angular.forEach($scope.filters.selectFilters, function (filter) {
                if (filter.Name === active.Group) {
                    if (filter.Options && filter.Options.length > 0) {
                        filter.SelectedOption = filter.Options[0];
                    } else {
                        filter.SelectedOption = {};
                    }
                    if (filter.ExcludeIds)
                        filter.ExcludeIds = null;
                    if (filter.Keyword)
                        filter.Keyword = '';
                }
            });

            if ($scope.filters.activeFilters.length === 0) {
                resetSearch();
            }
            else {
                search();
            }
        }

        var resetKeyword = function () {
            previousKeyword = '';
            $scope.keyword = '';
        }

        var resetResults = function () {
            $scope.results = [];
            $scope.totalResultCount = 0;
        }

        var buildQueryString = function () {
            var qs = '';

            if ($scope.keyword && $scope.keyword !== '') {
                qs = queryStringService.addQueryString('keyword', $scope.keyword, qs);
            } else if (previousKeyword && previousKeyword !== '') {
                qs = queryStringService.addQueryString('keyword', previousKeyword, qs);
            }

            return qs;
        }

        var search = function () {
            if (isExperienceEditor) return;

            if (!($scope.filters.activeFilters.length > 0 || $scope.keyword !== '')) {
                resetSearch();
                return;
            }

            //if they fill out text input field and click an advanced filter
            if ($scope.filters.activeFilters.filter(function (x) { return x.Group === "keyword" }) === undefined && $scope.keyword !== '')
                addActiveFilter("keyword", $scope.keyword, $scope.keyword)

            $scope.isLoading = true;

            var qs = buildQueryString();

            updateHistory(qs, isViewMoreSearch);

            searchService.search(endpoint, qs, handleSearchSuccess, handleSearchFailure);
        }

        var handleSearchSuccess = function (response) {
            if (response.data.TotalResultCount > 0) {
                $scope.activeSearch = true;
                $scope.totalResultCount = response.data.TotalResultCount;
                $scope.results = response.data.Results;
                $scope.resultsLabel = updateSectionLabel(resultsLabel, response.data.TotalResultCount);
                $scope.noResultsFound = false;
            } else {
                $scope.activeSearch = true;
                $scope.resultsLabel = updateSectionLabel(resultsLabel, 0);
                $scope.noResultsFound = true;
                resetResults();
            }

            if (scrollPosition > 0) {
                $timeout(function () {
                    window.scroll(0, scrollPosition);
                });
            }
            else if ($scope.filters.activeFilters.length === 1 && !!resultsElement && !pageloadSearch && !isViewMoreSearch) {
                scrollToService.scrollToElement(resultsElement);
            }

            if ($scope.keyword !== '' && $scope.keyword !== previousKeyword) {
                previousKeyword = $scope.keyword;
            }

            pageloadSearch = false;
            isViewMoreSearch = false;
            $scope.isLoading = false;
        }

        var handleSearchFailure = function (response) {
            resetKeyword();
            resetResults();

            pageloadSearch = false;
            isViewMoreSearch = false;
            $scope.isLoading = false;
        }

        var updateHistory = function (qs, replace) {
            var historyQs = qs;

            //update history & querystring
            if (pageloadSearch || replace) {
                history.replaceState(null, document.title, historyQs.replace('?&', '?'));
            }
            else {
                history.pushState(null, document.title, historyQs.replace('?&', '?'));
            }
        }

        var resetHistory = function () {
            history.pushState(null, document.title, '?');
        }

        var setFilters = function () {
            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('keyword'))) {
                var keyword = queryStringService.getParameterByName('keyword');
                if (keyword) {
                    $scope.keyword = keyword;
                    addActiveKeywordFilter(keyword);
                }
            }

            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('scroll'))) {
                scrollPosition = queryStringService.getParameterByName('scroll');
            }
        }

        var updateSectionLabel = function (sectionLabel, count) {
            if (sectionLabel) {
                if (sectionLabel.indexOf('##COUNT##') > 0) {
                    return sectionLabel.replace('##COUNT##', count);
                }
            }

            return sectionLabel;
        }

        init();
    }]);
};