const setup = function (app) {
    app.directive('tabs',
      ['$rootScope', 'appFactory', '$location', '$timeout', function ($rootScope, appFactory, $location, $timeout) {
          return {
              restrict: 'A',
              scope: true,
              bindToController: {
                  tabs: '&',
                  settings: '&'
              },
              controllerAs: 'tabsctrl',
              controller: ['$scope', function ($scope) {
                  $scope.tabs = []
                  $scope.locationPath = ''

                  $scope.settings = {
                      initialactive: 0,
                      hasactive: false,
                      hastabs: false,
                      dropdownactive: false,
                      title: 'Select an option...'
                  }

                  $scope.setFirstActive = function (replace) {
                      $scope.activate($scope.settings.initialactive, replace)
                  }

                  $scope.activate = function (which, replace) {
                      var hasactive = false

                      angular.forEach($scope.tabs, function (item, key) {
                          item.active = (item.id === which)
                          if (item.active) {
                              if (item.title.length > 0) $scope.settings.title = item.title
                              hasactive = true
                              $scope.settings.dropdownactive = false
                              if (replace) {
                                history.replaceState(null, document.title, $location.absUrl() + ($location.absUrl().indexOf('#') > -1 ? '' : '#' + item.elId))
                              }
                              else {
                                $location.hash(item.elId)
                              }
                          }
                      })
                      $scope.settings.hasactive = hasactive

                      if (!hasactive) {
                          $scope.settings.title = 'Select an option...'
                      }
                  }

                //   This was creating a new History entry on pageload which appended a # at the end. then 
                //   $scope.decodeUrlHash = function() {
                //       console.log('locationhash', location.hash, location.hash.replace("/", ""))
                //     location.hash = decodeURIComponent(location.hash.replace("/", ""));
                //   }

                  $scope.settings.hasactive = false

                  $scope.settings.title = 'Select an option...'
                  //TODO: Fix This
                  $scope.getElId = function (id, title, englishId) {
                      if(englishId){
                          title = englishId;
                      }
                      if (title !== undefined) {
                          var t = title.replace(/[^a-zA-Z0-9-_]/g, '')
                          return 'tab-' + t
                      } else {
                          return 'tab-' + id
                      }
                  }

                  $scope.addTab = function (settings) {
                      var id = Object.keys($scope.tabs).length
                      settings = (settings) ? JSON.parse(settings) : {}
                      var englishId = settings.englishTitle ? settings.englishTitle : undefined;
                      $scope.tabs[id] = {
                          id: id,
                          elId: $scope.getElId(id, settings.title, englishId),
                          title: settings.title || '',
                          active: false
                      }
                      return $scope.tabs[id]
                  }
                  $scope.getHashParts = function(locationHash) {
                    let hash = locationHash ? locationHash : location.hash;
                    let parts = hash.split('@');
                    if(parts.length > 1) {
                        return {hash:parts[0], anchor: parts[1]};
                    }
                    return {hash:parts[0]};
                  }
                  $scope.checkToScrollTo = function(hash) {
                    let parts = $scope.getHashParts(hash);
                    if(parts.anchor) {
                        if(document.getElementById(parts.anchor)){
                          document.getElementById(parts.anchor).scrollIntoView({behavior:'smooth'});
                        }
                    }
                  }

                  $scope.toggleDropdown = function () {
                      $scope.settings.dropdownactive = !$scope.settings.dropdownactive
                  }
                  $scope.checkToScrollTo();
              }],
              link: {
                  pre: function ($scope, $element, $attrs) {
                      $scope.locationPath = location.path
                      $scope.checkToScrollTo();
                      var exsettings = $attrs.tabs || null
                      if (exsettings) {
                          exsettings = JSON.parse(exsettings)
                          $scope.settings = angular.extend({}, $scope.settings, exsettings)
                      }
                  },

                  post: function ($scope) {
                      var setActive = function (replace) {
                          if (location.hash !== '')
                          {
                            var validatedHash = validate(location.hash);
                            if (validatedHash !== '')
                            {
                                var active = 0;
                                var fullHash = validatedHash;
                                validatedHash = validatedHash.split('@')[0];
                                var actives = $scope.tabs.filter(function (x) { return '#' + x.elId === validatedHash })
                                if (actives && actives.length > 0)
                                    active = actives[0].id;
                                $scope.activate(active, replace);
                                //scroll to after we activate the right tab
                                $timeout(function() {
                                    $scope.checkToScrollTo(fullHash);
                                }, 400)
                                
                            }
                            else {
                                $scope.checkToScrollTo();
                                location.hash = '#';
                                $scope.setFirstActive(true);
                            }
                          }
                          else {
                            $scope.checkToScrollTo();
                            $scope.setFirstActive(replace)
                          }
                      };

                      var validate = function (hash)
                      {
                          const decodedHash = decodeURIComponent(hash).replace("#!#", "#")
                          return /^#tab-.+$/.test(decodedHash) ? decodedHash : '';
                      }

                      $scope.$watch('tabs', function (data) {
                          $scope.settings.hastabs = (data.length > 1)
                      }, true);

                      $rootScope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
                          $scope.checkToScrollTo();
                          setActive(newUrl === oldUrl);
                      });
                  }
              }
          }
      }]
    )

    app.directive('tabsTriggers',
      [function () {
          return {
              restrict: 'A',
              require: '^tabs',
              template: '<div class="tabs-list" ng-if="settings.hastabs"><button ng-repeat="tab in tabs" class="tabs-list-item" data-ng-class="styleClass(tab.active)" data-ng-click="onTrigger($event, tab.id)"><span class="tabs-list-title" ng-bind="tab.title"></span></button ng-repeat-end></div>',
              scope: true,
              bindToController: {
                  rendertriggers: '&'
              },
              controllerAs: 'ctrl',
              controller: ['$scope', function ($scope) {
              }],
              link: function ($scope) {
                  $scope.styleClass = function (active) {
                      return (active) ? 'active' : ''
                  }
                  $scope.onTrigger = function (event, id) {
                      if (event && event.preventDefault) event.preventDefault()
                      $scope.activate(id)
                  }
              }
          }
      }]
    )

    app.directive('tabsTab',
      [function () {
          return {
              restrict: 'A',
              require: '^tabs',
              scope: true,
              bindToController: {
                  tab: '&'
              },
              controllerAs: 'ctrl',
              controller: ['$scope', function ($scope) {
                  $scope.tab = {}
              }],
              link: {
                  pre: function ($scope, $element, $attrs) {
                      $scope.tab = $scope.addTab($attrs.tabsTab)
                      return $scope.tab
                  }
              }
          }
      }]
    )

    app.directive('tabsTitle',
      [function () {
          return {
              restrict: 'A',
              require: '^tabs',
              template: '<button class="tabs-toptitle" ng-if="settings.hastabs" data-ng-click="onTitleTrigger($event)" data-ng-class="styleClass()"><span class="tabs-toptitle-text" ng-bind="settings.title"></span></button>',
              scope: true,
              bindToController: {
              },
              controllerAs: 'ctrl',
              controller: ['$scope', function ($scope) { }],
              link: function ($scope, $element, $attrs) {
                  $scope.styleClass = function () {
                      return ($scope.settings.dropdownactive) ? 'active' : ''
                  }
                  $scope.onTitleTrigger = function () {
                      $scope.toggleDropdown()
                  }
              }
          }
      }]
    )
}
module.exports = setup
