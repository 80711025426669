﻿module.exports = function (app) {
    app.directive('onenorPdfLink', function () {
        var link = {
            post: function ($scope, $element, $attrs) {
                $attrs.$observe('href', function (val) {
                    var a = $element[0];
                    if ($scope.pdfLink !== undefined && $scope.pdfLink !== '' && val !== undefined && val !== null && val !== '' && val.toLowerCase().indexOf('pdf:') > -1) {
                        a.href = $scope.pdfLink;
                    }
                });
            }
        };

        return {
            replace: false,
            restrict: 'A',
            scope: {
                pdfLink: '@onenorPdfLink'
            },
            link: link
        };
    });
};