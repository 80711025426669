﻿const setup = function (app) {
    app.directive('simpleTabs', function () {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                activeId: '&'
            },
            controllerAs: 'simpleTabsCtrl',
            controller: ['$scope', function ($scope) {
                $scope.activeId = 0;

                $scope.simpleSelect = function (id) {
                    $scope.activeId = id;
                };
            }],
            link: {
                pre: function ($scope, $element, $attrs) {
                    $scope.activeId = 0;
                }
            }
        };
    });
};
module.exports = setup