const App = function () {
    const app = angular.module('App', ['infinite-scroll'])
      .run(['$rootScope', function ($rootScope) {
          var scroll = new window.SmoothScroll('a[href*="#"]', {
              // Selectors
              ignore: '[data-scroll-ignore]', // Selector for links to ignore (must be a valid CSS selector)
              header: null, // Selector for fixed headers (must be a valid CSS selector)
              // Speed & Easing
              speed: 600, // Integer. How fast to complete the scroll in milliseconds
              offset: 50, // Integer or Function returning an integer. How far to offset the scrolling anchor location in pixels
              easing: 'easeInOutCubic'
          })
      }])
      .factory('appFactory', ['$rootScope', function ($rootScope) {
          return {
              overlay: {
                  'active': false,
                  'caller': null
              },
              activateOverlay: function (caller) {
                  if (caller) this.overlay.caller = caller
                  this.overlay.active = true
              },
              deactivateOverlay: function () {
                  this.overlay.active = false
                  this.overlay.caller = null
              },
              deactivateCaller: function () {
                  if (this.overlay.caller && this.overlay.caller.deactivateAll) {
                      this.overlay.caller.deactivateAll()
                  }
              },
              disclaimer: {
                  'active': false,
                  'caller': null
              },
              activateDisclaimer: function (caller) {
                  if (caller) this.disclaimer.caller = caller
                  this.disclaimer.active = true
              },
              deactivateDisclaimer: function () {
                    this.disclaimer.active = false
                    this.disclaimer.caller = null
              },
              windowSizes: {
                  'medium': 992
              },
              menu: {
                  'active': false
              },
              windowSize: 992,
              isMobile: false,
              setWindowSize: function (size) {
                  $rootScope.$emit('windowresize-windowSize', this.windowSize)
                  this.windowSize = size
                  return this.windowSize
              },
              setMobile: function () {
                  this.isMobile = (this.windowSize < this.windowSizes.medium)
                  $rootScope.$emit('windowresize-isMobile', this.isMobile)
                  return this.isMobile
              }
          } // end return obj
      }])
      .config(['$compileProvider', function ($compileProvider) {
          $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|s?ftp|mailto|tel|file|pdf):/);
      }]) 
      .config(['$locationProvider', function ($locationProvider) {
          $locationProvider.html5Mode({
            enabled: true,
            requireBase: false,
            rewriteLinks: false
          })
        }])
    angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 500)
    return app
}
module.exports = App
