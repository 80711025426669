'use strict'
require('string.prototype.startswith') // startswith polyfill
require('string.prototype.endswith') // endswith polyfill
require('string.prototype.includes') // includes polyfill
require('./polyfills/closest')
require('./polyfills/customEvent')
require('./polyfills/requestAnimationFrame')
require('angular')
require('ng-infinite-scroll')
window.$ = window.jQuery = require('jquery')
window.dotdotdot = require('dotdotdot')
window.app = require('./features/onenor-app')()
window.SmoothScroll = require('smooth-scroll') // https://github.com/cferdinandi/smooth-scroll

require('./features/onenor-windowresize')(window.app)
require('./features/onenor-bodyoverlay')(window.app)
require('./features/onenor-scrolltracker')(window.app)
require('./features/onenor-menuwatcher')(window.app)
require('./features/onenor-menutoggler')(window.app)

require('./features/onenor-toggler')(window.app)
require('./features/onenor-tabs')(window.app)
require('./features/onenor-accordion')(window.app)
require('./features/onenor-introanim')(window.app)
require('./features/onenor-searchform')(window.app)
require('./features/onenor-readmore')(window.app)
require('./features/onenor-searchshare')(window.app)
require('./features/onenor-placeholderText')(window.app)
require('./features/onenor-languageprompt')(window.app)

//services
require('./services/onenor-cookie-service')(window.app)
require('./services/onenor-helper-service')(window.app)
require('./services/onenor-querystring-service')(window.app)
require('./services/onenor-search-service')(window.app)
require('./services/onenor-scrollto-service')(window.app)

//directives
require('./directives/onenor-mailto')(window.app)
require('./directives/onenor-pdf-link')(window.app)
require('./directives/onenor-simple-tabs')(window.app)
require('./directives/onenor-smooth-scroll')(window.app)
require('./directives/onenor-emaildisclaimer')(window.app)
require('./directives/onenor-typeahead')(window.app)

//controllers
require('./controllers/onenor-footer')(window.app)
require('./controllers/onenor-insights-landing')(window.app)
require('./controllers/onenor-keywordsearch')(window.app)
require('./controllers/onenor-news-landing')(window.app)
require('./controllers/onenor-professionals-landing')(window.app)
require('./controllers/onenor-services-landing')(window.app)
require('./controllers/onenor-industries-landing')(window.app)
require('./controllers/onenor-experiences-landing')(window.app)
require('./controllers/onenor-sitesearch')(window.app)
require('./controllers/onenor-cookie-notification')(window.app)
require('./controllers/onenor-privacy-policy')(window.app)