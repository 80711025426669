const setup = function (app) {
  app.directive('menutoggler',
    ['$rootScope', 'appFactory', function ($rootScope, appFactory) {
      return {
        restrict: 'A',
        scope: true,
        bindToController: {
          triggers: '&',
          responders: '&',
          settings: '&'
        },
        controllerAs: 'menutogglerctrl',
        controller: ['$scope', function ($scope) {
          $scope.triggers = {}
          $scope.responders = {}

          $scope.settings = {
            usemodal: true,
            initialactive: false,
            hasactive: false
          }

          $scope.setFirstActive = function () {
            if ($scope.settings.initialactive) {
              $scope.activate($scope.settings.initialactive)
            }
          }

          $scope.activate = function (which) {
            var hasactive = false

            angular.forEach($scope.triggers, function (item, key) {
              item.active = (item.active) ? false : (item.id === which)
              if (item.active) {
                hasactive = true
              }
            })

            angular.forEach($scope.responders, function (item, key) {
              item.active = (item.active) ? false : (item.id === which)
            })

            $scope.settings.hasactive = hasactive

            if (!this.settings.usemodal) return

            if (hasactive) {
              appFactory.activateOverlay($scope)
            } else {
              appFactory.deactivateOverlay()
            }
          }

          $scope.deactivateAll = function() {

            angular.forEach($scope.triggers, function(item, key) {
              item.active = false;
            });

            angular.forEach($scope.responders, function(item, key) {
              item.active = false;
            });

            $scope.settings.hasactive = false;

            if(!this.settings.usemodal) return;

            appFactory.deactivateOverlay()
          };

          $scope.addTrigger = function (id) {
            id = (id !== undefined) ? id : Object.keys($scope.triggers).length + 1

            $scope.triggers[id] = {
              id: id,
              active: false
            }
            return $scope.triggers[id]
          }

          $scope.addResponder = function (id) {
            id = (id !== undefined) ? id : Object.keys($scope.responders).length + 1;

            $scope.responders[id] = {
              id: id,
              active: false
            }

            return $scope.responders[id]
          }
        }],
        link: {
          pre: function ($scope, $element, $attrs) {
            var exsettings = $attrs.menutoggler || null
            if (exsettings) {
              exsettings = JSON.parse(exsettings)
              $scope.settings = angular.extend({}, $scope.settings, exsettings)
            }

            $scope.$watch('settings.hasactive', function (data) {
              appFactory.menu.active = data
            }, true)
          },

          post: function ($scope) {
            $scope.setFirstActive()
          }
        }
      }
    }]
  );

  app.directive('menutogglerTrigger',
    [function() {
      return {
        restrict: 'A',
        require: '^menutoggler',
        scope: true,
        bindToController: {
          trigger: "&"
        },
        controllerAs: 'ctrl',
        controller: ['$scope', function ($scope) {
          $scope.trigger = {}
        }],
        link: {
          pre: function ($scope, $element, $attrs) {
            var id = ($attrs.menutogglerTrigger.length > 0) ? $attrs.menutogglerTrigger : undefined
            $scope.trigger = $scope.addTrigger(id)
            
            $scope.onTrigger = function () {
              $scope.activate($scope.trigger.id)
            }

            return $scope.trigger
          }
        }
      }
    }]
  )

  app.directive('menutogglerResponder',
    [function() {
      return {
        restrict: 'A',
        require: '^menutoggler',
        scope: true,
        bindToController: {
          responder: "&"
        },
        controllerAs: 'ctrl',
        controller: ['$scope', function ($scope) {
          $scope.responder = {}
        }],
        link: {
          pre: function ($scope, $element, $attrs) {
            var id = ($attrs.menutogglerResponder.length > 0)? $attrs.menutogglerResponder : undefined;
            $scope.responder = $scope.addResponder(id);
            return $scope.responder
          }
        }
      }
    }]
  )
}

module.exports = setup
