const setup = function (app) {
    app.directive('toggler',
      ['$rootScope', 'appFactory', function ($rootScope, appFactory) {
          return {
              restrict: 'A',
              scope: true,
              bindToController: {
                  triggers: '&',
                  responders: '&',
                  settings: '&'
              },
              controllerAs: 'togglerctrl',
              controller: ['$scope', function ($scope) {
                  $scope.triggers = {};
                  $scope.responders = {};

                  $scope.settings = {
                      usemodal: false,
                      initialactive: false,
                      hasactive: false,
                      dropdownactive: false,
                      title: 'Select an option...'
                  }

                  $scope.setFirstActive = function () {
                      if ($scope.settings.initialactive) {
                          $scope.activate($scope.settings.initialactive)
                      }
                  }

                  $scope.activate = function (which) {
                      var hasactive = false

                      angular.forEach($scope.triggers, function (item, key) {
                          item.active = (item.active) ? false : (item.id === which)
                          if (item.active) {
                              if (item.title.length > 0) $scope.settings.title = item.title
                              hasactive = true
                              $scope.settings.dropdownactive = false
                          }
                      })

                      angular.forEach($scope.responders, function (item, key) {
                          item.active = (item.active) ? false : (item.id === which)
                      })

                      $scope.settings.hasactive = hasactive

                      if (!hasactive) {
                          $scope.settings.title = 'Pick a category...'
                      }

                      if (!this.settings.usemodal) return

                      if (hasactive) {
                          appFactory.activateOverlay($scope)
                      } else {
                          $scope.settings.title = 'Select an option...'
                          appFactory.deactivateOverlay()
                      }
                  }

                  $scope.deactivateAll = function () {

                      angular.forEach($scope.triggers, function (item, key) {
                          item.active = false;
                      });

                      angular.forEach($scope.responders, function (item, key) {
                          item.active = false;
                      });

                      $scope.settings.hasactive = false;

                      $scope.settings.title = 'Select an option...'

                      if (!this.settings.usemodal) return;

                      appFactory.deactivateOverlay()
                  };

                  $scope.addTrigger = function (id, title) {
                      id = (id !== undefined) ? id : Object.keys($scope.triggers).length + 1
                      title = (id !== undefined) ? title : ''
                      $scope.triggers[id] = {
                          id: id,
                          title: title,
                          active: false
                      }
                      return $scope.triggers[id]
                  }

                  $scope.addResponder = function (id) {
                      id = (id !== undefined) ? id : Object.keys($scope.responders).length + 1;

                      $scope.responders[id] = {
                          id: id,
                          active: false
                      }

                      return $scope.responders[id]
                  }

                  $scope.toggleDropdown = function () {
                      $scope.settings.dropdownactive = !$scope.settings.dropdownactive
                  }
              }],
              link: {
                  pre: function ($scope, $element, $attrs) {

                      var exsettings = $attrs.toggler || null
                      if (exsettings) {
                          exsettings = JSON.parse(exsettings)
                          $scope.settings = angular.extend({}, $scope.settings, exsettings)
                      }
                  },

                  post: function ($scope) {
                      $scope.setFirstActive()
                  }
              }
          }
      }]
    );

    app.directive('togglerTrigger',
      ['$window', function ($window) {
          return {
              restrict: 'A',
              require: '^toggler',
              scope: true,
              bindToController: {
                  trigger: "&"
              },
              controllerAs: 'ctrl',
              controller: ['$scope', function ($scope) {
                  $scope.trigger = {}
              }],
              link: {
                  pre: function ($scope, $element, $attrs) {
                      var id = ($attrs.togglerTrigger.length > 0) ? $attrs.togglerTrigger : undefined
                      var title = ($attrs.togglerTitle !== undefined) ? $attrs.togglerTitle : ''
                      $scope.trigger = $scope.addTrigger(id, title)

                      $scope.onTrigger = function () {
                          $scope.activate($scope.trigger.id)
                      }

                      return $scope.trigger
                  },
                  post: function ($scope, $element, $attrs) {
                      if ($attrs.togglerTrigger !== '') {
                          angular.element($window).on('click touchstart', function (event) {
                              try {
                                  if (!($(event.target).data('toggler-trigger') === $scope.trigger.id
                                      || $(event.target).closest('*[data-toggler-trigger]').data('toggler-trigger') === $scope.trigger.id
                                      || $(event.target).closest('*[data-toggler-responder]').data('toggler-responder') === $scope.trigger.id)) {
                                      if ($scope.trigger.active) {
                                          $scope.activate($scope.trigger.id);
                                          $scope.$apply();
                                      }
                                  }
                              }
                              catch (e) {
                              }
                          });
                      }
                  }
              }
          }
      }]
    )

    app.directive('togglerResponder',
      [function () {
          return {
              restrict: 'A',
              require: '^toggler',
              scope: true,
              bindToController: {
                  responder: "&"
              },
              controllerAs: 'ctrl',
              controller: ['$scope', function ($scope) {
                  $scope.responder = {}
              }],
              link: {
                  pre: function ($scope, $element, $attrs) {
                      var id = ($attrs.togglerResponder.length > 0) ? $attrs.togglerResponder : undefined;
                      $scope.responder = $scope.addResponder(id);
                      return $scope.responder
                  }
              }
          }
      }]
    )

    app.directive('togglerTitle',
      [function () {
          return {
              restrict: 'A',
              require: '^toggler',
              scope: true,
              bindToController: {
              },
              controllerAs: 'ctrl',
              controller: ['$scope', function ($scope) { }],
              link: function ($scope, $element, $attrs) {
                  $scope.onTitleTrigger = function () {
                      $scope.toggleDropdown()
                  }
              }
          }
      }]
    )
}
module.exports = setup
