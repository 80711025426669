﻿module.exports = function (app) {
  app.controller('insightsLanding', ['$window', '$scope', '$http', '$element', '$timeout', '$sce', 'helperService', 'queryStringService', 'searchService', 'scrollToService', function ($window, $scope, $http, $element, $timeout, $sce, helperService, queryStringService, searchService, scrollToService) {
    $scope.isLoading = false;
    $scope.filters = {
      toggleFilters: [],
      selectFilters: [],
      categoryFilter: [],
      activeFilters: []
    };

    $scope.results = {};

    $scope.totalResultCount = 0;
    $scope.currentResultCount = 0;
    $scope.enableInfiniteScroll = false;

    $scope.keyword = '';

    $scope.hasSearchRun = false;
    $scope.hasSearchStarted = false;

    $scope.serverActiveFilters = [];

    $scope.subserviceFilters = [{ "Text": "Select", "Value": "" }];
    $scope.selectedService = { "SelectedToggle": $scope.subserviceFilters[0] };
    $scope.subindustryFilters = [{ "Text": "Select", "Value": "" }];
    $scope.selectedIndustry = { "SelectedToggle": $scope.subindustryFilters[0] };

    /* PRIVATE VARIABLES */

    var language = 'en';
    var sanitizedFields = [];
    var qsSeparator = '|';
    var firstSearch = true;
    var searchType = 'all';
    var scrollPosition = 0;
    var allEndpoint = '';
    var insightsEndpoint = '';
    var pastEventsEndpoint = '';
    var upcomingEventsEndpoint = '';
    var sortBy = 2;
    var sortOrder = 1;
    var preventAutoScroll = false;
    var pageloadSearch = false;
    var resultsElement;
    var previousKeyword = '';
    var isExperienceEditor = false;
    var isViewMoreSearch = false;

    var parameterNames = {
      category: 'category',
      language: 'language',
      pageSize: 'pageSize',
      keyword: 'keyword',
      noSkip: 'noSkip',
      sortBy: 'sortBy',
      sortOrder: 'sortOrder',
      related: 'related',
      date: 'date',
      scroll: 'scroll',
      pageNum: 'pageNum',
      insightPageNum: 'insightPageNum',
      upcomingEvents_EventsAndWebinars_PageNum: 'ueewPageNum',
      upcomingEvents_UpcomingSpeakingEngagements_PageNum: 'ueusePageNum',
      pastEvents_EventRecaps_PageNum: 'peerPageNum',
      pastEvents_WebinarRecordings_PageNum: 'pewrPageNum',
      pastEvents_PastSpeakingEngagements_PageNum: 'pepsePageNum',
      eventTypes: 'eventTypes'
    };

    var pageSize = 6;
    var insightPageSize = 6;
    var eventPageSize = 3;

    /* INIT */

    var init = function () {
      //look for initial state json for filters and results

      if ($element.attr('data-page-size')) {
        pageSize = helperService.tryParseInt($element.attr('data-page-size'), pageSize);
      }
      if ($element.attr('data-insight-page-size')) {
        insightPageSize = helperService.tryParseInt($element.attr('data-insight-page-size'), insightPageSize);
      }
      if ($element.attr('data-event-page-size')) {
        eventPageSize = helperService.tryParseInt($element.attr('data-event-page-size'), eventPageSize);
      }

      if ($element.attr('data-language')) {
        language = $element.attr('data-language');
      }
      if ($element.attr('data-search-all-endpoint')) {
        allEndpoint = $element.attr('data-search-all-endpoint');
      }
      if ($element.attr('data-search-insights-endpoint')) {
        insightsEndpoint = $element.attr('data-search-insights-endpoint');
      }
      if ($element.attr('data-search-past-events-endpoint')) {
        pastEventsEndpoint = $element.attr('data-search-past-events-endpoint');
      }
      if ($element.attr('data-search-upcoming-events-endpoint')) {
        upcomingEventsEndpoint = $element.attr('data-search-upcoming-events-endpoint');
      }
      if ($element.attr('data-sanitized-fields')) {
        sanitizedFields = $element.attr('data-sanitized-fields').split(',');
      }
      if ($element.attr('data-is-experience-editor')) {
        isExperienceEditor = true;
      }
      if (document.getElementById('json-select-filters')) {
        $scope.filters.selectFilters = JSON.parse(document.getElementById('json-select-filters').text);
      }
      if (document.getElementById('json-active-filters')) {
        $scope.serverActiveFilters = JSON.parse(document.getElementById('json-active-filters').text);
      }
      if (document.getElementById('json-category-filter')) {
        $scope.filters.categoryFilter = JSON.parse(document.getElementById('json-category-filter').text);
      }
      if (document.getElementById('scrollToTarget')) {
        resultsElement = document.getElementById('scrollToTarget');
      }

      if ($scope.filters.selectFilters && $scope.filters.selectFilters.length > 0) {
        $scope.select = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced === false; });
        $scope.advanced = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced === true; });
      }

      resetResults();

      if (queryStringService.hasQueryString()) {
        setFilters();
      }
      pageloadSearch = $scope.filters.activeFilters.length > 0;
      performSearch();
    }

    /* EVENT HANDLERS */

    $scope.onSubmit = function ($event) {
      //ensure keyword query is in active
      if ($scope.keyword) {
        $scope.filters.activeFilters = [];
        resetSelectedFilters();
        addActiveKeywordFilter($scope.keyword);
      }
      resetPageNums();

      performSearch();
    }

    $scope.onFilterInit = function ($event, filter) {
      //    if (!filter.SelectedOption)
      //        filter.SelectedOption = filter.Options[0];
    }

    $scope.showFilter = function (filter) {
      return !filter.Category || !$scope.filters.categoryFilter || filter.Category.includes($scope.filters.categoryFilter.SelectedOption.Value);
    }

    $scope.onCategoryClick = function ($event, filter, option) {
      if (!(filter && option)) return;

      filter.SelectedOption = option;

      removeActiveFilterByGroup('category');

      if (filter.SelectedOption.Value !== 'latest') {
        addActiveFilter('category', filter.SelectedOption.Text, filter.SelectedOption.Value);
      }
      var actives = [];
      angular.forEach($scope.filters.activeFilters, function (active) {
        var ok = false;

        var select = $scope.filters.selectFilters.filter(function (x) { return x.Name === active.Group });
        if (select && select.length > 0) {
          if (select[0].Category && select[0].Category !== '') {
            if (select[0].Category.includes($scope.filters.categoryFilter.SelectedOption.Value)) {
              ok = true;
            }
          }
          else {
            ok = true;
          }
        }

        if (ok || active.Group === 'category' || active.Group === 'keyword' || active.Group === 'date') {
          actives.push(active);
        }
        else {
          if (select && select.length > 0) {
            if ($scope.filters.toggleFilters.Name === active.Group) {
              $scope.filters.toggleFilters = {};
            }
            if (select[0].Options && select[0].Options.length > 0) {
              select[0].SelectedOption = select[0].Options[0];
            }
            else {
              select[0].SelectedOption = {};
            }
          }
        }
      });
      $scope.filters.activeFilters = actives;
      resetPageNums();
      firstSearch = true;
      window.setTimeout(function () {
        $scope.$broadcast("read-more-toggled", {});
      }, 1)

      performSearch();
    }

    $scope.onFilterChange = function ($event, filter) {
      if (!(filter && filter.SelectedOption)) return;

      removeActiveFilterByGroup(filter.Name);

      if (filter.Name === 'services') {
        removeActiveToggleFiltersByGroup(filter.Name);
        removeActiveFilterByGroup('subservices');
      } else if (filter.Name === 'industries') {
        removeActiveToggleFiltersByGroup(filter.Name);
        removeActiveFilterByGroup('subindustries');
      }

      if (filter.SelectedOption.Value !== '') {
        addActiveSelectFilter(filter);

        filter.Options.forEach(function (option) {
          if (option.Toggles && option.Toggles.length > 0) {
            option.SelectedToggle = {};
          }
        });

        if (filter.SelectedOption.Toggles && filter.SelectedOption.Toggles.length > 0) {
          addActiveToggleFilter(filter);
        }
      }

      firstSearch = true;
      resetPageNums();
      performSearch();
    }

    $scope.onFilterToggleSelect = function ($event, name, option, toggle) {
      if (!toggle || !toggle.IsEnabled) return;

      option.SelectedToggle = toggle;

      removeActiveFilterByGroup(name);
      addActiveFilter(name, toggle.Text, toggle.Value);

      resetPageNums();

      performSearch();
    }

    $scope.onSubFilterChange = function ($event, name, toggle) {
      removeActiveFilterByGroup('sub' + name);

      if (name === 'services' && $scope.selectedService.SelectedToggle.Value !== '') {
        addActiveFilter('sub' + name, $scope.selectedService.SelectedToggle.Text, $scope.selectedService.SelectedToggle.Value);
      }
      else if (name === 'industries' && $scope.selectedIndustry.SelectedToggle.Value !== '') {
        addActiveFilter('sub' + name, $scope.selectedIndustry.SelectedToggle.Text, $scope.selectedIndustry.SelectedToggle.Value);
      }

      performSearch();
    }

    $scope.onRemoveFilter = function (event, active) {
      removeActiveFilterByValue(active.Value);

      if (active.Group === 'subservices') {
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0]
      }

      if (active.Group === 'subindustries') {
        $scope.selectedIndustry.SelectedToggle = $scope.subindustryFilters[0]
      }

      if (active.Group === 'keyword') {
        previousKeyword = '';
      }

      angular.forEach($scope.filters.selectFilters, function (filter) {
        if (filter.Name === active.Group) {
          removeActiveToggleFiltersByGroup(active.Group);
          if (filter.Options && filter.Options.length > 0) {
            filter.SelectedOption = filter.Options[0];
          }
          else {
            filter.SelectedOption = {};
          }
          if (filter.ExcludeIds)
            filter.ExcludeIds = null;
          if (filter.Keyword)
            filter.Keyword = '';
        }
      });

      if (active.Group === 'category') {
        $scope.filters.categoryFilter.SelectedOption = $scope.filters.categoryFilter.Options[0];
      }

      resetPageNums();

      if ($scope.filters.activeFilters.length === 0) {
        $scope.filters.activeFilters = [];
        $scope.filters.categoryFilter.SelectedOption = $scope.filters.categoryFilter.Options[0];
        $scope.hasSearchRun = false;
        firstSearch = true;
        performSearch();
      }
      else {
        performSearch();
      }
    }

    $scope.onRemoveFilterAll = function (event) {
      resetSearch();
    }

    $scope.onResultLink = function ($event, url) {
      if ($event.ctrlKey || $event.metaKey)
        return;
      var qs = buildQueryString($window.pageYOffset);
      updateHistory(qs, true);
      window.location = url;
    }

    $scope.onInsightsViewMore = function ($event) {
      if ($scope.isLoading) return;

      isViewMoreSearch = true;
      $scope.results.Insights.PageNum++;
      partialInsightSearch();
    }

    $scope.onEventsViewMore = function ($event, isUpcoming, category) {
      if (!category) return;
      if ($scope.isLoading) return;

      isViewMoreSearch = true;
      category.PageNum++;
      partialEventSearch(isUpcoming, category);
    }

    $scope.onInsightsSeeAll = function ($event) {
      var insightsOptions = $scope.filters.categoryFilter.Options.filter(function (x) { return x.Value === 'insights' });
      if (insightsOptions && insightsOptions.length > 0) {
        $scope.filters.categoryFilter.SelectedOption = insightsOptions[0];
        removeActiveFilterByGroup('category');
        addActiveFilter('category', 'Insights', 'insights');
        firstSearch = true;
        resetPageNums();
        performSearch();
      }
    }

    $scope.onEventsSeeAll = function ($event) {
      var eventOptions = $scope.filters.categoryFilter.Options.filter(function (x) { return x.Value === 'events' });
      if (eventOptions && eventOptions.length > 0) {
        $scope.filters.categoryFilter.SelectedOption = eventOptions[0];
        removeActiveFilterByGroup('category');
        addActiveFilter('category', 'Events', 'events');
        firstSearch = true;
        resetPageNums();
        performSearch();
      }
    }

    var setFilters = function () {
      if ($scope.filters.selectFilters) {
        angular.forEach($scope.filters.selectFilters, function (filter) {
          if (filter.Options && filter.Options.length > 0) {
            filter.SelectedOption = filter.Options[0];
          }
        });
      }

      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.keyword))) {
        var keyword = queryStringService.getParameterByName(parameterNames.keyword);
        if (keyword) {
          addKeywordFilter(keyword);
        }
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.category))) {
        var category = queryStringService.getParameterByName(parameterNames.category)
        setCategoryFilter(category);

        if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.pageSize))) {
          var pageSize = helperService.tryParseInt(queryStringService.getParameterByName(parameterNames.pageSize), 0);

          switch (category) {
            case 'latest':
              pageSize = pageSize;
              break;
            case 'insights':
              insightPageSize = pageSize;
              break;
            case 'events':
              eventPageSize = pageSize;
              break;
            default:
              pageSize = pageSize;
              break;
          }
        }
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.insightPageNum))) {
        $scope.results.Insights.PageNum = helperService.tryParseInt(queryStringService.getParameterByName(parameterNames.insightPageNum, 1));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.upcomingEvents_EventsAndWebinars_PageNum))) {
        $scope.results.UpcomingEvents.EventsAndWebinars.PageNum = helperService.tryParseInt(queryStringService.getParameterByName(parameterNames.upcomingEvents_EventsAndWebinars_PageNum, 1));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.upcomingEvents_UpcomingSpeakingEngagements_PageNum))) {
        $scope.results.UpcomingEvents.UpcomingSpeakingEngagements.PageNum = helperService.tryParseInt(queryStringService.getParameterByName(parameterNames.upcomingEvents_UpcomingSpeakingEngagements_PageNum, 1));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.pastEvents_EventRecaps_PageNum))) {
        $scope.results.PastEvents.EventRecaps.PageNum = helperService.tryParseInt(queryStringService.getParameterByName(parameterNames.pastEvents_EventRecaps_PageNum, 1));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.pastEvents_PastSpeakingEngagements_PageNum))) {
        $scope.results.PastEvents.PastSpeakingEngagements.PageNum = helperService.tryParseInt(queryStringService.getParameterByName(parameterNames.pastEvents_PastSpeakingEngagements_PageNum, 1));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.pastEvents_WebinarRecordings_PageNum))) {
        $scope.results.PastEvents.WebinarRecordings.PageNum = helperService.tryParseInt(queryStringService.getParameterByName(parameterNames.pastEvents_WebinarRecordings_PageNum, 1));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.related))) {
        setSelectFilters(queryStringService.getParameterByName(parameterNames.related));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.date))) {
        setSelectFilters(queryStringService.getParameterByName(parameterNames.date));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.sortBy))) {
        sortBy = helperService.tryParseInt(queryStringService.getParameterByName(parameterNames.sortBy), 0);
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.sortOrder))) {
        sortOrder = helperService.tryParseInt(queryStringService.getParameterByName(parameterNames.sortOrder), 0);
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.language))) {
        language = queryStringService.getParameterByName(parameterNames.language);
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName(parameterNames.scroll))) {
        scrollPosition = queryStringService.getParameterByName(parameterNames.scroll);
      }
    }

    var setCategoryFilter = function (category) {
      if (!(category === 'latest' || category === 'insights' || category === 'events')) category = 'latest';

      if ($scope.filters.categoryFilter && $scope.filters.categoryFilter.Options.length > 0) {
        angular.forEach($scope.filters.categoryFilter.Options, function (option) {
          if (option.Value === category) {
            $scope.filters.categoryFilter.SelectedOption = option;
          }
        });
      }
      if ($scope.filters.categoryFilter.SelectedOption.Value !== 'latest') {
        addActiveFilter('category', $scope.filters.categoryFilter.SelectedOption.Text, $scope.filters.categoryFilter.SelectedOption.Value);
      }
    }

    var addKeywordFilter = function (keyword) {
      $scope.keyword = keyword;
      addActiveKeywordFilter(keyword);
    }

    var setSelectFilters = function (relateds) {
      var aRelated = [];
      aRelated = relateds.split('|');
      angular.forEach(aRelated, function (relatedId) {
        var found = false;
        angular.forEach($scope.filters.selectFilters, function (filter) {
          if (filter.Options && filter.Options.length > 0 && $scope.showFilter(filter)) {
            angular.forEach(filter.Options, function (option) {
              if (option.Value === relatedId) {
                found = true;
                filter.SelectedOption = option;
                if (option.Toggles && option.Toggles.length > 0) {
                  addActiveToggleFilter(filter);
                }
                addActiveSelectFilter(filter);
              }
              else if (option.Toggles && option.Toggles.length > 0) {
                angular.forEach(option.Toggles, function (toggle) {
                  if (toggle.Value === relatedId) {
                    found = true;
                    option.SelectedToggle = toggle;
                    filter.SelectedOption = option;
                    addActiveFilter('sub' + filter.Name, toggle.Text, toggle.Value);
                    addActiveToggleFilter(filter);

                    if (filter.Name === 'services') {
                      $scope.selectedService.SelectedToggle = toggle;
                    } else if (filter.Name === 'industries') {
                      $scope.selectedIndustry.SelectedToggle = toggle;
                    }
                  }
                });
              }
            });
          }
        });
        if (!found && $scope.serverActiveFilters && $scope.serverActiveFilters.length > 0) {
          //try checking the active filters json from server for typeaheads
          angular.forEach($scope.serverActiveFilters, function (server) {
            if (server.Value === relatedId) {
              addActiveFilter('?', server.Text, server.Value);
            }
          });
        }
      });
    }

    var addActiveKeywordFilter = function (keyword) {
      keyword = searchService.getKeywordDisplay(keyword);
      addActiveFilter('keyword', keyword, keyword);
    }

    var addActiveSelectFilter = function (filter) {
      if (filter && filter.SelectedOption) {
        addActiveFilter(filter.Name, filter.SelectedOption.Text, filter.SelectedOption.Value);
      }
    }

    var addActiveFilter = function (group, text, value) {
      $scope.filters.activeFilters.push({ Group: group, Text: text, Value: value });
    }

    var addActiveToggleFilter = function (filter) {
      $scope.filters.toggleFilters.push({ Name: filter.Name, Option: filter.SelectedOption });

      if (filter.Name === "services") {
        $scope.selectedService = filter.SelectedOption;
        //clear out existig drop-down
        $scope.subserviceFilters = [$scope.subserviceFilters[0]]
        //add new items
        $scope.subserviceFilters = $scope.subserviceFilters.concat(filter.SelectedOption.Toggles);
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0];
      }
      if (filter.Name === "industries") {
        $scope.selectedIndustry = filter.SelectedOption;
        //clear out existig drop-down
        $scope.subindustryFilters = [$scope.subindustryFilters[0]]
        //add new items
        $scope.subindustryFilters = $scope.subindustryFilters.concat(filter.SelectedOption.Toggles);
        $scope.selectedIndustry.SelectedToggle = $scope.subserviceFilters[0];
      }
    }

    var performSearch = function () {
      if (isExperienceEditor) {
        resetActiveFilters();
        return;
      }

      if (!$scope.filters.categoryFilter.SelectedOption) {
        if ($scope.filters.categoryFilter.Options && $scope.filters.categoryFilter.Options.length > 0) {
          $scope.filters.categoryFilter.SelectedOption = $scope.filters.categoryFilter.Options[0];
        }
      }

      allSearch();
    }

    var allSearch = function () {
      if (!($scope.filters.activeFilters.length > 0 || $scope.keyword !== '' || $scope.filters.categoryFilter.SelectedOption.Value !== 'latest')) {
        resetSearch();
        return;
      }

      const isAlreadyInFilterSet = !!$scope.filters.activeFilters.filter(x => { return x.Text.includes($scope.keyword) }).length;

      //if they fill out text input field and click an advanced filter
      if ($scope.filters.activeFilters.filter(function (x) { return x.Group === "keyword" }) !== undefined && $scope.keyword !== '' && !isAlreadyInFilterSet) {
        const keyword = searchService.getKeywordDisplay($scope.keyword);
        addKeywordFilter(keyword);
      }

      $scope.hasSearchStarted = true;
      $scope.isLoading = true;

      var qs = buildQueryString();
      updateHistory(qs, isViewMoreSearch);

      searchService.search(allEndpoint, qs, handleAllSearchSuccess, handleSearchError);
    }

    var handleAllSearchSuccess = function (response) {
      angular.forEach(response.data.Insights.Results, function (result) {
        angular.forEach(sanitizedFields, function (name) {
          result[name] = $sce.trustAsHtml(result[name]);
        });
      });

      angular.forEach(response.data.UpcomingEvents.EventsAndWebinars.Results, function (result) {
        angular.forEach(sanitizedFields, function (name) {
          result[name] = $sce.trustAsHtml(result[name]);
        });
      });

      angular.forEach(response.data.UpcomingEvents.UpcomingSpeakingEngagements.Results, function (result) {
        angular.forEach(sanitizedFields, function (name) {
          result[name] = $sce.trustAsHtml(result[name]);
        });
      });

      angular.forEach(response.data.PastEvents.EventRecaps.Results, function (result) {
        angular.forEach(sanitizedFields, function (name) {
          result[name] = $sce.trustAsHtml(result[name]);
        });
      });

      angular.forEach(response.data.PastEvents.WebinarRecordings.Results, function (result) {
        angular.forEach(sanitizedFields, function (name) {
          result[name] = $sce.trustAsHtml(result[name]);
        });
      });

      angular.forEach(response.data.PastEvents.PastSpeakingEngagements.Results, function (result) {
        angular.forEach(sanitizedFields, function (name) {
          result[name] = $sce.trustAsHtml(result[name]);
        });
      });

      $scope.results = response.data;
      recalculateTotalResultCount();

      if (scrollPosition > 0) {
        $timeout(function () {
          window.scroll(0, scrollPosition);
        });
      }
      else if ($scope.filters.activeFilters.length === 1 && !!resultsElement && !pageloadSearch && !isViewMoreSearch) {
        scrollToService.scrollToElement(resultsElement);
      }

      if ($scope.keyword !== '' && $scope.keyword !== previousKeyword) {
        previousKeyword = $scope.keyword;
      }

      firstSearch = false;
      pageloadSearch = false;
      isViewMoreSearch = false;
      $scope.hasSearchRun = true;
      $scope.isLoading = false;
      $scope.keyword = '';
    }

    var partialInsightSearch = function () {
      $scope.hasSearchStarted = true;
      $scope.isLoading = true;

      var qs = buildPartialQueryString($scope.results.Insights.PageNum);
      updateHistory(buildQueryString(), isViewMoreSearch);

      searchService.search(insightsEndpoint, qs, handlePartialInsightSearchSuccess, handleSearchError);
    }

    var handlePartialInsightSearchSuccess = function (response) {
      angular.forEach(response.data.Results, function (result) {
        angular.forEach(sanitizedFields, function (name) {
          result[name] = $sce.trustAsHtml(result[name]);
        });
      });

      if ($scope.results.Insights.PageNum > 1 && !firstSearch) {
        $scope.results.Insights.Results = $scope.results.Insights.Results.concat(response.data.Results);
        $scope.results.Insights.TotalResultCount = response.data.TotalResultCount;
      }
      else {
        $scope.results.Insights = response.data;
      }
      recalculateTotalResultCount();

      $scope.hasSearchRun = true;
      $scope.isLoading = false;
      firstSearch = false;
      $scope.keyword = '';
    }

    var partialEventSearch = function (isUpcoming, category) {
      $scope.hasSearchStarted = true;
      $scope.isLoading = true;

      var qs = buildPartialQueryString(category.PageNum, category.Ids);
      updateHistory(buildQueryString(), isViewMoreSearch);

      var eventEndpoint = isUpcoming ? upcomingEventsEndpoint : pastEventsEndpoint;
      if (!eventEndpoint || eventEndpoint === '' || typeof eventEndpoint === 'undefined') handleSearchError();

      var handlePartialEventSearchSuccess = function (response) {
        angular.forEach(response.data.Results, function (result) {
          angular.forEach(sanitizedFields, function (name) {
            result[name] = $sce.trustAsHtml(result[name]);
          });
        });

        if (category.PageNum > 1 && !firstSearch) {
          category.Results = category.Results.concat(response.data.Results);
        }
        else {
          category.Results = response.data.Results;
        }
        category.TotalResultCount = response.data.TotalResultCount;
        recalculateTotalResultCount();

        $scope.hasSearchRun = true;
        $scope.isLoading = false;
        firstSearch = false;
        $scope.keyword = '';
      }

      searchService.search(eventEndpoint, qs, handlePartialEventSearchSuccess, handleSearchError);
    }

    var handleSearchError = function (error) {
      pageloadSearch = false;
      $scope.isLoading = false;
      $scope.enableInfiniteScroll = false;
      $scope.keyword = '';
    }

    var recalculateTotalResultCount = function () {
      switch ($scope.filters.categoryFilter.SelectedOption.Value) {
        case 'latest':
          $scope.totalResultCount = $scope.results.Insights.TotalResultCount +
            $scope.results.UpcomingEvents.EventsAndWebinars.TotalResultCount +
            $scope.results.UpcomingEvents.UpcomingSpeakingEngagements.TotalResultCount +
            $scope.results.PastEvents.EventRecaps.TotalResultCount +
            $scope.results.PastEvents.WebinarRecordings.TotalResultCount +
            $scope.results.PastEvents.PastSpeakingEngagements.TotalResultCount;
          break;
        case 'insights':
          $scope.totalResultCount = $scope.results.Insights.TotalResultCount;
          break;
        case 'events':
          $scope.totalResultCount = $scope.results.UpcomingEvents.EventsAndWebinars.TotalResultCount +
            $scope.results.UpcomingEvents.UpcomingSpeakingEngagements.TotalResultCount +
            $scope.results.PastEvents.EventRecaps.TotalResultCount +
            $scope.results.PastEvents.WebinarRecordings.TotalResultCount +
            $scope.results.PastEvents.PastSpeakingEngagements.TotalResultCount;
          break;
        default:
          break;
      }
    }

    var buildQueryString = function (scroll) {
      var category = '';
      if ($scope.filters.categoryFilter) {
        category = $scope.filters.categoryFilter.SelectedOption.Value;
      }

      var date = '';
      var related = '';
      angular.forEach($scope.filters.activeFilters, function (active) {
        if (active.Group !== 'keyword' && active.Group !== 'category' && active.Group !== 'date') {
          related += (related !== '' ? '|' : '') + active.Value;
        }
        if (active.Group == 'date') {
          date = active.Value;
        }
      });

      var qs = '';
      if (category !== '') {
        qs = queryStringService.addQueryString(parameterNames.category, category, qs);

        switch (category) {
          case 'latest':
            qs = queryStringService.addQueryString(parameterNames.pageSize, pageSize, qs);
            break;
          case 'insights':
            qs = queryStringService.addQueryString(parameterNames.pageSize, insightPageSize, qs);
            break;
          case 'events':
            qs = queryStringService.addQueryString(parameterNames.pageSize, eventPageSize, qs);
            break;
          default:
            qs = queryStringService.addQueryString(parameterNames.pageSize, pageSize, qs);
            break;
        }
      }

      if ($scope.results.Insights.PageNum && $scope.results.Insights.PageNum > 1)
        qs = queryStringService.addQueryString(parameterNames.insightPageNum, $scope.results.Insights.PageNum, qs);
      if ($scope.results.UpcomingEvents.EventsAndWebinars.PageNum && $scope.results.UpcomingEvents.EventsAndWebinars.PageNum > 1)
        qs = queryStringService.addQueryString(parameterNames.upcomingEvents_EventsAndWebinars_PageNum, $scope.results.UpcomingEvents.EventsAndWebinars.PageNum, qs);
      if ($scope.results.UpcomingEvents.UpcomingSpeakingEngagements.PageNum && $scope.results.UpcomingEvents.UpcomingSpeakingEngagements.PageNum > 1)
        qs = queryStringService.addQueryString(parameterNames.upcomingEvents_UpcomingSpeakingEngagements_PageNum, $scope.results.UpcomingEvents.UpcomingSpeakingEngagements.PageNum, qs);
      if ($scope.results.PastEvents.EventRecaps.PageNum && $scope.results.PastEvents.EventRecaps.PageNum > 1)
        qs = queryStringService.addQueryString(parameterNames.pastEvents_EventRecaps_PageNum, $scope.results.PastEvents.EventRecaps.PageNum, qs);
      if ($scope.results.PastEvents.WebinarRecordings.PageNum && $scope.results.PastEvents.WebinarRecordings.PageNum > 1)
        qs = queryStringService.addQueryString(parameterNames.pastEvents_WebinarRecordings_PageNum, $scope.results.PastEvents.WebinarRecordings.PageNum, qs);
      if ($scope.results.PastEvents.PastSpeakingEngagements.PageNum && $scope.results.PastEvents.PastSpeakingEngagements.PageNum > 1)
        qs = queryStringService.addQueryString(parameterNames.pastEvents_PastSpeakingEngagements_PageNum, $scope.results.PastEvents.PastSpeakingEngagements.PageNum, qs);
      if ($scope.keyword && $scope.keyword !== '') {
        qs = queryStringService.addQueryString(parameterNames.keyword, $scope.keyword, qs);
      } else if (previousKeyword && previousKeyword !== '') {
        qs = queryStringService.addQueryString(parameterNames.keyword, previousKeyword, qs);
      }
      if (date && date !== '') {
        qs = queryStringService.addQueryString(parameterNames.date, date, qs);
      }
      if (related && related !== '')
        qs = queryStringService.addQueryString(parameterNames.related, related, qs);
      if (firstSearch)
        qs = queryStringService.addQueryString(parameterNames.noSkip, true, qs);
      qs = queryStringService.addQueryString(parameterNames.sortBy, sortBy, qs);
      qs = queryStringService.addQueryString(parameterNames.sortOrder, sortOrder, qs);
      if (language !== '')
        qs = queryStringService.addQueryString(parameterNames.language, language, qs);
      if (scroll && scroll > 0)
        qs = queryStringService.addQueryString(parameterNames.scroll, scroll, qs);

      return qs;
    }

    var buildPartialQueryString = function (pageNum, categoryIds) {
      var qs = '';

      var date = '';
      var related = '';
      angular.forEach($scope.filters.activeFilters, function (active) {
        if (active.Group !== 'keyword' && active.Group !== 'category' && active.Group !== 'date') {
          related += (related !== '' ? '|' : '') + active.Value;
        }
        if (active.Group == 'date') {
          date = active.Value;
        }
      });

      if (categoryIds && categoryIds !== '') {
        qs = queryStringService.addQueryString(parameterNames.eventTypes, categoryIds, qs);
      }
      if (related && related !== '')
        qs = queryStringService.addQueryString(parameterNames.related, related, qs);
      if (date && date !== '')
        qs = queryStringService.addQueryString(parameterNames.date, date, qs);

      if (pageNum && pageNum > 1)
        qs = queryStringService.addQueryString(parameterNames.pageNum, pageNum, qs);

      var category = '';
      if ($scope.filters.categoryFilter)
        category = $scope.filters.categoryFilter.SelectedOption.Value;
      if (category !== '') {
        qs = queryStringService.addQueryString(parameterNames.category, category, qs);

        switch (category) {
          case 'latest':
            qs = queryStringService.addQueryString(parameterNames.pageSize, pageSize, qs);
            break;
          case 'insights':
            qs = queryStringService.addQueryString(parameterNames.pageSize, insightPageSize, qs);
            break;
          case 'events':
            qs = queryStringService.addQueryString(parameterNames.pageSize, eventPageSize, qs);
            break;
          default:
            qs = queryStringService.addQueryString(parameterNames.pageSize, pageSize, qs);
            break;
        }
      }

      if ($scope.keyword && $scope.keyword !== '') {
        qs = queryStringService.addQueryString(parameterNames.keyword, $scope.keyword, qs);
      } else if (previousKeyword && previousKeyword !== '') {
        qs = queryStringService.addQueryString(parameterNames.keyword, previousKeyword, qs);
      }

      qs = queryStringService.addQueryString(parameterNames.sortBy, sortBy, qs);
      qs = queryStringService.addQueryString(parameterNames.sortOrder, sortOrder, qs);
      if (language !== '')
        qs = queryStringService.addQueryString(parameterNames.language, language, qs);

      return qs;
    }

    var updateHistory = function (qs, replace) {
      var historyQs = qs;
      historyQs = queryStringService.removeQueryString(parameterNames.noSkip, true, historyQs);
      historyQs = queryStringService.removeQueryString(parameterNames.pageSize, pageSize, historyQs);
      historyQs = queryStringService.removeQueryString(parameterNames.sortBy, sortBy, historyQs);
      historyQs = queryStringService.removeQueryString(parameterNames.sortOrder, sortOrder, historyQs);
      historyQs = queryStringService.removeQueryString(parameterNames.language, language, historyQs);
      if (pageloadSearch || replace) {
        history.replaceState(null, document.title, historyQs.replace('?&', '?'));
      }
      else {
        history.pushState(null, document.title, historyQs.replace('?&', '?'));
      }
    }

    var removeActiveFilterByGroup = function (group) {
      $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Group !== group; });
    }

    var removeActiveFilterByValue = function (value) {
      $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Value !== value });
    }

    var removeActiveToggleFiltersByGroup = function (group) {
      if (group === "services") {
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0];
        $scope.subserviceFilters = [$scope.subserviceFilters[0]];
      }
      else if (group === "industries") {
        $scope.selectedIndustry.SelectedToggle = $scope.subindustryFilters[0];
        $scope.subindustryFilters = [$scope.subindustryFilters[0]];
      }

      $scope.filters.toggleFilters = $scope.filters.toggleFilters.filter(function (active) { return active.Name !== group; });
    }

    var resetSearch = function () {
      resetActiveFilters();
      resetSelectedFilters();
      resetResults();
      $scope.keyword = '';
      previousKeyword = '';
      $scope.hasSearchRun = false;

      $scope.subserviceFilters = [$scope.subserviceFilters[0]]
      $scope.subindustryFilters = [$scope.subindustryFilters[0]]

      var qs = buildQueryString();
      updateHistory(qs);
    }

    var resetActiveFilters = function () {
      $scope.filters.categoryFilter.SelectedOption = $scope.filters.categoryFilter.Options[0];
      $scope.filters.activeFilters = [];
      if ($scope.filters.categoryFilter.SelectedOption && $scope.filters.categoryFilter.SelectedOption.Value !== 'latest') {
        addActiveFilter('category', $scope.filters.categoryFilter.SelectedOption.Text, $scope.filters.categoryFilter.SelectedOption.Value);
      }
    }

    var resetPageNums = function () {
      $scope.results.Insights.PageNum = 1;
      $scope.results.UpcomingEvents.EventsAndWebinars.PageNum = 1;
      $scope.results.UpcomingEvents.UpcomingSpeakingEngagements.PageNum = 1;
      $scope.results.PastEvents.EventRecaps.PageNum = 1;
      $scope.results.PastEvents.WebinarRecordings.PageNum = 1;
      $scope.results.PastEvents.PastSpeakingEngagements.PageNum = 1;
    }

    var resetSelectedFilters = function () {
      if ($scope.filters && $scope.filters.selectFilters && $scope.filters.selectFilters.length > 0) {
        angular.forEach($scope.filters.selectFilters, function (filter) {
          filter.SelectedOption = {};
          if (filter.Options && filter.Options.length > 0) {
            filter.Options.forEach(function (option) {
              option.SelectedToggle = {};
            });
            filter.SelectedOption = filter.Options[0];
          }
          if (filter.ExcludeIds)
            filter.ExcludeIds = null;
          if (filter.Keyword)
            filter.Keyword = '';
        });
        $scope.filters.toggleFilters = [];
      }
    }

    var resetResults = function () {
      $scope.results = {
        TotalResultCount: 0,
        Insights: {
          Ids: null,
          PageNum: 1,
          Results: [],
          Title: '',
          TotalResultCount: 0
        },
        UpcomingEvents: {
          EventsAndWebinars: {
            Ids: null,
            PageNum: 1,
            Results: [],
            Title: '',
            TotalResultCount: 0
          },
          UpcomingSpeakingEngagements: {
            Ids: null,
            PageNum: 1,
            Results: [],
            Title: '',
            TotalResultCount: 0
          }
        },
        PastEvents: {
          EventRecaps: {
            Ids: null,
            PageNum: 1,
            Results: [],
            Title: '',
            TotalResultCount: 0
          },
          WebinarRecordings: {
            Ids: null,
            PageNum: 1,
            Results: [],
            Title: '',
            TotalResultCount: 0
          },
          PastSpeakingEngagements: {
            Ids: null,
            PageNum: 1,
            Results: [],
            Title: '',
            TotalResultCount: 0
          }
        }
      }
    }

    init();
  }])
}