﻿module.exports = function (app) {
    app.controller('privacyPolicy', ['$scope', '$rootScope', function ($scope, $rootScope) {

        var ev_cookienotification_open = 'ev_cookienotification_open';

        function init() {

        }

        $scope.openCookieNotification = function () {
            $rootScope.$broadcast(ev_cookienotification_open, null);
        };

        init();
    }]);
}