﻿module.exports = function (app) {
    app.controller("keywordSearch", ["$scope", "$window", "$element", "queryStringService", function($scope, $window, $element, queryStringService) {
    
        $scope.keyword = "";
        $scope.category = "";
        $scope.firstname = "";
        $scope.lastname = "";
        var searchEndpoint = "";
        var profEndpoint = "";

        $scope.onSubmit = function (tab) {
            if ($scope.keyword == "" && $scope.firstname == "" && $scope.lastname == "")
                return;

            if($(document.activeElement).attr('tabindex') === "1") {
                $scope.keyword = "";
            } else {
                $scope.firstname = "";
                $scope.lastname = "";
            }

            var qs = buildQueryString();
            if (qs == "")
                return;

            var endpoint = searchEndpoint;

            if($(document.activeElement).attr('tabindex') === "1" && ($scope.firstname !== "" || $scope.lastname !== ""))
                endpoint = profEndpoint;

            var searchQueryUrl = endpoint + qs;

            $window.location.href = searchQueryUrl; 
        };

        $scope.sectionSubmit = function ($event, category) {
            if(document.getElementsByClassName('section-option--active').length > 0)
                document.getElementsByClassName('section-option--active')[0].classList.remove('section-option--active')
            $event.currentTarget.classList.add('section-option--active')
            $scope.category = category;
            $scope.onSubmit();
        }

        $scope.onTrigger = function (tabId) {
            document.getElementsByClassName('active-button')[0].classList.remove('active-button');
            document.getElementsByClassName('active-section')[0].classList.remove('active-section');
            document.getElementById(tabId + '-button').classList.add('active-button');
            document.getElementById(tabId).classList.add('active-section');
        }

        $scope.onKeywordTypeaheadSelect = function (event, filter, selection) {
            if (!selection) return;

            //filter is always null for keyword
            //redirect to selection.Url
            if (selection && selection.Url) {
                $window.location.href = selection.Url;
            }
       }
       
        var init = function () {
            if ($element.attr('data-search-endpoint')) {
                searchEndpoint = $element.attr('data-search-endpoint');
            }
            if ($element.attr('data-prof-endpoint')) {
                profEndpoint = $element.attr('data-prof-endpoint');
            }
        };

        var buildQueryString = function () {
            var qs = "";
            
            if($scope.keyword !== '')
                qs = queryStringService.addQueryString('keyword', $scope.keyword, qs);
            if($scope.category !== '')
                qs = queryStringService.addQueryString('category', $scope.category, qs);

            if($scope.firstname !== '')
                qs = queryStringService.addQueryString('firstname', $scope.firstname, qs);
            
            if($scope.lastname !== '')
                qs = queryStringService.addQueryString('lastname', $scope.lastname, qs);
                
            return qs;
        };
        
        init(); 
    
    }]);
};