const setup = function (app) {
  app.directive('menuWatcher',
    ['$rootScope', 'appFactory', function ($rootScope, appFactory) {
      return {
        restrict: 'A',
        scope: true,
        bindToController: {
          menu: '&'
        },
        controllerAs: 'menuwatcherctrl',
        controller: ['$scope', function ($scope) {
          $scope.menu = appFactory.menu
        }]
      }
    }]
  )
}

module.exports = setup
