module.exports = function (app) {
    app.directive('emailDisclaimer',
        ['$rootScope', 'appFactory', 'queryStringService', function ($rootScope, appFactory, queryStringService) {
            return {
                restrict: 'A',
                scope: {
                    heading: '@heading',
                    body: '@body',
                    submit: '@submit',
                    cancel: '@cancel',
                    email: '@'
                },
                template: ''+
                            '<div class="modal-mask" data-ng-class="{true: \'active\'}[disclaimer.active]" ng-if="{true: \'active\'}[disclaimer.active]">' +
                            '<section class="section modal-wrapper">' +
                                '<div class="page-limit page-limit-narrow modal-container">' +
                                    '<h4 class="component-title modal-header"> {{ heading }} </h4>' +
                                    '<div class="richtext modal-body"> {{ body }}' +
                                    '</div>' +
                                    '<div class="buttons modal-footer">' +
                                        '<button class="btn  btn-default" ng-click="SendEmail()"> {{ submit }} </button>' +
                                        '<button class="btn  btn-default" ng-click="CloseModal()"> {{ cancel }} </button>' +
                                    '</div>' +
                                '</div>' +
                            '</section>' + 
                            '</div>',
                controllerAs: 'emailDisclaimerDirective',
                controller: ['$scope', function ($scope) {
                    $scope.disclaimer = appFactory.disclaimer

                    $scope.$on('email-disclaimer', function(event, args) {
                        appFactory.activateOverlay($scope)
                        appFactory.activateDisclaimer($scope)
                        $scope.$apply()
                        $scope.email = args.email
                    })

                    $scope.CloseModal = function () {
                        appFactory.deactivateDisclaimer();
                        appFactory.deactivateOverlay()
                    }

                    $scope.SendEmail = function () {
                        var mailTo = 'mailto:' + $scope.email
                        window.location.href = mailTo
                        $scope.CloseModal()
                    }
                }],
                link: function ($scope) {
                    $scope.onTriggerClose = function () {
                        appFactory.deactivateCaller()
                    }
                }
            }
        }]
    )
}