const setup = function (app) {
    app.directive('accordion',
        ['$rootScope', 'appFactory', 'scrollTrackerFactory', '$timeout', 'scrollToService', 'queryStringService', function ($rootScope, appFactory, scrollTrackerFactory, $timeout, scrollToService, $queryStringService) {
            return {
                restrict: 'A',
                scope: true,
                bindToController: {
                    triggers: '&',
                    settings: '&'
                },
                controllerAs: 'accordionctrl',
                controller: ['$scope', function ($scope) {
                    $scope.triggers = {}

                    $scope.scrollData = scrollTrackerFactory.tracker

                    $scope.settings = {
                        initialactive: 0,
                        offset: 16,
                        hasactive: false,
                        toggle: false,
                        showAll: false,
                        showToggleButton: false
                    }

                    $rootScope.$on('open-all-accordions', function () {
                        $scope.openAllAccordion();
                    }, true)

                    $rootScope.$on('close-all-accordions', function () {
                        $scope.deactivateAllAccordion();
                    }, true)

                    $rootScope.$on('open-all-group1-accordions', function () {
                        $scope.openAllGroup1Accordion();
                    }, true)

                    $rootScope.$on('close-all-group1-accordions', function () {
                        $scope.deactivateAllGroup1Accordion();
                    }, true)

                    $rootScope.$on('open-all-group2-accordions', function () {
                        $scope.openAllGroup2Accordion();
                    }, true)

                    $rootScope.$on('close-all-group2-accordions', function () {
                        $scope.deactivateAllGroup2Accordion();
                    }, true)

                    $scope.setFirstActive = function () {
                        var queryParameter = parseQueryParameter();
                        if (queryParameter) {
                            var trigger = findAccordion(queryParameter);
                            if (trigger) {
                                $scope.activate(trigger.id);
                            }
                        } else {
                            if ($scope.settings.initialactive) {
                                $scope.activate($scope.settings.initialactive)
                            }
                        }
                    }

                    $scope.activate = function (which) {
                        var hasactive = false
                        if (!$scope.settings.showAll) {
                            angular.forEach($scope.triggers, function (item, key) {
                                item.active = (item.active) ? false : (item.id === which)
                                if (item.active) {
                                    hasactive = true
                                }
                            })
                            $scope.settings.hasactive = hasactive
                        } else {
                            angular.forEach($scope.triggers, function (item, key) {
                                if (item.id === which) {
                                    item.active = (item.active) ? false : (item.id === which)
                                }
                            })
                        }
                    }

                    $scope.toggleAllAccordion = function () {
                        angular.forEach($scope.triggers, function (item, key) {
                            item.active = !$scope.toggle
                        })

                        $scope.settings.hasactive = !$scope.settings.showAll
                        $scope.settings.showAll = !$scope.settings.showAll
                    }

                    $scope.openAllAccordion = function () {
                        angular.forEach($scope.triggers, function (item, key) {
                            item.active = true
                        })

                        $scope.settings.hasactive = true
                        $scope.settings.showAll = true
                    }

                    $scope.deactivateAllAccordion = function () {
                        angular.forEach($scope.triggers, function (item, key) {
                            item.active = false
                        })

                        $scope.settings.hasactive = false
                        $scope.settings.showAll = false
                    }

                    $scope.openAllGroup1Accordion = function () {
                        angular.forEach($scope.triggers, function (item, key) {
                            if (item.group === "1") {
                                item.active = true
                            }
                        })
                    }

                    $scope.deactivateAllGroup1Accordion = function () {
                        angular.forEach($scope.triggers, function (item, key) {
                            if (item.group === "1") {
                                item.active = false
                            }
                        })
                    }

                    $scope.openAllGroup2Accordion = function () {
                        angular.forEach($scope.triggers, function (item, key) {
                            if (item.group === "2") {
                                item.active = true
                            }
                        })
                    }

                    $scope.deactivateAllGroup2Accordion = function () {
                        angular.forEach($scope.triggers, function (item, key) {
                            if (item.group === "2") {
                                item.active = false
                            }
                        })
                    }

                    $scope.addAccordion = function (settings) {
                        var id = Object.keys($scope.triggers).length
                        settings = (settings) ? JSON.parse(settings) : {}

                        $scope.triggers[id] = {
                            id: id,
                            group: settings.group || '',
                            name: settings.name || settings.title || '',
                            title: settings.title || '',
                            active: false,
                            limitHeight: '0px',
                            fullHeight: '999px'
                        }
                        return $scope.triggers[id]
                    }

                    var findAccordion = function (title) {
                        var selectedItem;

                        angular.forEach($scope.triggers, function (item, key) {
                            if (item.name === title) {
                                selectedItem = item;
                            }
                            else if (item.title === title) {
                                selectedItem = item;
                            };
                        });

                        return selectedItem;
                    }

                    var parseQueryParameter = function () {
                        var queryParameter = window.location.search;
                        if (queryParameter) {
                            return $queryStringService.getParameterByName('accordion');
                        }

                        return "";
                    }
                }],
                link: {
                    pre: function ($scope, $element, $attrs) {
                        var exsettings = $attrs.accordion || null
                        if (exsettings) {
                            exsettings = JSON.parse(exsettings)
                            $scope.settings = angular.extend({}, $scope.settings, exsettings)
                        }
                    },

                    post: function ($scope, $element) {
                        $scope.setFirstActive()
                        $scope.settings.showToggleButton = $scope.triggers && Object.keys($scope.triggers).length > 1;

                        $timeout(function () {
                            var activeEle = $element[0].getElementsByClassName('accordion__bar--active');
                            if (activeEle) {
                                scrollToService.scrollToElement(activeEle[0]);
                            }
                        })
                    }
                }
            }
        }]
    )

    app.directive('accordionResponder',
        ['$rootScope', 'scrollTrackerFactory', function ($rootScope, scrollTrackerFactory) {
            return {
                restrict: 'A',
                require: '^accordion',
                scope: true,
                bindToController: {
                    trigger: '&',
                    sticky: '&'
                },
                controllerAs: 'ctrl',
                controller: ['$scope', function ($scope) {
                    $scope.trigger = {}
                    $scope.sticky = {}
                }],
                link: {
                    pre: function ($scope, $element, $attrs) {
                        $scope.trigger = $scope.addAccordion($attrs.accordionResponder)

                        return $scope.trigger
                    },
                    post: function ($scope, $element) {
                        $scope.checkPosition = function (winposition) {
                            var winPos = winposition
                            var bottom = ($scope.sticky.elPosition + $scope.getHeight()) - ($scope.settings.offset * 4)
                            var newElTop = 0

                            if (winPos >= $scope.sticky.elPosition) {
                                $scope.sticky.stuck = true
                                if (winPos < bottom) {
                                    newElTop = (winPos - $scope.sticky.elPosition) + $scope.settings.offset // the distance from position and element top = increases as you scroll
                                    $scope.sticky.style = {
                                        'top': newElTop + 'px'
                                    }
                                }
                            } else {
                                $scope.resetSticky()
                            }
                        }

                        $scope.getHeight = function () {
                            $scope.sticky.elHeight = $element[0].offsetHeight
                            return $scope.sticky.elHeight
                        }

                        $scope.setSize = function () {
                            $scope.sticky.elPosition = $scope.scrollData.getElementOffset($element[0]).top
                            $scope.getHeight()
                        }

                        $scope.resetSticky = function () {
                            $scope.sticky.stuck = false
                            $scope.sticky.style = {}
                        }

                        $element.ready(function () {
                            $scope.resetSticky()
                            $scope.setSize()

                            $rootScope.$on('windowresize-windowSize', function () {
                                $scope.resetSticky()
                                $scope.setSize()
                            }, true)

                            $scope.$watch('scrollData.windowPosition', function (position) {
                                $scope.checkPosition(position)
                            }, true)
                        })
                    }
                }
            }
        }]
    )

    app.directive('accordionTrigger',
        [function () {
            return {
                restrict: 'A',
                require: '^accordionResponder',
                scope: true,
                template: '' +
                    '<button class="accordion__bar" ng-click="onAccordion()" ng-class="buttonClass()">' +
                    '<span class="accordion__title" ng-bind="trigger.title"></span>' +
                    '<span class="accordion__icon" data-ng-class="iconClass()" ng-style="iconStyle()">' +
                    '<i class="icon open icon-plus"></i><i class="icon close icon-minus"></i><span class="title close">Close</span></span></span>' +
                    '</button>',
                bindToController: {},
                controllerAs: 'ctrl',
                controller: ['$scope', function ($scope) {
                    $scope.buttonClass = function () {
                        return ($scope.trigger.active) ? 'accordion__bar--active' : ''
                    }
                    $scope.iconClass = function () {
                        return {
                            'active': $scope.trigger.active,
                            'stuck': $scope.sticky.stuck
                        }
                    }
                    $scope.iconStyle = function () {
                        return ($scope.trigger.active && $scope.sticky.stuck) ? $scope.sticky.style : {}
                    }
                    $scope.onAccordion = function () {
                        $scope.activate($scope.trigger.id)
                    }
                }]
            }
        }]
    )

    app.directive('accordionSizer',
        ['$rootScope', function ($rootScope) {
            return {
                restrict: 'A',
                require: '^accordionResponder',
                bindToController: {},
                controllerAs: 'ctrl',
                controller: ['$scope', function ($scope) { }],
                link: function ($scope, $element) {
                    $scope.getFullHeight = function (addtl) {
                        const child = $element.children().eq(0)[0]
                        const height = (child.offsetHeight !== undefined) ? child.offsetHeight : (child.clientHeight !== undefined) ? child.clientHeight : 0
                        const additional = (addtl !== undefined && !isNaN(addtl)) ? addtl : 0;
                        $scope.trigger.fullHeight = height + additional + 'px'
                    }

                    $element.ready(function () {
                        window.setTimeout(function () {
                            $scope.getFullHeight()
                        }, 1)

                        $rootScope.$on('windowresize-windowSize', function () {
                            $scope.getFullHeight()
                        }, true)
                    })

                    //force height update when read more btn is clicked
                    $scope.$on('read-more-toggled', function (evnt, args) {
                        if (evnt) {
                            var addtlHeight = 0;
                            if (args.height) {
                                addtlHeight = parseInt(args.height.substring(0, args.height.indexOf('px')));
                                if (addtlHeight > 0) {
                                    addtlHeight += 32; //margins
                                }
                                if (!args.active) {
                                    addtlHeight = 0 - addtlHeight;
                                }
                            }
                            $scope.getFullHeight(addtlHeight)
                        }
                    })
                }
            }
        }]
    )
}
module.exports = setup
