﻿module.exports = function (app) {
    app.controller('siteSearch', ['$window', '$scope', '$http', '$element', '$log', '$timeout', '$sce', 'helperService', 'queryStringService', 'searchService', 'scrollToService', function ($window, $scope, $http, $element, $log, $timeout, $sce, helperService, queryStringService, searchService, scrollToService) {
        $scope.isLoading = false;
        $scope.filters = {
            alphaFilter: {},
            toggleFilters: [],
            selectFilters: [],
            categoryFilter: [],
            activeFilters: []
        };
        $scope.results = [];
        $scope.totalResultCount = 0;
        $scope.currentResultCount = 0;
        $scope.enableInfiniteScroll = false;

        $scope.keyword = '';
        $scope.lastkeyword = '';

        $scope.hasSearchRun = false;
        $scope.hasSearchStarted = false;

        $scope.serverActiveFilters = [];

        /* PRIVATE VARIABLES */

        var language = 'en';
        var sanitizedFields = [];
        var qsSeparator = '|';
        var firstSearch = true;
        var searchType = 'all';
        var pageSize = 10;
        var pageNum = 1;
        var scrollPosition = 0;
        var endpoint = '';
        var sortBy = 2;
        var sortOrder = 1;
        var previousKeyword = '';
        var isExperienceEditor = false;
        var isViewMoreSearch = false;
        var resultsElement;
        var pageloadSearch = false;
        var outerPanel, innerPanel, closeButton;
        var altDown = false;
        var searchDataEndpoint = '';

        /* INIT */

        var init = function () {
            //look for initial state json for filters and results
            if ($element.attr('data-page-size')) {
                pageSize = helperService.tryParseInt($element.attr('data-page-size'), pageSize);
            }
            if ($element.attr('data-language')) {
                language = $element.attr('data-language');
            }
            if ($element.attr('data-search-endpoint')) {
                endpoint = $element.attr('data-search-endpoint');
            }
            if ($element.attr('data-sanitized-fields')) {
                sanitizedFields = $element.attr('data-sanitized-fields').split(',');
            }
            if ($element.attr('data-is-experience-editor')) {
                isExperienceEditor = true;
            }
            if (document.getElementById('json-select-filters')) {
                $scope.filters.selectFilters = JSON.parse(document.getElementById('json-select-filters').text);
            }
            if (document.getElementById('json-active-filters')) {
                $scope.serverActiveFilters = JSON.parse(document.getElementById('json-active-filters').text);
            }
            if (document.getElementById('json-category-filter')) {
                $scope.filters.categoryFilter = JSON.parse(document.getElementById('json-category-filter').text);
            }
            if (document.getElementById('scrollToTarget')) {
                resultsElement = document.getElementById('scrollToTarget');
            }

            if ($scope.filters.selectFilters && $scope.filters.selectFilters.length > 0) {
                $scope.select = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced === false; });
                $scope.advanced = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced === true; });
            }

            initFilters();

            if (queryStringService.hasQueryString()) {
                setFilters();
            }
            pageloadSearch = $scope.filters.activeFilters.length > 0;
            search();

            searchDataEndpoint = '/webapi/sitesearch/searchdata';
            outerPanel = $("<div class='onenorthsearchdata__panel onenorthsearchdata__panel--hidden'></div>");
            innerPanel = $("<div class='onenorthsearchdata__content'></div>");
            closeButton = $("<button class='onenorthsearchdata__closebutton'>X</button>");
            outerPanel.append(innerPanel);
            outerPanel.append(closeButton);
            $("body").append(outerPanel);

            $(window).on("keydown", function (e) {
                if (e.target.tagName === "INPUT") return;

                if (e.keyCode === 18) altDown = true;

                if (e.keyCode === 27) {
                    hidePanel();
                }
            });

            $(window).on("dblclick", function (e) {
                if (altDown)
                    showPanel();
            })

            $(window).on("keyup", function (e) {
                if (e.target.tagName === "INPUT") return;

                if (e.keyCode === 18) altDown = false;
            });

            closeButton.on("click", function () {
                hidePanel();
            });
        }

        /* EVENT HANDLERS */

        $scope.onSubmit = function () {

            if ($scope.keyword) {
                $scope.filters.activeFilters = [];
                resetSelectedFilters();
                addActiveKeywordFilter($scope.keyword);
            }
            pageNum = 1;

            search();
        }

        $scope.onFilterInit = function ($event, filter) {
        }

        $scope.onCategoryClick = function ($event, filter, option) {
            if (!(filter && option)) return;

            filter.SelectedOption = option;

            removeActiveFilterByGroup('category');

            if (filter.SelectedOption.Value !== 'All') {
                addActiveFilter('category', filter.SelectedOption.Text, filter.SelectedOption.Value);
            }
            var actives = [];
            angular.forEach($scope.filters.activeFilters, function (active) {
                var ok = false;

                var select = $scope.filters.selectFilters.filter(function (x) { return x.Name === active.Group });
                if (select && select.length > 0) {
                    if (select[0].Category && select[0].Category !== '') {
                        if (select[0].Category.includes($scope.filters.categoryFilter.SelectedOption.Value)) {
                            ok = true;
                        }
                    }
                    else {
                        ok = true;
                    }
                }

                if (ok || active.Group === 'category' || active.Group === 'keyword') {
                    actives.push(active);
                }
                else {
                    if (select && select.length > 0) {
                        if ($scope.filters.toggleFilter.Name === active.Group) {
                            $scope.filters.toggleFilter = {};
                        }
                        if (select[0].Options && select[0].Options.length > 0) {
                            select[0].SelectedOption = select[0].Options[0];
                        }
                        else {
                            select[0].SelectedOption = {};
                        }
                    }
                }
            });
            $scope.filters.activeFilters = actives;

            pageNum = 1;
            firstSearch = true;

            search();
        }

        $scope.onFilterChange = function ($event, filter) {
            if (!(filter && filter.SelectedOption)) return;

            if (filter.SelectedOption.Value === '') {
                removeActiveFilterByGroup(filter.Name);
                if ($scope.filters.toggleFilter && $scope.filters.toggleFilter.Name === filter.Name) {
                    $scope.filters.toggleFilter = {};
                }
            }
            else {
                removeActiveFilterByGroup(filter.Name);
                addActiveSelectFilter(filter);

                filter.Options.forEach(function (option) {
                    if (option.Toggles && option.Toggles.length > 0) {
                        option.SelectedToggle = {};
                    }
                });

                if (filter.SelectedOption.Toggles && filter.SelectedOption.Toggles.length > 0) {
                    setActiveToggleFilter(filter);
                }
            }
            pageNum = 1;

            search();
        }

        $scope.onFilterToggleSelect = function ($event, name, option, toggle) {
            if (!toggle || !toggle.IsEnabled) return;

            option.SelectedToggle = toggle;

            removeActiveFilterByGroup(name);
            addActiveFilter(name, toggle.Text, toggle.Value);
            pageNum = 1;

            search();
        }

        $scope.onRemoveFilter = function (event, active) {
            if (active.Group === 'keyword') {
                previousKeyword = $scope.keyword
                $scope.keyword = ''
            };
            removeActiveFilterByValue(active.Value);
            angular.forEach($scope.filters.selectFilters, function (filter) {
                if (filter.Name === active.Group) {
                    if ($scope.filters.toggleFilter.Name === active.Group) {
                        $scope.filters.toggleFilter = {};
                    }
                    if (filter.Options && filter.Options.length > 0) {
                        filter.SelectedOption = filter.Options[0];
                    }
                    else {
                        filter.SelectedOption = {};
                    }
                    if (filter.ExcludeIds)
                        filter.ExcludeIds = null;
                    if (filter.Keyword)
                        filter.Keyword = '';
                }
            });
            resetSearch()
            if (active.Group === 'category') {
                if ($scope.keyword) {
                    $scope.filters.activeFilters = [];
                    resetSelectedFilters();
                    addActiveKeywordFilter($scope.keyword);
                }
            }
            pageNum = 1;

            search();
        }

        $scope.onRemoveFilterAll = function (event) {

            resetSearch();
            $scope.keyword = '';
            search();
        }

        $scope.onResultLink = function ($event, url) {
            if ($event.ctrlKey || $event.metaKey)
                return;
            var qs = buildQueryString($window.pageYOffset);
            updateHistory(qs, true);
            window.location = url;
        }

        $scope.onViewMore = function ($event) {
            viewMore();
        }

        var viewMore = function (bUserTriggered) {
            if (!$scope.isLoading) {
                isViewMoreSearch = true;
                pageNum++;
                search();
            }
        }

        var initFilters = function () {
            if ($scope.filters.selectFilters) {
                angular.forEach($scope.filters.selectFilters, function (filter) {
                    if (filter.Options && filter.Options.length > 0) {
                        filter.SelectedOption = filter.Options[0];
                    }
                });
            }
        }

        var setFilters = function () {
            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('keyword'))) {
                var keyword = queryStringService.getParameterByName('keyword');
                if (keyword) {
                    addKeywordFilter(keyword);
                }
            }
            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('letter'))) {
                setLetterFilter(queryStringService.getParameterByName('letter'));
            }
            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('related'))) {
                setSelectFilters(queryStringService.getParameterByName('related'));
            }
            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('pageSize'))) {
                pageSize = helperService.tryParseInt(queryStringService.getParameterByName('pageSize'), 0);
            }
            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('pageNum'))) {
                pageNum = helperService.tryParseInt(queryStringService.getParameterByName('pageNum'), 0);
            }
            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortBy'))) {
                sortBy = helperService.tryParseInt(queryStringService.getParameterByName('sortBy'), 0);
            }
            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortOrder'))) {
                sortOrder = helperService.tryParseInt(queryStringService.getParameterByName('sortOrder'), 0);
            }
            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('language'))) {
                language = queryStringService.getParameterByName('language');
            }
            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('scroll'))) {
                scrollPosition = queryStringService.getParameterByName('scroll');
            }
            if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('category'))) {
                var category = queryStringService.getParameterByName('category')
                setCategoryFilter(category);
            }
        }

        var setCategoryFilter = function (category) {
            if (!(category === 'All' || category === 'Insights' || category === 'Professionals' || category === 'Services' || category === 'Experience' || category === 'News' || category === 'Others')) category = 'All';

            if ($scope.filters.categoryFilter && $scope.filters.categoryFilter.Options.length > 0) {
                angular.forEach($scope.filters.categoryFilter.Options, function (option) {
                    if (option.Value === category) {
                        $scope.filters.categoryFilter.SelectedOption = option;
                    }
                });
            }
            if ($scope.filters.categoryFilter.SelectedOption.Value !== 'All') {
                addActiveFilter('category', $scope.filters.categoryFilter.SelectedOption.Text, $scope.filters.categoryFilter.SelectedOption.Value);
            }
        }

        var addKeywordFilter = function (keyword) {
            $scope.keyword = keyword;
            addActiveKeywordFilter(keyword);
        }

        var setSelectFilters = function (relateds) {
            var aRelated = [];
            aRelated = relateds.split('|');
            angular.forEach(aRelated, function (relatedId) {
                var found = false;
                angular.forEach($scope.filters.selectFilters, function (filter) {
                    if (filter.Options && filter.Options.length > 0) {
                        angular.forEach(filter.Options, function (option) {
                            if (option.Value === relatedId) {
                                found = true;
                                filter.SelectedOption = option;
                                if (option.Toggles && option.Toggles.length > 0) {
                                    setActiveToggleFilter(filter);
                                }
                                addActiveSelectFilter(filter);
                            }
                            else if (option.Toggles && option.Toggles.length > 0) {
                                angular.forEach(option.Toggles, function (toggle) {
                                    if (toggle.Value === relatedId) {
                                        found = true;
                                        option.SelectedToggle = toggle;
                                        filter.SelectedOption = option;
                                        setActiveToggleFilter(filter);
                                        addActiveFilter(filter.Name, toggle.Text, toggle.Value);
                                    }
                                });
                            }
                        });
                    }
                });
                if (!found && $scope.serverActiveFilters && $scope.serverActiveFilters.length > 0) {
                    //try checking the active filters json from server for typeaheads
                    angular.forEach($scope.serverActiveFilters, function (server) {
                        if (server.Value === relatedId) {
                            addActiveFilter('?', server.Text, server.Value);
                        }
                    });
                }
            });
        }

        var addActiveKeywordFilter = function (keyword) {
            keyword = searchService.getKeywordDisplay(keyword);
            addActiveFilter('keyword', keyword, keyword);
        }

        var addActiveSelectFilter = function (filter) {
            if (filter && filter.SelectedOption) {
                addActiveFilter(filter.Name, filter.SelectedOption.Text, filter.SelectedOption.Value);
            }
        }

        var addActiveFilter = function (group, text, value) {
            $scope.filters.activeFilters.push({ Group: group, Text: text, Value: value });
        }

        var setActiveToggleFilter = function (filter) {
            $scope.filters.toggleFilter = { Name: filter.Name, Option: filter.SelectedOption };
        }

        var search = function () {
            if ($scope.keyword === '') {
                $scope.filters.categoryFilter = []
                return
            };
            if (isExperienceEditor) return;
            if (!$scope.filters.categoryFilter || $scope.filters.categoryFilter.length === 0) {
                if (document.getElementById('json-category-filter')) {
                    $scope.filters.categoryFilter = JSON.parse(document.getElementById('json-category-filter').text);
                }
            }
            if (!$scope.filters.categoryFilter.SelectedOption) {
                if ($scope.filters.categoryFilter.Options && $scope.filters.categoryFilter.Options.length > 0) {
                    $scope.filters.categoryFilter.SelectedOption = $scope.filters.categoryFilter.Options[0];
                }
            }

            $scope.hasSearchStarted = true;
            $scope.isLoading = true;
            $scope.lastkeyword = searchService.getKeywordDisplay($scope.keyword).replace(/^"+|"+$/gm, '');

            //build querystring
            var qs = buildQueryString();
            //history qs
            updateHistory(qs, isViewMoreSearch);

            //perform ajax request
            searchService.search(endpoint, qs, handleSearchSuccess, handleSearchError);
        }

        var handleSearchSuccess = function (response) {
            angular.forEach(response.data.Results, function (result) {
                angular.forEach(sanitizedFields, function (name) {
                    result[name] = $sce.trustAsHtml(result[name]);
                });
            });

            if (pageNum > 1 && !firstSearch) {
                $scope.results = $scope.results.concat(response.data.Results || []);
            }
            else {
                $scope.results = response.data.Results || [];
            }
            $scope.totalResultCount = response.data.TotalResultCount;
            $scope.currentResultCount = $scope.results.length;

            if (scrollPosition > 0) {
                $timeout(function () {
                    window.scroll(0, scrollPosition);
                });
            }
            else if ($scope.filters.activeFilters.length >= 1 && !!resultsElement && !pageloadSearch && !isViewMoreSearch) {
                scrollToService.scrollToElement(resultsElement);
            }

            if ($scope.currentResultCount >= $scope.totalResultCount) {
                $scope.enableInfiniteScroll = false;
            }
            else {
                $timeout(function () {
                    $scope.enableInfiniteScroll = $scope.totalResultCount > 0 && $scope.currentResultCount > 0 && ($scope.currentResultCount < $scope.totalResultCount);
                }, 1000);
            }

            if ($scope.keyword !== '' && $scope.keyword !== previousKeyword) {
                previousKeyword = $scope.keyword;
            }

            firstSearch = false;
            pageloadSearch = false;
            isViewMoreSearch = false;
            $scope.hasSearchRun = true;
            $scope.isLoading = false;
        }

        var handleSearchError = function (error) {
            pageloadSearch = false;
            isViewMoreSearch = false;
            $scope.isLoading = false;
            $scope.enableInfiniteScroll = false;
            $scope.keyword = '';
        }

        var buildQueryString = function (scroll) {
            var category = '';
            if ($scope.filters.categoryFilter) {
                category = $scope.filters.categoryFilter.SelectedOption.Value;
            }

            var related = '';
            angular.forEach($scope.filters.activeFilters, function (active) {
                if (active.Group !== 'keyword' && active.Group !== 'category') {
                    related += (related !== '' ? '|' : '') + active.Value;
                }
            });

            var qs = '';
            if (category !== '' && category !== 'All') {
                qs = queryStringService.addQueryString('category', category, qs);
            }
            if ($scope.keyword && $scope.keyword !== '') {
                qs = queryStringService.addQueryString('keyword', $scope.keyword, qs);
            } else if (previousKeyword && previousKeyword !== '') {
                qs = queryStringService.addQueryString('keyword', previousKeyword, qs);
            }
            if (related && related !== '')
                qs = queryStringService.addQueryString('related', related, qs);
            if (firstSearch)
                qs = queryStringService.addQueryString('noSkip', true, qs);
            if (pageNum && pageNum > 1)
                qs = queryStringService.addQueryString('pageNum', pageNum, qs);
            qs = queryStringService.addQueryString('pageSize', pageSize, qs);
            qs = queryStringService.addQueryString('sortBy', sortBy, qs);
            qs = queryStringService.addQueryString('sortOrder', sortOrder, qs);
            if (language !== '')
                qs = queryStringService.addQueryString('language', language, qs);
            if (scroll && scroll > 0)
                qs = queryStringService.addQueryString('scroll', scroll, qs);

            return qs;
        }

        var updateHistory = function (qs, replace) {
            var historyQs = qs;
            historyQs = queryStringService.removeQueryString('noSkip', true, historyQs);
            historyQs = queryStringService.removeQueryString('pageSize', pageSize, historyQs);
            historyQs = queryStringService.removeQueryString('sortBy', sortBy, historyQs);
            historyQs = queryStringService.removeQueryString('sortOrder', sortOrder, historyQs);
            historyQs = queryStringService.removeQueryString('language', language, historyQs);
            //update history & querystring
            if (pageloadSearch || replace) {
                history.replaceState(null, document.title, historyQs.replace('?&', '?'));
            }
            else {
                history.pushState(null, document.title, historyQs.replace('?&', '?'));
            }
        }

        var resetHistory = function () {
            history.pushState(null, document.title, '?');
        }

        var removeActiveFilterByGroup = function (group) {
            $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Group !== group; });
        }

        var removeActiveFilterByValue = function (value) {
            $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Value !== value });
        }

        var updateActiveFilters = function () {
            $scope.filters.activeFilters = [];
            angular.forEach($scope.filters.selectFilters, function (filter) {
                if (filter.SelectedOption && filter.SelectedOption.Value !== '') {
                    if (filter.SelectedOption.SelectedToggle && filter.SelectedOption.SelectedToggle.Value !== '') {
                        $scope.filters.activeFilters.push({ Group: filter.Name, Text: filter.SelectedOption.SelectedToggle.Text, Value: filter.SelectedOption.SelectedToggle.Value });
                    }
                    else {
                        $scope.filters.activeFilters.push({ Group: filter.Name, Text: filter.SelectedOption.Text, Value: filter.SelectedOption.Value });
                    }
                }
            });
        }

        var resetSearch = function () {
            $scope.filters.activeFilters = [];
            resetActiveFilters();
            resetSelectedFilters();
            resetHistory();
            $scope.hasSearchRun = false;
            $scope.results = [];
            pageNum = 1;
            previousKeyword = ''
        }

        var resetActiveFilters = function () {
            $scope.filters.categoryFilter = [];
        }

        var resetSelectedFilters = function () {
            angular.forEach($scope.filters.selectFilters, function (filter) {
                filter.SelectedOption = {};
                if (filter.Options && filter.Options.length > 0) {
                    filter.Options.forEach(function (option) {
                        option.SelectedToggle = {};
                    });
                    filter.SelectedOption = filter.Options[0];
                }
                if (filter.ExcludeIds)
                    filter.ExcludeIds = null;
                if (filter.Keyword)
                    filter.Keyword = '';
            });
            $scope.filters.toggleFilter = {};
        }

        var resetCategorySearch = function () {
            if (!$scope.filters.categoryFilter.SelectedOption || $scope.filters.categoryFilter.SelectedOption.Value !== 'all') {

            }
        }

        var showPanel = function () {
            if (outerPanel.hasClass("onenorthsearchdata__panel--hidden")) {
                outerPanel.removeClass("onenorthsearchdata__panel--hidden").addClass("onenorthsearchdata__panel--displayed");
            if(!$("body").hasClass("onenorthsearchdata--opened"))
                $("body").addClass("onenorthsearchdata--opened")

                requestData();
            }
        }

        var hidePanel = function () {
            if (!outerPanel.hasClass("onenorthsearchdata__panel--hidden")) {
                outerPanel.removeClass("onenorthsearchdata__panel--displayed").addClass("onenorthsearchdata__panel--hidden");
            }
            if($("body").hasClass("onenorthsearchdata--opened"))
                $("body").removeClass("onenorthsearchdata--opened")
        }

        var handleSearchDataSuccess = function (result) {
            innerPanel.append("<pre>");
            for(var i=0; i <result.data.length; i++){
              innerPanel.children().first().append(document.createTextNode(JSON.stringify(result.data[i], null, 3)))
            }
            innerPanel.append("</pre>");
            innerPanel.removeClass("onenorthsearchdata__content--loading");
        }

        var handleSearchDataError = function (result, status, errorText) {
            innerPanel.append($("<div class='onenorthsearchdata__block onenorthsearchdata__block--full'><h4>Unable to Display</h4><p>" + errorText + "</p></div>"));
            innerPanel.removeClass("onenorthsearchdata__content--loading");
        }

        var requestData = function () {
            innerPanel.empty();
            innerPanel.addClass("onenorthsearchdata__content--loading");

            //perform ajax request
            searchService.search(searchDataEndpoint, buildQueryString(), handleSearchDataSuccess, handleSearchDataError);
        }

        init();
    }])
}