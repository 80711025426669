﻿module.exports = function (app) {
  app.controller('newsLanding', ['$window', '$scope', '$element', '$timeout', '$sce', 'helperService', 'queryStringService', 'searchService', 'scrollToService', function ($window, $scope, $element, $timeout, $sce, helperService, queryStringService, searchService, scrollToService) {
    $scope.isLoading = false;
    $scope.filters = {
      toggleFilters: [],
      selectFilters: [],
      categoryFilters: [],
      activeFilters: [],
    };
    $scope.results = [];
    $scope.totalResultCount = 0;
    $scope.currentResultCount = 0;
    $scope.enableInfiniteScroll = false;

    $scope.keyword = '';

    $scope.hasSearchRun = false;
    $scope.hasSearchStarted = false;

    $scope.serverActiveFilters = [];

    $scope.subserviceFilters = [{ 'Text': 'Select', 'Value': '' }];
    $scope.selectedService = { 'SelectedToggle': $scope.subserviceFilters[0] };
    $scope.subindustryFilters = [{ 'Text': 'Select', 'Value': '' }];
    $scope.selectedIndustry = { 'SelectedToggle': $scope.subindustryFilters[0] };

    /* PRIVATE VARIABLES */

    var language = 'en';
    var sanitizedFields = [];
    var firstSearch = true;
    var pageSize = 10;
    var pageNum = 1;
    var scrollPosition = 0;
    var endpoint = '';
    var sortBy = 2;
    var sortOrder = 1;
    var pageloadSearch = false;
    var resultsElement;
    var previousKeyword = '';
    var isExperienceEditor = false;
    var isViewMoreSearch = false;

    /* INIT */

    var init = function () {
      //look for initial state json for filters and results

      if ($element.attr('data-page-size')) {
        pageSize = helperService.tryParseInt($element.attr('data-page-size'), pageSize);
      }
      if ($element.attr('data-language')) {
        language = $element.attr('data-language');
      }
      if ($element.attr('data-search-endpoint')) {
        endpoint = $element.attr('data-search-endpoint');
      }
      if ($element.attr('data-sanitized-fields')) {
        sanitizedFields = $element.attr('data-sanitized-fields').split(',');
      }
      if ($element.attr('data-is-experience-editor')) {
        isExperienceEditor = true;
      }
      if (document.getElementById('json-select-filters')) {
        $scope.filters.selectFilters = JSON.parse(document.getElementById('json-select-filters').text);
      }
      if (document.getElementById('json-active-filters')) {
        $scope.serverActiveFilters = JSON.parse(document.getElementById('json-active-filters').text);
      }
      if (document.getElementById('scrollToTarget')) {
        resultsElement = document.getElementById('scrollToTarget');
      }
      if (document.getElementById('scrollToTarget')) {
        resultsElement = document.getElementById('scrollToTarget');
      }

      if ($scope.filters.selectFilters && $scope.filters.selectFilters.length > 0) {
        $scope.select = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced == false; });
        $scope.advanced = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced == true; });
      }

      initFilters();

      if (queryStringService.hasQueryString()) {
        setFilters();
      }
      pageloadSearch = $scope.filters.activeFilters.length > 0;
      search();
    }

    /* EVENT HANDLERS */

    $scope.onSubmit = function () {
      if ($scope.keyword) {
        $scope.filters.activeFilters = [];
        resetSelectedFilters();
        addActiveKeywordFilter($scope.keyword);
      }
      pageNum = 1;

      search();
    }

    $scope.showFilter = function (filter) {
      return !filter.Category || !$scope.filters.categoryFilter || filter.Category.includes($scope.filters.categoryFilter.SelectedOption.Value);
    }

    $scope.onFilterInit = function ($event, filter) {
    }

    $scope.onFilterChange = function ($event, filter) {
      if (!(filter && filter.SelectedOption)) return;

      removeActiveFilterByGroup(filter.Name);

      if (filter.Name === 'services') {
        removeActiveToggleFiltersByGroup(filter.Name);
        removeActiveFilterByGroup('subservices');
      } else if (filter.Name === 'industries') {
        removeActiveToggleFiltersByGroup(filter.Name);
        removeActiveFilterByGroup('subindustries');
      }

      if (filter.SelectedOption.Value !== '') {
        addActiveSelectFilter(filter);

        filter.Options.forEach(function (option) {
          if (option.Toggles && option.Toggles.length > 0) {
            option.SelectedToggle = {};
          }
        });

        if (filter.SelectedOption.Toggles && filter.SelectedOption.Toggles.length > 0) {
          addActiveToggleFilter(filter);
        }
      }

      pageNum = 1;
      search();
    }

    $scope.onFilterToggleSelect = function ($event, name, option, toggle) {
      if (!toggle || !toggle.IsEnabled) return;

      option.SelectedToggle = toggle;

      removeActiveFilterByGroup(name);
      addActiveFilter(name, toggle.Text, toggle.Value);
      pageNum = 1;

      search();
    }

    $scope.onSubFilterChange = function ($event, name, toggle) {
      removeActiveFilterByGroup('sub' + name);

      if (name === 'services' && $scope.selectedService.SelectedToggle.Value !== '') {
        addActiveFilter('sub' + name, $scope.selectedService.SelectedToggle.Text, $scope.selectedService.SelectedToggle.Value);
      }
      else if (name === 'industries' && $scope.selectedIndustry.SelectedToggle.Value !== '') {
        addActiveFilter('sub' + name, $scope.selectedIndustry.SelectedToggle.Text, $scope.selectedIndustry.SelectedToggle.Value);
      }

      search();
    }

    $scope.onRemoveFilter = function (event, active) {
      removeActiveFilterByValue(active.Value);

      if (active.Group === 'subservices') {
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0]
      }

      if (active.Group === 'subindustries') {
        $scope.selectedIndustry.SelectedToggle = $scope.subindustryFilters[0]
      }

      if (active.Group === 'keyword') {
        previousKeyword = '';
      }

      angular.forEach($scope.filters.selectFilters, function (filter) {
        if (filter.Name === active.Group) {
          removeActiveToggleFiltersByGroup(active.Group);
          if (filter.Options && filter.Options.length > 0) {
            filter.SelectedOption = filter.Options[0];
          } else {
            filter.SelectedOption = {};
          }
          if (filter.ExcludeIds)
            filter.ExcludeIds = null;
          if (filter.Keyword)
            filter.Keyword = '';
        }
      });

      pageNum = 1;
      search();
    }

    $scope.onRemoveFilterAll = function (event) {
      resetSearch();
      search();
    }

    $scope.onResultLink = function ($event, url) {
      if ($event.ctrlKey || $event.metaKey)
        return;
      var qs = buildQueryString($window.pageYOffset);
      updateHistory(qs, true);
      window.location = url;
    }

    $scope.onViewMore = function ($event) {
      viewMore();
    }

    var viewMore = function (bUserTriggered) {
      if (!$scope.isLoading) {
        isViewMoreSearch = true;
        pageNum++;
        search();
      }
    }

    var initFilters = function () {
      if ($scope.filters.selectFilters) {
        angular.forEach($scope.filters.selectFilters, function (filter) {
          if (filter.Options && filter.Options.length > 0) {
            filter.SelectedOption = filter.Options[0];
          }
        });
      }
    }

    var setFilters = function () {
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('keyword'))) {
        var keyword = queryStringService.getParameterByName('keyword');
        if (keyword) {
          addKeywordFilter(keyword);
        }
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('date'))) {
        setSelectFilters(queryStringService.getParameterByName('date'));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('related'))) {
        setSelectFilters(queryStringService.getParameterByName('related'));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('pageSize'))) {
        pageSize = helperService.tryParseInt(queryStringService.getParameterByName('pageSize'), 0);
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('pageNum'))) {
        pageNum = helperService.tryParseInt(queryStringService.getParameterByName('pageNum'), 0);
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortBy'))) {
        sortBy = helperService.tryParseInt(queryStringService.getParameterByName('sortBy'), 0);
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortOrder'))) {
        sortOrder = helperService.tryParseInt(queryStringService.getParameterByName('sortOrder'), 0);
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('language'))) {
        language = queryStringService.getParameterByName('language');
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('scroll'))) {
        scrollPosition = queryStringService.getParameterByName('scroll');
      }
    }

    var addKeywordFilter = function (keyword) {
      $scope.keyword = keyword;
      const newKeyword = searchService.getKeywordDisplay(keyword);
      addActiveFilter('keyword', newKeyword, newKeyword);
    }

    var setSelectFilters = function (relateds) {
      var aRelated = [];
      aRelated = relateds.split('|');
      angular.forEach(aRelated, function (relatedId) {
        var found = false;
        angular.forEach($scope.filters.selectFilters, function (filter) {
          if (filter.Options && filter.Options.length > 0 && $scope.showFilter(filter)) {
            angular.forEach(filter.Options, function (option) {
              if (option.Value === relatedId) {
                found = true;
                filter.SelectedOption = option;
                if (option.Toggles && option.Toggles.length > 0) {
                  addActiveToggleFilter(filter);
                }
                addActiveSelectFilter(filter);
              }
              else if (option.Toggles && option.Toggles.length > 0) {
                angular.forEach(option.Toggles, function (toggle) {
                  if (toggle.Value === relatedId) {
                    found = true;
                    option.SelectedToggle = toggle;
                    filter.SelectedOption = option;
                    addActiveFilter('sub' + filter.Name, toggle.Text, toggle.Value);
                    addActiveToggleFilter(filter);

                    if (filter.Name === 'services') {
                      $scope.selectedService.SelectedToggle = toggle;
                    } else if (filter.Name === 'industries') {
                      $scope.selectedIndustry.SelectedToggle = toggle;
                    }
                  }
                });
              }
            });
          }
        });
        if (!found && $scope.serverActiveFilters && $scope.serverActiveFilters.length > 0) {
          //try checking the active filters json from server for typeaheads
          angular.forEach($scope.serverActiveFilters, function (server) {
            if (server.Value === relatedId) {
              addActiveFilter('?', server.Text, server.Value);
            }
          });
        }
      });
    }

    var addActiveKeywordFilter = function (keyword) {
      keyword = searchService.getKeywordDisplay(keyword);
      addActiveFilter('keyword', keyword, keyword);
    }

    var addActiveSelectFilter = function (filter) {
      if (filter && filter.SelectedOption) {
        addActiveFilter(filter.Name, filter.SelectedOption.Text, filter.SelectedOption.Value);
      }
    }

    var addActiveFilter = function (group, text, value) {
      $scope.filters.activeFilters.push({ Group: group, Text: text, Value: value });
    }

    var addActiveToggleFilter = function (filter) {
      $scope.filters.toggleFilters.push({ Name: filter.Name, Option: filter.SelectedOption });

      if (filter.Name === 'services') {
        $scope.selectedService = filter.SelectedOption;
        //clear out existig drop-down
        $scope.subserviceFilters = [$scope.subserviceFilters[0]]
        //add new items
        $scope.subserviceFilters = $scope.subserviceFilters.concat(filter.SelectedOption.Toggles);
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0];
      }
      if (filter.Name === 'industries') {
        $scope.selectedIndustry = filter.SelectedOption;
        //clear out existig drop-down
        $scope.subindustryFilters = [$scope.subindustryFilters[0]]
        //add new items
        $scope.subindustryFilters = $scope.subindustryFilters.concat(filter.SelectedOption.Toggles);
        $scope.selectedIndustry.SelectedToggle = $scope.subserviceFilters[0];
      }
    }

    var search = function () {
      if (isExperienceEditor) return;

      $scope.hasSearchStarted = true;
      $scope.isLoading = true;
      const isAlreadyInFilterSet = !!$scope.filters.activeFilters.filter(x => { return x.Text.includes($scope.keyword) }).length;

      //if they fill out text input field and click an advanced filter
      if ($scope.filters.activeFilters.filter(function (x) { return x.Group === 'keyword' }) !== undefined && $scope.keyword !== '' && !isAlreadyInFilterSet) {
        const keyword = searchService.getKeywordDisplay($scope.keyword);
        addActiveFilter('keyword', keyword, keyword);
      }

      //build querystring
      var qs = buildQueryString();
      //history qs
      updateHistory(qs, isViewMoreSearch);

      //perform ajax request
      searchService.search(endpoint, qs, handleSearchSuccess, handleSearchError);
    }

    var handleSearchSuccess = function (response) {
      angular.forEach(response.data.Results, function (result) {
        angular.forEach(sanitizedFields, function (name) {
          result[name] = $sce.trustAsHtml(result[name]);
        });
      });

      if (pageNum > 1 && !firstSearch) {
        $scope.results = $scope.results.concat(response.data.Results);
      }
      else {
        $scope.results = response.data.Results;
      }
      $scope.totalResultCount = response.data.TotalResultCount;
      $scope.currentResultCount = $scope.results.length;

      if (scrollPosition > 0) {
        $timeout(function () {
          window.scroll(0, scrollPosition);
        });
      } else if ($scope.filters.activeFilters.length === 1 && !!resultsElement && !pageloadSearch && !isViewMoreSearch) {
        scrollToService.scrollToElement(resultsElement);
      }

      if ($scope.currentResultCount >= $scope.totalResultCount) {
        $scope.enableInfiniteScroll = false;
      }
      else {
        $timeout(function () {
          $scope.enableInfiniteScroll = $scope.totalResultCount > 0 && $scope.currentResultCount > 0 && ($scope.currentResultCount < $scope.totalResultCount);
        }, 1000);
      }

      if ($scope.keyword !== '' && $scope.keyword !== previousKeyword) {
        previousKeyword = $scope.keyword;
      }

      firstSearch = false;
      pageloadSearch = false;
      isViewMoreSearch = false;
      $scope.hasSearchRun = true;
      $scope.isLoading = false;
      $scope.keyword = '';
    }

    var handleSearchError = function (error) {
      pageloadSearch = false;
      isViewMoreSearch = false;
      $scope.enableInfiniteScroll = false;
      $scope.isLoading = false;
      $scope.keyword = '';
    }

    var buildQueryString = function (scroll) {
      var related = '';
      var date = '';
      angular.forEach($scope.filters.activeFilters, function (active) {
        if (active.Group !== 'keyword' && active.Group !== 'date') {
          related += (related !== '' ? '|' : '') + active.Value;
        }
        if (active.Group == 'date') {
          date = active.Value;
        }
      });

      var qs = '';
      if ($scope.keyword && $scope.keyword !== '') {
        qs = queryStringService.addQueryString('keyword', $scope.keyword, qs);
      } else if (previousKeyword && previousKeyword !== '') {
        qs = queryStringService.addQueryString('keyword', previousKeyword, qs);
      }
      if (date && date !== '') {
        qs = queryStringService.addQueryString('date', date, qs);
      }
      if (related && related !== '')
        qs = queryStringService.addQueryString('related', related, qs);
      if (firstSearch)
        qs = queryStringService.addQueryString('noSkip', true, qs);
      if (pageNum && pageNum > 1)
        qs = queryStringService.addQueryString('pageNum', pageNum, qs);
      qs = queryStringService.addQueryString('pageSize', pageSize, qs);
      qs = queryStringService.addQueryString('sortBy', sortBy, qs);
      qs = queryStringService.addQueryString('sortOrder', sortOrder, qs);
      if (language !== '')
        qs = queryStringService.addQueryString('language', language, qs);
      if (scroll && scroll > 0)
        qs = queryStringService.addQueryString('scroll', scroll, qs);

      return qs;
    }

    var updateHistory = function (qs, replace) {
      var historyQs = qs;
      historyQs = queryStringService.removeQueryString('noSkip', true, historyQs);
      historyQs = queryStringService.removeQueryString('pageSize', pageSize, historyQs);
      historyQs = queryStringService.removeQueryString('sortBy', sortBy, historyQs);
      historyQs = queryStringService.removeQueryString('sortOrder', sortOrder, historyQs);
      historyQs = queryStringService.removeQueryString('language', language, historyQs);
      //update history & querystring
      if (pageloadSearch || replace) {
        history.replaceState(null, document.title, historyQs.replace('?&', '?'));
      }
      else {
        history.pushState(null, document.title, historyQs.replace('?&', '?'));
      }
    }

    var resetHistory = function () {
      history.pushState(null, document.title, '?');
    }

    var removeActiveFilterByGroup = function (group) {
      $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Group !== group; });
    }

    var removeActiveFilterByValue = function (value) {
      $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Value !== value });
    }

    var removeActiveToggleFiltersByGroup = function (group) {
      if (group === 'services') {
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0];
        $scope.subserviceFilters = [$scope.subserviceFilters[0]];
      }
      else if (group === 'industries') {
        $scope.selectedIndustry.SelectedToggle = $scope.subindustryFilters[0];
        $scope.subindustryFilters = [$scope.subindustryFilters[0]];
      }

      $scope.filters.toggleFilters = $scope.filters.toggleFilters.filter(function (active) { return active.Name !== group; });
    }

    var resetSearch = function () {
      $scope.filters.activeFilters = [];
      resetSelectedFilters();
      resetHistory();
      $scope.hasSearchRun = false;
      $scope.results = [];
      pageNum = 1;
      previousKeyword = '';
      $scope.subserviceFilters = [$scope.subserviceFilters[0]]
      $scope.subindustryFilters = [$scope.subindustryFilters[0]]
    }

    var resetSelectedFilters = function () {
      angular.forEach($scope.filters.selectFilters, function (filter) {
        filter.SelectedOption = {};
        if (filter.Options && filter.Options.length > 0) {
          filter.Options.forEach(function (option) {
            option.SelectedToggle = {};
          });
          filter.SelectedOption = filter.Options[0];
        }
        if (filter.ExcludeIds)
          filter.ExcludeIds = null;
        if (filter.Keyword)
          filter.Keyword = '';
      });
      $scope.filters.toggleFilters = [];
    }

    init();
  }])
}