﻿module.exports = function (app) {
    app.factory("queryStringService", ['$window', function ($window) {
        return {
            /**
             * @Converts a JSON object to a querystring
             * @returns string
             */
            toQueryString: function (jsonObject) {
                var result = '';
                for (key in jsonObject) {
                    result += key + '=' + jsonObject[key] + '&';
                }
                return result.slice(0, result.length - 1);
            },
            /**
             * @Converts a querystring to a filter array
             * @returns string
             */
            toKeyValueArray: function (queryString) {
                var sets = queryString.replace('?', '').split('&');
                var keyValueArray = [];
                for (set in sets) {
                    var keyVals = set.split('=');
                    keyValueArray.push({ name: keyVals[0], value: keyVals[1] });
                }
                return keyValueArray;
            },
            /**
             * @Converts Returns a query string parameter value by name
             * @returns string
             */
            getParameterByName: function (name) {
                name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
                var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                        results = regex.exec(location.search);
                return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
            },
            /**
             * @Converts Returns a query string parameter value by name
             * @returns string
             */
            getParametersByName: function (name) {
                var parts = [];
                var hashArr = [];
                if (location.hash != '') {
                    hashArr = location.hash.replace('#', '').substring(location.hash.indexOf('?')).replace('?', '').split('&');
                }
                else {
                    parts = location.search.replace('?', '').split('&');
                }

                parts = parts.concat(hashArr);
                var arr = [];
                for (var i = 0; i < parts.length; i++) {
                    var part = parts[i].split('=');
                    if (part[0] == name)
                        arr.push(part[1]);
                }
                return arr;
            },

            getParametersValueByName: function (name, queryString) {
                if (!queryString && queryString != "") {
                    queryString = $window.location.search;
                }
                name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
                var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                    results = regex.exec(queryString);
                return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
            },


            /**
             * @Converts Removes a query string parameter
             * @returns string
             */
            removeQueryString: function (key, value, queryString) {
                if (!queryString && queryString != "") queryString = decodeURI($window.location.search);
                return queryString.replace('&' + key + "=" + value, "").replace(key + "=" + value, "");
            },
            /**
             * @Converts Update query string parameter
             * @returns string
             */
            updateQueryString: function (key, value, queryString, oldvalue) {

                if (!queryString && queryString != "") {
                    queryString = decodeURI($window.location.search);
                }
                if (oldvalue != undefined) {
                    var re = new RegExp("([?&])" + key + "=" + oldvalue + "?(&|#|$)(.*)", "gi");
                }
                else {
                    var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi");
                }
                if (re.test(queryString)) {
                    //update query string value
                    if (typeof value !== 'undefined' && value !== null)
                        return queryString.replace(re, '$1' + key + "=" + value + '$2$3');
                    else {
                        var hash = queryString.split('#');
                        queryString = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
                        if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                            queryString += '#' + hash[1];
                        return queryString;
                    }
                }
                else {
                    //query string doesn't exist, add querystring
                    return this.addQueryString(key, value, queryString);
                }
            },

            addQueryString: function (key, value, queryString) {
                if (!queryString && queryString != "")
                    queryString = decodeURI($window.location.search);
                value = encodeURIComponent(value);
                if (typeof value !== 'undefined' && value !== null) {
                    if (queryString.indexOf(key + '=' + value) > -1)//querystring already exists - don't add
                        return queryString;
                    var separator = queryString.indexOf('?') !== -1 ? '&' : '?';
                    var hash = queryString.split('#');
                    if (value instanceof Array) {
                        if (value.length > 0) {
                            queryString = hash[0] + separator + key + '=' + value[0];
                            for (i = 1; i < value.length; i++) {
                                queryString = queryString + '&' + key + '=' + value[i];
                            }
                        }
                        else
                            queryString = hash[0] + separator + key + '=';

                    }
                    else
                        queryString = hash[0] + separator + key + '=' + value;

                    if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                        queryString += '#' + hash[1];
                    return queryString;
                }
                else
                    return queryString;
            },

            hasQueryString: function () {
                return location.search.length > 0;
            },
        };
    }]);
}