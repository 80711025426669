/*
  NOTE: body overlay
*/
module.exports = function (app) {
    app.directive('placeholderText',
      ['appFactory', '$rootScope', '$window', function (appFactory, $rootScope) {
          var controller = ['$scope', '$element', function ($scope, $element) {
              function init() {
                  $scope.defaultText = 'Search';
                  $scope.altText = '';
                  $scope.placeholder = '';
              }

              init();
          }],
          link = {
              pre: function ($scope, $element, $window) {
                  var isMobile  = ($window.innerWidth < 992);

                $rootScope.$on('windowresize-isMobile', function (event, ismobile) {
                    $element[0].placeholder = (ismobile) ? $element[0].attributes["data-placeholder-text-mobile"].value || '': $element[0].attributes["data-placeholder-text-desktop"].value || ''
                }, true);

                $scope.$watch('placeholder', function () {
                    $element[0].placeholder = (isMobile) ? $element[0].attributes["data-placeholder-text-mobile"].value || '' : $element[0].attributes["data-placeholder-text-desktop"].value || ''
                }, true);
              }
          };

          return {
              replace: false,
              restrict: 'A',
              controller: controller,
              link: link
          }
      }]
    )
}
