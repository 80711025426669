﻿module.exports = function (app) {
  app.controller('experiencesLanding', ['$scope', '$element', '$sce', 'queryStringService', 'searchService', 'helperService', 'scrollToService', function ($scope, $element, $sce, queryStringService, searchService, helperService, scrollToService) {

    //SCOPE VARIABLES
    $scope.isLoading = false;
    $scope.showAll = false;
    $scope.enableInfiniteScroll = false;
    $scope.filters = {
      toggleFilters: [],
      selectFilters: [],
      activeFilters: []
    };
    $scope.keyword = '';
    $scope.totalResultCount = 0;
    $scope.results = [];

    $scope.activeSearch = false;
    $scope.serverActiveFilters = [];
    $scope.showClearAll = false;
    $scope.resultsLabel = '';
    $scope.listingCountLabel = '';
    $scope.noResultsFound = false;
    $scope.subserviceFilters = [{ 'Text': 'Select', 'Value': '' }];
    $scope.selectedService = { 'SelectedToggle': $scope.subserviceFilters[0] };
    $scope.subindustryFilters = [{ 'Text': 'Select', 'Value': '' }];
    $scope.selectedIndustry = { 'SelectedToggle': $scope.subindustryFilters[0] };

    //PRIVATE VARIABLES
    var language = 'en';
    var sanitizedFields = [];
    var endpoint = '';
    var defaultPageSize = 6;
    var pageSize = defaultPageSize;
    var pageNum = 1;
    var sortBy = 3; //Date
    var sortOrder = 1; //Descending
    var resultsLabel = '';
    var listingCountLabel = ''
    var pageloadSearch = false;
    var resultsElement;
    var previousKeyword = '';
    var isExperienceEditor = false;
    var isViewMoreSearch = false;
    var scrollPosition = false;

    var init = function () {
      if ($element.attr('data-page-size')) {
        pageSize = helperService.tryParseInt($element.attr('data-page-size'), defaultPageSize);
      }
      if ($element.attr('data-language')) {
        language = $element.attr('data-language');
      }
      if ($element.attr('data-search-endpoint')) {
        endpoint = $element.attr('data-search-endpoint');
      }
      if ($element.attr('data-sanitized-fields')) {
        sanitizedFields = $element.attr('data-sanitized-fields').split(',');
      }
      if ($element.attr('data-is-experience-editor')) {
        isExperienceEditor = true;
      }
      if ($element.attr('data-resultslabel')) {
        resultsLabel = $element.attr('data-resultslabel');
      }
      if ($element.attr('data-listingcountlabel')) {
        listingCountLabel = $element.attr('data-listingcountlabel');
      }
      if (document.getElementById('json-select-filters')) {
        $scope.filters.selectFilters = JSON.parse(document.getElementById('json-select-filters').text);
      }
      if (document.getElementById('json-active-filters')) {
        $scope.serverActiveFilters = JSON.parse(document.getElementById('json-active-filters').text);
      }
      if (document.getElementById('scrollToTarget')) {
        resultsElement = document.getElementById('scrollToTarget');
      }

      if ($scope.filters.selectFilters && $scope.filters.selectFilters.length > 0) {
        $scope.select = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced == false; });
        $scope.advanced = $scope.filters.selectFilters.filter(function (x) { return x.IsAdvanced == true; });
      }

      if (queryStringService.hasQueryString()) {
        setFilters();
      }
      pageloadSearch = $scope.filters.activeFilters.length > 0;
      search();
    }

    $scope.onSubmit = function () {
      if ($element.attr('data-page-size')) {
        pageSize = helperService.tryParseInt($element.attr('data-page-size'), defaultPageSize);
      } else {
        pageSize = defaultPageSize;
      }

      removeActiveFilterByGroup('keyword');

      if ($scope.keyword) {
        addActiveKeywordFilter($scope.keyword);
      }

      search();
    }

    $scope.showFilter = function (filter) {
      return !filter.Category || !$scope.filters.categoryFilter || filter.Category.includes($scope.filters.categoryFilter.SelectedOption.Value);
    }

    $scope.onViewMoreClick = function () {
      isViewMoreSearch = true;
      pageNum++;
      search();
    }

    $scope.ViewAll = function () {
      if ($scope.keyword == '' && $scope.filters.activeFilters.length === 0) {
        pageSize = 9;
        pageNum = 1;
        var qs = buildQueryString();
        searchService.search(endpoint, qs, handleViewAllSearchSuccess);
      }
    }

    $scope.returnToTop = function () {
      document.documentElement.scrollTop = 0;
    }

    $scope.onViewMoreViewAllClick = function () {
      isViewMoreSearch = true;
      $scope.isLoading = true;
      pageNum++;
      var qs = buildQueryString();
      searchService.search(endpoint, qs, handleViewAllSearchSuccess);
    }

    $scope.onFilterInit = function ($event, filter) {
      if (!filter.SelectedOption)
        filter.SelectedOption = filter.Options[0];
    }

    $scope.onFilterChange = function ($event, filter) {
      if ($element.attr('data-page-size')) {
        pageSize = helperService.tryParseInt($element.attr('data-page-size'), defaultPageSize);
      } else {
        pageSize = defaultPageSize;
      }

      if (!(filter && filter.SelectedOption)) return;

      removeActiveFilterByGroup(filter.Name);

      if (filter.Name === 'services') {
        removeActiveToggleFiltersByGroup(filter.Name);
        removeActiveFilterByGroup('subservices');
      } else if (filter.Name === 'industries') {
        removeActiveToggleFiltersByGroup(filter.Name);
        removeActiveFilterByGroup('subindustries');
      }

      if (filter.SelectedOption.Value !== '') {
        addActiveSelectFilter(filter);

        filter.Options.forEach(function (option) {
          if (option.Toggles && option.Toggles.length > 0) {
            option.SelectedToggle = {};
          }
        });

        if (filter.SelectedOption.Toggles && filter.SelectedOption.Toggles.length > 0) {
          addActiveToggleFilter(filter);
        }
      }

      pageNum = 1;
      search();
    }

    $scope.onFilterToggleSelect = function ($event, name, option, toggle) {
      if (!toggle || !toggle.IsEnabled) return;

      option.SelectedToggle = toggle;

      removeActiveFilterByGroup(name);
      addActiveFilter(name, toggle.Text, toggle.Value);
      pageNum = 1;

      search();
    }

    $scope.onSubFilterChange = function ($event, name, toggle) {
      removeActiveFilterByGroup('sub' + name);

      if (name === 'services' && $scope.selectedService.SelectedToggle.Value !== '') {
        addActiveFilter('sub' + name, $scope.selectedService.SelectedToggle.Text, $scope.selectedService.SelectedToggle.Value);
      }
      else if (name === 'industries' && $scope.selectedIndustry.SelectedToggle.Value !== '') {
        addActiveFilter('sub' + name, $scope.selectedIndustry.SelectedToggle.Text, $scope.selectedIndustry.SelectedToggle.Value);
      }

      search();
    }

    /** Adding Filters **/
    var addKeywordFilter = function (keyword) {
      addActiveFilter('keyword', keyword, keyword);
    }

    var addActiveKeywordFilter = function (keyword) {
      keyword = searchService.getKeywordDisplay(keyword);
      addActiveFilter('keyword', keyword, keyword);
    }

    var addActiveSelectFilter = function (filter) {
      if (filter && filter.SelectedOption) {
        addActiveFilter(filter.Name, filter.SelectedOption.Text, filter.SelectedOption.Value);
      }
    }

    var addActiveFilter = function (group, text, value) {
      $scope.showClearAll = true;
      $scope.filters.activeFilters.push({ Group: group, Text: text, Value: value });
    }

    var addActiveToggleFilter = function (filter) {
      if (filter.Name === 'services') {
        $scope.selectedService = filter.SelectedOption;
        //clear out existig drop-down
        $scope.subserviceFilters = [$scope.subserviceFilters[0]]
        //add new items
        $scope.subserviceFilters = $scope.subserviceFilters.concat(filter.SelectedOption.Toggles);
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0];
      }
      if (filter.Name === 'industries') {
        $scope.selectedIndustry = filter.SelectedOption;
        //clear out existig drop-down
        $scope.subindustryFilters = [$scope.subindustryFilters[0]]
        //add new items
        $scope.subindustryFilters = $scope.subindustryFilters.concat(filter.SelectedOption.Toggles);
        $scope.selectedIndustry.SelectedToggle = $scope.subserviceFilters[0];
      }
    }

    $scope.onRemoveFilterAll = function (event) {
      resetSearch();
      $scope.ViewAll();
    }

    var removeActiveFilterByGroup = function (group) {
      $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Group !== group; });
    }

    var removeActiveFilterByValue = function (value) {
      $scope.filters.activeFilters = $scope.filters.activeFilters.filter(function (active) { return active.Value !== value });
    }

    var resetSearch = function () {
      $scope.filters.activeFilters = [];
      $scope.activeSearch = false;

      resetSelectedFilters();
      resetHistory();
      resetKeyword();

      $scope.showClearAll = false;

      pageNum = 1;
      pageSize = defaultPageSize;
      previousKeyword = '';
    }

    var resetSelectedFilters = function () {
      angular.forEach($scope.filters.selectFilters, function (filter) {
        filter.SelectedOption = {};
        if (filter.Options && filter.Options.length > 0) {
          filter.Options.forEach(function (option) {
            option.SelectedToggle = {};
          });
          filter.SelectedOption = filter.Options[0];
        }
        if (filter.ExcludeIds)
          filter.ExcludeIds = null;
        if (filter.Keyword)
          filter.Keyword = '';
      });
      $scope.filters.toggleFilters = [];
      $scope.subserviceFilters = [$scope.subserviceFilters[0]]
      $scope.subindustryFilters = [$scope.subindustryFilters[0]]
    }

    $scope.onRemoveFilter = function (event, active) {
      removeActiveFilterByValue(active.Value);

      if (active.Group == 'keyword') {
        resetKeyword();
      }

      if (active.Group === 'subservices') {
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0]
      }

      if (active.Group === 'subindustries') {
        $scope.selectedIndustry.SelectedToggle = $scope.subindustryFilters[0]
      }

      angular.forEach($scope.filters.selectFilters, function (filter) {
        if (filter.Name === active.Group) {
          removeActiveToggleFiltersByGroup(active.Group);
          if (filter.Options && filter.Options.length > 0) {
            filter.SelectedOption = filter.Options[0];
          } else {
            filter.SelectedOption = {};
          }
          if (filter.ExcludeIds)
            filter.ExcludeIds = null;
          if (filter.Keyword)
            filter.Keyword = '';
        }
      });

      pageNum = 1;

      if ($element.attr('data-page-size')) {
        pageSize = helperService.tryParseInt($element.attr('data-page-size'), defaultPageSize);
      } else {
        pageSize = defaultPageSize;
      }

      if ($scope.filters.activeFilters.length === 0) {
        resetSearch();
        $scope.ViewAll();
      }
      else {
        search();
      }
    }

    var resetKeyword = function () {
      previousKeyword = '';
      $scope.keyword = '';
    }

    var resetResults = function () {
      $scope.totalResultCount = 0;
      $scope.results = [];
    }

    var buildQueryString = function () {
      var qs = '';
      var related = '';

      angular.forEach($scope.filters.activeFilters, function (active) {
        if (active.Group !== 'keyword' && active.Group !== 'geography') {
          related += (related !== '' ? '|' : '') + active.Value;
        }
      });

      if ($scope.keyword && $scope.keyword !== '') {
        qs = queryStringService.addQueryString('keyword', $scope.keyword, qs);
      } else if (previousKeyword && previousKeyword !== '') {
        qs = queryStringService.addQueryString('keyword', previousKeyword, qs);
      }

      if (related && related !== '') {
        qs = queryStringService.addQueryString('related', related, qs);
      }

      if (pageNum > 1 && !isViewMoreSearch)
        qs = queryStringService.addQueryString('noSkip', true, qs);

      if (pageNum && pageNum > 1) {
        qs = queryStringService.addQueryString('pageNum', pageNum, qs);
      }

      qs = queryStringService.addQueryString('pageSize', pageSize, qs);
      qs = queryStringService.addQueryString('sortBy', sortBy, qs);
      qs = queryStringService.addQueryString('sortOrder', sortOrder, qs);
      if (language !== '') {
        qs = queryStringService.addQueryString('language', language, qs);
      }

      return qs;
    }

    var search = function () {
      if (isExperienceEditor) return;

      if (!($scope.filters.activeFilters.length > 0 || $scope.keyword !== '')) {
        resetSearch();
        return;
      }

      //if they fill out text input field and click an advanced filter
      if ($scope.filters.activeFilters.filter(function (x) { return x.Group === 'keyword' }) === undefined && $scope.keyword !== '')
        addActiveFilter('keyword', $scope.keyword, $scope.keyword)

      $scope.isLoading = true;

      var qs = buildQueryString();

      updateHistory(qs, isViewMoreSearch);

      searchService.search(endpoint, qs, handleSearchSuccess, handleSearchFailure);
    }

    var handleSearchSuccess = function (response) {
      angular.forEach(response.data.Results, function (result) {
        angular.forEach(sanitizedFields, function (name) {
          result[name] = $sce.trustAsHtml(result[name]);
        });
      });

      if (pageNum > 1 && isViewMoreSearch) {
        $scope.results = $scope.results.concat(response.data.Results || []);
      }
      else {
        $scope.resultsLabel = updateResultLabel(resultsLabel, response.data.TotalResultCount);
        $scope.totalResultCount = response.data.TotalResultCount;
        $scope.results = response.data.Results || [];

        if (response.data.TotalResultCount > 0) {
          $scope.noResultsFound = false;
        }
        else {
          $scope.noResultsFound = true;
          resetResults();
        }
      }

      if (scrollPosition > 0) {
        $timeout(function () {
          window.scroll(0, scrollPosition);
        });
      }
      else if ($scope.filters.activeFilters.length === 1 && !!resultsElement && !pageloadSearch && !isViewMoreSearch) {
        scrollToService.scrollToElement(resultsElement);
      }

      if ($scope.keyword !== '' && $scope.keyword !== previousKeyword) {
        previousKeyword = $scope.keyword;
      }

      pageloadSearch = false;
      isViewMoreSearch = false;
      $scope.isLoading = false;
      $scope.activeSearch = true;
    }

    var handleViewAllSearchSuccess = function (response) {
      angular.forEach(response.data.Results, function (result) {
        angular.forEach(sanitizedFields, function (name) {
          result[name] = $sce.trustAsHtml(result[name]);
        });
      });

      if (pageNum > 1 && isViewMoreSearch) {
        $scope.results = $scope.results.concat(response.data.Results || []);
      }
      else {
        $scope.totalResultCount = response.data.TotalResultCount;
        $scope.results = response.data.Results;
        $scope.noResultsFound = response.data.TotalResultCount <= 0;
      }

      $scope.listingCountLabel = $sce.trustAsHtml(updateListingCountLabel(listingCountLabel, $scope.results.length, $scope.totalResultCount));

      isViewMoreSearch = false;
      $scope.isLoading = false;
    }

    var handleSearchFailure = function (response) {
      resetKeyword();
      resetResults();

      pageloadSearch = false;
      isViewMoreSearch = false;
      $scope.isLoading = false;
    }

    var updateHistory = function (qs, replace) {
      var historyQs = qs;
      historyQs = queryStringService.removeQueryString('noSkip', true, historyQs);
      historyQs = queryStringService.removeQueryString('pageSize', pageSize, historyQs);
      historyQs = queryStringService.removeQueryString('sortBy', sortBy, historyQs);
      historyQs = queryStringService.removeQueryString('sortOrder', sortOrder, historyQs);
      historyQs = queryStringService.removeQueryString('language', language, historyQs);
      //update history & querystring
      if (pageloadSearch || replace) {
        history.replaceState(null, document.title, historyQs.replace('?&', '?'));
      }
      else {

        history.pushState(null, document.title, historyQs.replace('?&', '?'));
      }
    }

    var resetHistory = function () {
      history.pushState(null, document.title, '?');
    }

    var removeActiveToggleFiltersByGroup = function (group) {
      if (group === 'services') {
        $scope.selectedService.SelectedToggle = $scope.subserviceFilters[0];
        $scope.subserviceFilters = [$scope.subserviceFilters[0]];
      }
      else {
        $scope.selectedIndustry.SelectedToggle = $scope.subindustryFilters[0];
        $scope.subindustryFilters = [$scope.subindustryFilters[0]];
      }
    }

    var setFilters = function () {
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('keyword'))) {
        var keyword = queryStringService.getParameterByName('keyword');
        if (keyword) {
          $scope.keyword = keyword;
          addKeywordFilter(keyword);
        }
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('related'))) {
        setSelectFilters(queryStringService.getParameterByName('related'));
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('pageSize'))) {
        pageSize = helperService.tryParseInt(queryStringService.getParameterByName('pageSize'), 0);
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('pageNum'))) {
        pageNum = helperService.tryParseInt(queryStringService.getParameterByName('pageNum'), 0);
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortBy'))) {
        sortBy = helperService.tryParseInt(queryStringService.getParameterByName('sortBy'), 0);
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('sortOrder'))) {
        sortOrder = helperService.tryParseInt(queryStringService.getParameterByName('sortOrder'), 0);
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('language'))) {
        language = queryStringService.getParameterByName('language');
      }
      if (!helperService.isNullOrEmpty(queryStringService.getParameterByName('scroll'))) {
        scrollPosition = queryStringService.getParameterByName('scroll');
      }
    }

    var setSelectFilters = function (relateds) {
      var aRelated = [];
      aRelated = relateds.split('|');
      angular.forEach(aRelated, function (relatedId) {
        var found = false;
        angular.forEach($scope.filters.selectFilters, function (filter) {
          if (filter.Options && filter.Options.length > 0) {
            angular.forEach(filter.Options, function (option) {
              if (option.Value === relatedId) {
                found = true;
                filter.SelectedOption = option;
                if (option.Toggles && option.Toggles.length > 0) {
                  addActiveToggleFilter(filter);
                }
                addActiveSelectFilter(filter);
              }
              else if (option.Toggles && option.Toggles.length > 0) {
                angular.forEach(option.Toggles, function (toggle) {
                  if (toggle.Value === relatedId) {
                    found = true;
                    option.SelectedToggle = toggle;
                    filter.SelectedOption = option;
                    addActiveFilter('sub' + filter.Name, toggle.Text, toggle.Value);
                    addActiveToggleFilter(filter);

                    if (filter.Name === 'services') {
                      $scope.selectedService.SelectedToggle = toggle;
                    } else if (filter.Name === 'industries') {
                      $scope.selectedIndustry.SelectedToggle = toggle;
                    }
                  }
                });
              }
            });
          }
        });
        if (!found && $scope.serverActiveFilters && $scope.serverActiveFilters.length > 0) {
          //try checking the active filters json from server for typeaheads
          angular.forEach($scope.serverActiveFilters, function (server) {
            if (server.Value === relatedId) {
              addActiveFilter('?', server.Text, server.Value);
            }
          });
        }
      });
    }

    var updateResultLabel = function (sectionLabel, count) {
      if (sectionLabel) {
        if (sectionLabel.indexOf('##COUNT##') > 0) {
          return sectionLabel.replace('##COUNT##', count);
        }
      }

      return sectionLabel;
    }

    var updateListingCountLabel = function (sectionLabel, resultCount, totalCount) {
      if (sectionLabel) {
        if (sectionLabel.indexOf('##COUNT##') > 0 && sectionLabel.indexOf('##TOTAL##') > 0) {
          return sectionLabel.replace('##COUNT##', '<span style="color:#0d7d2e">' + resultCount + '</span>').replace('##TOTAL##', totalCount);
        }
      }

      return sectionLabel;
    }

    init();
  }]);
};