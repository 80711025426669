﻿module.exports = function (app) {
    app.controller("footer", ["$scope", "$element", function ($scope, $element) {

        $scope.goToTranslatedUrl = function (url) {
            if (url) {
                window.location.href = url;
            }    
        };

        $scope.isHomePage = function () {
            var bodyElement = angular.element(document.body);
            if (bodyElement) {
                return bodyElement.hasClass('page__home');
            }
            
            return false; 
        }

        var englishLink = document.getElementById("english");
        var englishLinkLanguage = '';

        var chineseLink = document.getElementById("chinese");
        var chineseLinkLanguage = ''; 

        var contextLanguage = $element.attr("data-context-language");

        if (englishLink != null) {
            englishLinkLanguage = englishLink.getAttribute("data-language"); 
        }

        if (chineseLink != null) {
            chineseLinkLanguage = chineseLink.getAttribute("data-language"); 
        }

        var toggleEnglishLanguage = function () {
            $scope.IsEnglishLanguage = true;
            $scope.IsChineseLanguage = false; 
        }

        var toggleChineseLanguage = function () {
            $scope.IsEnglishLanguage = false;
            $scope.IsChineseLanguage = true; 
        }

        //Conditional that updates footer language 'active' state
        if (contextLanguage == englishLinkLanguage) {
            toggleEnglishLanguage();
        }
        else if (contextLanguage == chineseLinkLanguage) {
            toggleChineseLanguage();
        }
        else {
            toggleEnglishLanguage(); //default selection; 
        }

    }]);
};