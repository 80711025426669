const setup = function (app) {
  app.directive('searchform',
    ['$rootScope', 'scrollToService', function ($rootScope, scrollToService) {
      return {
        restrict: 'A',
        scope: true,
        bindToController: {
          settings: '&',
          results: '&',
          resultsElement: '&'
        },
        controllerAs: 'searchctrl',
        controller: ['$scope', function ($scope) {
          $scope.settings = {
            hasactive: false
          }
          $scope.updateResults = function () {
            $scope.settings.hasactive = true
            if ($scope.resultsElement) scrollToService.scrollToElement($scope.resultsElement)
          }
          $scope.resetResults = function () {
            $scope.settings.hasactive = false
          }
        }],
        link: {
          post: function ($scope) {
            $scope.onChange = function (event) {
              $scope.updateResults()
            }
            $scope.onSubmit = function (event) {
              if (event) event.preventDefault()
              $scope.updateResults()
            }
            $scope.onRemoveKeyword = function (event) {
              if (event) event.preventDefault()
              $scope.resetResults()
            }
            $scope.onRemoveKeywordAll = function (event) {
              if (event) event.preventDefault()
              $scope.resetResults()
            }
          }
        }
      }
    }]
  )

  app.directive('searchformResults',
    [ function () {
      return {
        restrict: 'A',
        scope: false,
        link: {
          post: function ($scope, $element) {
            $scope.resultsElement = $element[0]
          }
        }
      }
    }]
  )
}
module.exports = setup
