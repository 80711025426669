module.exports = function (app) {
    app.directive('readmore',
      ['$rootScope', 'appFactory', function ($rootScope, appFactory) {
          return {
              restrict: 'A',
              scope: true,
              bindToController: {
                  'readmorestate': '&'
              },
              controllerAs: 'readmorectrl',
              controller: ['$scope', function ($scope) {
                  $scope.readmorestate = {
                      active: false,
                      limitHeight: '0px',
                      fullHeight: '400px'
                  }
                  $scope.toggle = function () {
                      $scope.getFullHeight();
                      $scope.readmorestate.active = !$scope.readmorestate.active
                  }
              }],
              link: function ($scope, $element, $attrs) {
                  $scope.onReadmoreTrigger = function () {
                      $scope.toggle()
                      $scope.$emit('read-more-toggled', { active: $scope.readmorestate.active, height: $scope.readmorestate.fullHeight })
                  }
              }
          }
      }]
    )
    app.directive('readmoreSizer',
      ['$rootScope', 'appFactory', function ($rootScope, appFactory) {
          return {
              restrict: 'A',
              scope: false,
              require: '^readmore',
              bindToController: {
              },
              controllerAs: 'readmoreexctrl',
              controller: ['$scope', function ($scope) {
              }],
              link: function ($scope, $element, $attrs) {
                  $scope.getFullHeight = function () {
                      const child = $element.children().eq(0)[0]
                      const height = (child.offsetHeight !== undefined) ? child.offsetHeight : (child.clientHeight !== undefined) ? child.clientHeight : 0
                      $scope.readmorestate.fullHeight = height + 'px'
                  }

                  $element.ready(function () {
                      window.setTimeout(function () {
                          $scope.getFullHeight()
                      }, 1)
                      $rootScope.$on('windowresize-windowSize', function () {
                          $scope.getFullHeight()
                      }, true)
                  })
              }
          }
      }]
    )
}
