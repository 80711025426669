﻿module.exports = function (app) {
    app.directive('onenorSmoothScroll', function () {
        var link = {
            post: function ($scope, $element, $attrs) {
                
                //check that id is non empty and that there is a document with that id
                //add onclick event handler to the element. on click smooth scroll to the found element

                if (!(!$scope.id || $scope.id === '')) {
                    var target = document.getElementById($scope.id);
                    $element.bind('click', function (e) {
                        let scroll = new window.SmoothScroll();
                        if (scroll) {
                            scroll.animateScroll(target, {
                                speed: 600,
                                offset: 50,
                                easing: 'easeInOutCubic'
                            });
                        }
                    });
                }

            }
        };

        return {
            replace: false,
            restrict: 'A',
            scope: {
                id: '@onenorSmoothScroll'
            },
            link: link
        };
    });
};